<template>
    <v-col align-self="start" cols="8">
        <!-- SECTION TITLE -->
        <v-row align="center" justify="center">
            <p class="section-title mt-5">Detalles de tu suscripción</p>
        </v-row>

        <v-row>
            <v-col cols="1"></v-col>
            <v-col align-self="center" cols="10">
                <!-- SUBSCRIPTION STATUS -->
                <SubscriptionDetail_Text
                    :value="subscriptionStatusLabel"
                    label="Estado de Suscripción"
                ></SubscriptionDetail_Text>

                <!-- SUBSCRIPTION TYPE -->
                <SubscriptionDetail_Text
                    :value="subscriptionType"
                    label="Tipo de Suscripción"
                ></SubscriptionDetail_Text>

                <!-- SUBSCRIPTION START DATE -->
                <SubscriptionDetail_Text
                    :value="subscriptionStartDate"
                    label="Fecha de inicio de la subscripción"
                ></SubscriptionDetail_Text>

                <!-- SUBSCRIPTION PERIOD START DATE -->
                <SubscriptionDetail_Text
                    :value="periodStartDate"
                    label="Fecha de inicio de periodo actual"
                ></SubscriptionDetail_Text>

                <!-- SUBSCRIPTION PERIOD END DATE -->
                <SubscriptionDetail_Text
                    :value="periodFinishDate"
                    label="Fecha de finalización de periodo actual"
                ></SubscriptionDetail_Text>

                <!-- SUBSCRIPTION RESTING DAYS -->
                <SubscriptionDetail_Text
                    label="Días de suscripción disponibles"
                    :value="restingDays + ' días'"
                ></SubscriptionDetail_Text>

                <!-- SUBSCRIPTION PRICE -->
                <SubscriptionDetail_Text
                    label="Precio de suscripción"
                    :value="subscriptionPrice.toUpperCase()"
                ></SubscriptionDetail_Text>

                <!-- SUBSCRIPTION AUTO RENEWAL -->
                <SubscriptionDetail_Text
                    label="Renovación automática"
                    :value="subscriptionRenewal ? 'Activada' : 'Desactivada'"
                ></SubscriptionDetail_Text>

                <!-- SUBSCRIPTION ID -->
                <SubscriptionDetail_Text
                    label="ID de Suscripción"
                    :value="subscriptionId"
                ></SubscriptionDetail_Text>

            </v-col>
            <v-col cols="1"></v-col>
        </v-row>

        <!-- CTA BUTTON -->
        <v-row align="center" justify="center">
            <!-- START FREE TRIAL BUTTON -->
            <button
                class="kuida-md-btn aqua button-shadow mt-12 px-12"
                @click="confirmAutoRenewalDialogTrigger = true"
            >
                {{ subscriptionRenewal ? 'Cancelar Renovación Automática' : 'Activar Renovación Automática' }}
            </button>
        </v-row>

        <!-- CONFIRM CANCELLATION AT PERIOD END DIALOG -->
        <ActionConfirm_Dialog
            :dialog-trigger="confirmAutoRenewalDialogTrigger"
            :dialog-message="subscriptionRenewal ? dialogMessages[0] : dialogMessages[1]"
            dialog-title="Atención"
            @action-cancel="closeCancellationRequestDialog"
            @action-confirm="updateAutoRenewalSelectionRequest(!subscriptionRenewal)"
        ></ActionConfirm_Dialog>

        <!-- LOADING DIALOG -->
        <Loading_Dialog
            :loading-dialog-trigger="loadingDialogTrigger"
            dialog-message="Espera un momento por favor :)"
        ></Loading_Dialog>
    </v-col>
</template>

<script>
import SubscriptionDetail_Text from "@/components/common_widgets/texts/SubscriptionDetail_Text";
import ActionConfirm_Dialog from "@/components/common_widgets/dialogs/ActionConfirm_Dialog";
import Loading_Dialog from "@/components/common_widgets/dialogs/Loading_Dialog";
import * as dateHelpers from "@/helpers/date_formatting"
import {cloud_functions_request} from "@/helpers/cloud_functions_request";

export default {
    name: "SubscriptionDetails_Section",
    components: {
        Loading_Dialog,
        ActionConfirm_Dialog,
        SubscriptionDetail_Text,
    },
    props: {
        subscriptionsList: Array,
    },
    data() {
        return {
            subscriptionType: '',
            latestSubscription: null,
            subscriptionStatusLabel: '',
            subscriptionStartDate: null,
            subscriptionRenewal: false,
            subscriptionId: '',
            periodStartDate: null,
            periodFinishDate: null,
            restingDays: null,
            subscriptionPrice: '',
            loadingDialogTrigger: false,
            confirmAutoRenewalDialogTrigger: false,
            dialogMessages: [
                "¿Estás segur@ que deseas cancelar la renovación automática de tu suscripción al terminar el periodo actual? Perderás los beneficios de Kuida al llegar la fecha de finalización de periodo.",
                "¿Estás segur@ que deseas activar la renovación automática de tu suscripción al terminar el periodo actual?"
            ]
        }
    },
    mounted() {
        this.selectLatestSubscription()
    },
    methods: {
        calculateDaysToPeriodEnds(periodEndDate) {
            const today = new Date();

            // To calculate the time difference of two dates
            let difference_In_Time = periodEndDate.getTime() - today.getTime();

            // To calculate the no. of days between two dates
            return Math.ceil(difference_In_Time / (1000 * 3600 * 24));
        },

        async updateAutoRenewalSelectionRequest(value) {
            this.loadingDialogTrigger = true;

            let requestObject = {
                subscriptionId: this.latestSubscription['id'],
                autoRenewal: value,
            }

            let response = await cloud_functions_request('changeAutoRenewalSubscriptionState', requestObject);

            if(response['status'] === 200) {
                this.$emit('subscription-auto-renewal-change');

            } else {
                this.resultDialogTitle = "Error";
                this.resultDialogMessage = "Ha ocurrido un error al cancelar tu suscripción, favor de intentarlo de nuevo.";
                this.resultDialogTrigger = true;
            }

            this.loadingDialogTrigger = false;

            this.closeCancelSubscriptionDialog();
        },

        closeCancellationRequestDialog() {
            this.confirmAutoRenewalDialogTrigger = false;
        },

        selectLatestSubscription() {
            // Select latest subscription from list
            this.latestSubscription = this.subscriptionsList[0];

            // Create a user-friendly label for subscription status
            this.createStatusLabel();

            // Get current subscription Id
            this.subscriptionId = this.latestSubscription['id'];

            // Get Kuida Plan from subscription
            this.subscriptionType = this.latestSubscription['plan']['nickname'];

            // Get & format subscription start date
            let startDate = new Date(this.latestSubscription['start_date'] * 1000);
            this.subscriptionStartDate = dateHelpers.formatDateFromDateType(startDate);

            // Get & format subscription current period start date
            let periodStartDate = new Date(this.latestSubscription['current_period_start'] * 1000);
            this.periodStartDate = dateHelpers.formatDateFromDateType(periodStartDate);

            // Get & format subscription current period finish date
            let periodEndDate = new Date(this.latestSubscription['current_period_end'] * 1000);
            this.periodFinishDate = dateHelpers.formatDateFromDateType(periodEndDate);

            // Get available subscription days
            this.restingDays = this.calculateDaysToPeriodEnds(periodEndDate);

            // Get subscription price
            this.subscriptionPrice = "$" + (this.latestSubscription['plan']['amount'] / 100) + ".00 " + this.latestSubscription['plan']['currency'];

            this.subscriptionRenewal = this.latestSubscription['cancel_at_period_end'];
        },

        createStatusLabel() {
            let status = this.latestSubscription['status'];

            switch (status) {
                case 'trialing':
                    this.subscriptionStatusLabel = "Periodo de Prueba";
                    return
                case 'active':
                    this.subscriptionStatusLabel = "Activa";
                    return
                case 'past_due':
                    this.subscriptionStatusLabel = "Vencida";
                    return
                case 'unpaid':
                    this.subscriptionStatusLabel = "Falta de Pago";
                    return
                case 'canceled':
                    this.subscriptionStatusLabel = "Cancelada";
                    return
                case 'incomplete':
                    this.subscriptionStatusLabel = "Incompleta";
                    return
                case 'incomplete_expired':
                    this.subscriptionStatusLabel = "Incompleta & Vencida";
                    return
                case 'ended':
                    this.subscriptionStatusLabel = "Terminada";
                    return
                default:
                    this.subscriptionStatusLabel = "Error al obtener información";
                    return;
            }
        },
    },
}
</script>

<style scoped>
.section-title {
    color: #F96767;
    font-weight: bold;
    font-size: 18px;
}
</style>