import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faClock, faPlus} from '@fortawesome/free-solid-svg-icons'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faUserFriends } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faPlus)
library.add(faEdit)
library.add(faTimesCircle)
library.add(faClock)
library.add(faUserFriends)

Vue.component('font-awesome-icon', FontAwesomeIcon)