<template>
    <v-container class="py-2" fluid>
        <v-row>
            <!-- SERVICES LIST COLUMN -->
            <v-col cols="6">
                <v-container class="content-columns">
                    <!-- SERVICES CARDS LIST -->
                    <v-col align="center">
                        <!-- CARDS LIST -->
                        <v-container style="height: 75vh; overflow: auto; padding: 0" class="mt-2">
                            <DoctorServicesList_Component></DoctorServicesList_Component>
                        </v-container>
                    </v-col>
                </v-container>
            </v-col>

            <!-- CREATE SERVICE FORM COLUMN -->
            <v-col cols="6">
                <v-container class="content-columns" fill-height>
                    <v-row align="center" justify="center">
                        <!-- CREATE NEW SERVICE FORM -->
                        <AddService_Form></AddService_Form>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import DoctorServicesList_Component from "@/components/profile/tabs/DoctorServicesList_Tab";
import AddService_Form from "@/components/profile/dialog_forms/AddService_Form";

export default {
    name: "DoctorServices_Component",
    components: {
        DoctorServicesList_Component,
        AddService_Form,
    },
}
</script>

<style scoped>
.content-columns {
    height: 80vh;
    width: 100%;
}

.card-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #F96767;
}

::-webkit-input-placeholder {
    color: white !important;
    opacity: 1 !important;
}

/* Designing for scroll-bar */
::-webkit-scrollbar {
    width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #eee;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #FDCFCF;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #5AC6C8;
}

.scroller {
    height: 100%;
}
</style>