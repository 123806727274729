<template>
    <div>
        <!-- LIST TITLE -->
        <v-row align="center" justify="center"
               v-if="this.servicesData.length > 0">
            <h2 class="card-title mt-2 mb-2">Servicios que ofreces</h2>
        </v-row>

        <v-container style="overflow-y: auto; overflow-x: hidden !important;">
            <DynamicScroller
                v-if="this.servicesData.length > 0"
                :items="this.servicesData"
                :min-item-size="400"
                :key-field="id"
            >
                <template v-slot="{ item, index, active }">
                    <DynamicScrollerItem
                        :size-dependencies="[ item.id ]"
                        :item="item"
                        :index="index"
                        :active="active"
                    >
                        <Service_Card :item="item"></Service_Card>
                    </DynamicScrollerItem>
                </template>
            </DynamicScroller>

            <v-row v-else align="center" class="noSpace" justify="center">
                <v-col align-self="center" class="mx-8">
                    <p class="accent--text" style="font-size: 0.8em; font-weight: bold; margin-top: 50%;">
                        Añade los diferentes tipos de consultas que ofreces.
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </div>

</template>

<script>
import firebase from "firebase";
import Service_Card from "@/components/common_widgets/cards/Service_Card";

export default {
    name: "DoctorServicesList_Component",
    components: {
        Service_Card,
    },
    data() {
        return {
            user: '',
            servicesData: [],
        }
    },
    async created() {
        this.user = firebase.auth().currentUser.email;

        await this.getServicesDocumentsList();
    },
    methods: {
        async getServicesDocumentsList() {
            const options2 = { style: 'currency', currency: 'MXN' };
            const numberFormat2 = new Intl.NumberFormat('es-MX', options2);

            await firebase.firestore().collection('doctors').doc(this.user).collection('services').orderBy('addedSince', "desc").onSnapshot((snapshot) => {
                this.servicesData = [];

                snapshot.docs.forEach((item) => {
                    const data = item.data();

                    const map = {
                        id: item.id,
                        price: numberFormat2.format(data.price) + " " + data.currency.toUpperCase(),
                        type: data.type,
                        name: data.title,
                        duration: data.duration,
                        addedSince: this.formatDate(data.addedSince),
                        description: data.description,
                    };

                    this.servicesData.push(map);
                });
            });
        },

        formatDateNumbers(number) {
            if (number.toString().length === 1) return '0' + number;
            else return number;
        },

        formatDate(date) {
            const dateTime = date.toDate();
            let day = this.formatDateNumbers(dateTime.getDate());
            let month = this.formatDateNumbers((dateTime.getMonth() + 1));
            let year = dateTime.getFullYear();
            return day + '/' + month + '/' + year;
        },
    }
}
</script>

<style scoped>

</style>