export const specialitiesList = [
  'Acupuntura',
  'Algología',
  'Analista clínico',
  'Anatomopatología',
  'Anestesiología',
  'Angiología',
  'Audiología',
  'Cardiología',
  'Cardiología pediátrica',
  'Cirugía bariátrica',
  'Cirugía cardiovascular y torácica',
  'Cirugía cardiovascular y torácica pediátrica',
  'Cirugía de la mano',
  'Cirugía estética y cosmética',
  'Cirugía general',
  'Cirugía maxilofacial',
  'Cirugía oncológica',
  'Cirugía pediátrica',
  'Cirugía plástica',
  'Cirugía torácica',
  'Dermatología',
  'Dermatología pediátrica',
  'Diabetología',
  'Endocrinología',
  'Endocrinología pediátrica',
  'Endoscopista',
  'Enfermería',
  'Epidemiología',
  'Especialista en medicina critica y terapia intensiva',
  'Especialista en medicina del deporte',
  'Especialista en medicina del trabajo',
  'Especialista en medicina integrada',
  'Especialista en medicina nuclear',
  'Especialista en medicina preventiva',
  'Fisioterapeuta',
  'Gastroenterología',
  'Gastroenterología pediátrica',
  'Genetista',
  'Geriatría',
  'Ginecología',
  'Ginecología oncológica',
  'Hematología',
  'Hematología pediátrica',
  'Infectología',
  'Infectología pediátrica',
  'Inmunología',
  'Internista',
  'Logopedia',
  'Medicina de familia',
  'Medicina estética',
  'Medicina general',
  'Medicina renopata',
  'Naturista',
  'Nefrología',
  'Nefrología pediátrica',
  'Neonatología',
  'Neumología',
  'Neumología pediátrica',
  'Neurocirugía',
  'Neurofisiología',
  'Neurología',
  'Neurología pediátrica',
  'Nutricionista',
  'Nutrición clínica',
  'Odontología',
  'Odontología pediátrica',
  'Oftalmología',
  'Oftalmología pediátrica',
  'Oncología médica',
  'Oncología pediátrica',
  'Optometría',
  'Ortopedia',
  'Ortopedia infantil',
  'Otorrinolaringología',
  'Patología clínica',
  'Pediatría',
  'Podiatría',
  'Podología',
  'Proctología',
  'Psicoanalista',
  'Psicología',
  'Psicopedagogía',
  'Psiquiatría',
  'Psiquiatría infantil',
  'Quiropráctica',
  'Radiología',
  'Radiooncología',
  'Radioterapia',
  'Reumatología',
  'Reumatología pediátrica',
  'Terapia complementaria',
  'Terapia ocupacional',
  'Traumatología',
  'Urgenciología',
  'Urología',
  'Urología pediátrica',
  'Otro...',
];