import firebase from "firebase";

export async function cloud_functions_request(cloudFunction, requestObject) {
    try {
        const callableFunction = firebase.functions().httpsCallable(cloudFunction);
        return await callableFunction(requestObject);
    } catch (e) {
        console.log("-- ERROR --");
        console.log(e);
    }
}