<template>
    <!-- NEW APPOINTMENT DIALOG -->
    <v-dialog
        v-model="dialogTrigger"
        overlay-opacity="0.8"
        persistent
    >
        <!-- DIALOG BODY -->
        <v-card class="rounded-xl elevation-0" color="accent">
            <v-container class="px-10 py-8" fluid>
                <!-- HEADER -->
                <v-row align="center" justify="space-between">
                    <!-- TITLE -->
                    <p class="dialogTitle text-uppercase">CANJEA UN CÓDIGO DE DESCUENTO</p>

                    <!-- CLOSE DIALOG BUTTON -->
                    <v-btn
                        class="mt-n4 mr-n2"
                        color="white"
                        fab
                        plain
                        small
                        @click="closeDialog"
                    >
                        <v-icon large>mdi-close</v-icon>
                    </v-btn>
                </v-row>

                <!-- DIALOG BODY -->
                <v-row align="center" class="px-8" justify="center">
                    <v-col cols="3"></v-col>

                    <v-col align-self="center" cols="6">
                        <!-- PROMOTIONS TEXT -->
                        <p class="white--text text-center mt-4 mb-6" style="font-size: 17px; font-weight: normal;">
                            Aprovecha los beneficios que Kuida ha creado para gente como tu e introduce el código de descuento que tengas aquí.
                        </p>

                        <!-- PROMOTION CODE TEXT FIELD -->
                        <v-text-field
                            v-model="promotionCode"
                            :rules="promotionCodeRules"
                            color="white"
                            dark
                            dense
                            label="Código de descuento"
                            outlined
                        ></v-text-field>
                    </v-col>

                    <v-col cols="3"></v-col>
                </v-row>

                <!-- SAVE DATA BUTTON -->
                <v-row align="center" class="mt-8 mb-2" justify="center">
                    <button
                        class="kuida-md-btn coral-btn button-shadow mt-2"
                        @click="promotionCodeRedeemRequest"
                    >
                        Canjear Cupón
                    </button>
                </v-row>

            </v-container>
        </v-card>

        <!-- ALERT MESSAGES DIALOG -->
        <Alert_Dialog
            :dialog-message="alertDialogMessage"
            :dialog-title="alertDialogTitle"
            :dialog-trigger="alertDialogTrigger"
            @dialog-close="alertDialogTrigger = false;"
        ></Alert_Dialog>

        <!-- FILES LOADING INDICATOR -->
        <Loading_Dialog
            :loading-dialog-trigger="loadingDialog"
            dialog-message="Espera un momento"
        ></Loading_Dialog>

    </v-dialog>
</template>

<script>
import Loading_Dialog from "@/components/common_widgets/dialogs/Loading_Dialog";
import Alert_Dialog from "@/components/common_widgets/dialogs/Alert_Dialog";
import firebase from "firebase";
import {cloud_functions_request} from "@/helpers/cloud_functions_request";

export default {
    name: "RedeemPromotionCode_Form",
    props: {
        dialogTrigger: Boolean,
        stripeCustomerId: {
            type: String,
        },
    },
    components: {
        Loading_Dialog,
        Alert_Dialog,
    },
    data() {
        return {
            currentUser: '',
            promotionCode: '',
            promotionCodeRules: [
                value => !!value || 'Introduce tu código aquí.',
                value => {
                    const pattern = /^([A-Z]|[ ]|[0-9])*$/
                    return pattern.test(value) || 'El nombre solo puede contener mayúsculas, números y espacios.'
                },
            ],

            // Alerts Variables
            alertDialogTrigger: false,
            alertDialogMessage: '',
            alertDialogTitle: '',
            loadingDialog: false,
        }
    },
    watch: {
        promotionCode(value) {
            this.promotionCode = value.toString().toUpperCase();
        },
    },
    async beforeMount() {
        this.currentUser = firebase.auth().currentUser.email;
    },
    methods: {
        async promotionCodeRedeemRequest() {
            this.loadingDialog = true;

            if(this.checkEmptyFields()) {
                const responseStatus = await this.promotionCodeRedeem();
                // Possible Response Status
                // 500 --> Server Error
                // 400 --> Required Payment Method
                // 204 --> Promotion Coupon not found
                // 200 --> Success
                if(responseStatus === 500) {
                    this.loadingDialog = false;
                    this.alertDialogTitle = "ERROR";
                    this.alertDialogMessage = "Ha ocurrido un error al realizar tu solicitud. Favor de intentarlo de nuevo.";
                    this.alertDialogTrigger = true;

                } else if(responseStatus === 400) {
                    this.loadingDialog = false;
                    this.alertDialogTitle = "ERROR";
                    this.alertDialogMessage = "El cupón introducido requiere tener un método de pago registrado. Puedes editar esta información desde Perfil --> Suscripciones --> Actualizar Método de Pago.";
                    this.alertDialogTrigger = true;

                } else if(responseStatus === 204) {
                    this.loadingDialog = false;
                    this.alertDialogTitle = "ERROR";
                    this.alertDialogMessage = "El cupón introducido no existe o ha caducado.";
                    this.alertDialogTrigger = true;

                } else {
                    this.loadingDialog = false;
                    this.redeemedCouponSuccessfully();
                    this.closeDialog();
                }

            } else {
                this.loadingDialog = false;
                this.alertDialogTitle = "ERROR";
                this.alertDialogMessage = "Favor de llenar todos los campos.";
                this.alertDialogTrigger = true;

            }
        },

        async promotionCodeRedeem() {
            try {
                let requestObject = {
                    promotionCode: this.promotionCode,
                    customerId: this.stripeCustomerId,
                    doctorId: this.currentUser,
                }

                let response = await cloud_functions_request('startDoctorSubscriptionWithCoupon', requestObject);

                return response['status'];
            } catch (e) {
                return e.response.status;
            }
        },

        redeemedCouponSuccessfully() {
            this.$emit('redeemed-coupon');
        },

        checkEmptyFields() {
            return this.promotionCode !== '';
        },

        clearTextFields() {
            this.promotionCode = '';
        },

        closeDialog() {
            this.clearTextFields();

            this.$emit('dialog-close');
        },
    }
}
</script>

<style scoped>
.dialogTitle {
    color: #FFF;
    font-size: 1.5em !important;
    font-weight: bold;
}

.dialogSubtitle {
    color: #FFF;
    font-size: 0.7em !important;
    font-weight: bold;
    text-align: center;
}
</style>