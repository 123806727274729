<template>
  <v-col align="center" h>
    <!-- FORM TITLE -->
    <v-row align="center" justify="center">
      <h2 class="card-title mt-3 mb-4">Crear nuevo servicio</h2>
    </v-row>

    <!-- FORM CARD -->
    <v-sheet
      class="rounded-xl pa-10"
      color="primary"
      dark
      elevation="3"
      height="75vh"
    >
      <!-- SERVICE NAME TEXT FIELD -->
      <v-text-field
        v-model="serviceName"
        class="login-form-input mb-4"
        dark
        dense
        hide-details
        label="Nombre del Servicio"
        outlined
        @keyup.enter.exact="storeNewServiceRequest"
      ></v-text-field>

      <!-- SERVICE PRICE TEXT FIELD -->
      <v-text-field
        v-model="servicePrice"
        class="login-form-input mb-4"
        dark
        dense
        hide-details
        label="Costo ($ MXN)"
        outlined
        @keyup.enter.exact="storeNewServiceRequest"
      ></v-text-field>

      <!-- SERVICE DURATION TEXT FIELD -->
      <v-text-field
        v-model="serviceDuration"
        class="login-form-input mb-4"
        dark
        dense
        hide-details
        label="Duración Estimada (minutos)"
        outlined
        @keyup.enter.exact="storeNewServiceRequest"
      ></v-text-field>

      <!-- SERVICE LOCATION DROPDOWN -->
      <v-select
        v-model="serviceLocation"
        :items="locations"
        append-icon="mdi-arrow-down"
        class="mb-n2"
        color="#FFFFFF"
        dark
        dense
        item-color="#FFFFFF"
        menu-props="{class:coral}"
        outlined
        placeholder="Ubicación de Consulta"
      ></v-select>

      <!-- SERVICE DESCRIPTION TEXT AREA -->
      <v-textarea
        v-model="serviceDescription"
        class="mb-n6"
        color="#FFFFFF"
        dark
        dense
        height="20vh"
        hint="Descripción de tu servicio, te recomendamos sea lo más breve y concisa posible."
        item-color="#FFFFFF"
        no-resize
        outlined
        placeholder="Descripción"
        @keyup.enter.exact="storeNewServiceRequest"
      ></v-textarea>

      <!-- TO ABSORB THE TRANSACTION FEE. -->
      <v-row align="center" justify="start">
        <v-col md="11" sm="12">
          <h4 style="color: white; text-align: start;">
            ¿Quiéres que el usuario pague la comisión por transacción?
          </h4>
        </v-col>
        <v-switch v-model="commissionAccepted" color="accent"></v-switch>
      </v-row>

      <!-- SERVICE COST POSTING MESSAGE. -->
      <v-row align="center" justify="center">
        <v-col cols="12" md="12" sm="12"
          ><h4 style="color: white;">
            El servicio se publicara con un costo de
            <span style="color: white;"> ${{ newPrice }} MXN.</span>
          </h4>
        </v-col>
      </v-row>

      <!-- SERVICE CREATION BUTTON -->
      <v-container>
        <v-row align="center" justify="center">
          <button
            class="kuida-md-btn whitecoral-btn button-shadow mt-6"
            type="button"
            @click="
              storeNewServiceRequest(),
                validationResult
                  ? (confirmAutoRenewalDialogTrigger = true)
                  : (confirmAutoRenewalDialogTrigger = false)
            "
          >
            Crear Servicio
          </button>
        </v-row>
      </v-container>
    </v-sheet>

    <!-- CONFIRM ADD SERVICE -->
    <ActionConfirmService_Dialog
      :dialog-trigger="confirmAutoRenewalDialogTrigger"
      :dialog-message="serviceObject"
      dialog-title="Atención"
      @action-cancel="closeCancellationRequestDialog"
      @action-confirm="updateNewServiceRequest"
    >
    </ActionConfirmService_Dialog>

    <!-- INVALID FORM DIALOG -->
    <Alert_Dialog
      :dialog-message="dialogMessage"
      :dialog-title="dialogTitle"
      :dialog-trigger="alertDialog"
      @dialog-close="dialogCustomEvent"
    ></Alert_Dialog>
  </v-col>
</template>

<script>
import firebase from "firebase";
import Alert_Dialog from "@/components/common_widgets/dialogs/Alert_Dialog";
import { cloud_functions_request } from "@/helpers/cloud_functions_request";
import ActionConfirmService_Dialog from "@/components/common_widgets/dialogs/ActionConfirmService_Dialog";

export default {
  name: "AddService_Form",
  components: {
    Alert_Dialog,
    ActionConfirmService_Dialog,
  },
  data() {
    return {
      locations: ["Consultorio"],
      serviceName: null,
      servicePrice: null,
      serviceDuration: null,
      serviceLocation: null,
      serviceDescription: null,
      alertDialog: false,
      dialogTitle: "",
      dialogMessage: "",
      commissionAccepted: false,
      confirmAutoRenewalDialogTrigger: false,
      serviceObject: {},
      activeSubscription: false,
      validationResult: false,
    };
  },
  methods: {
    // Execute this method whenever a user wants to store a new service
    async storeNewServiceRequest() {
      // Form null and formats validation
      const validationResult = this.formValidation();

      // Only execute store when validation is completed successfully
      if (validationResult === true) {
        this.validationResult = validationResult;
        // Get service type code based on selected Location
        let serviceType = this.getServiceTypeBasedOnLocation();

        let activeSubscription = await this.getSubscriptionStatusFromStripe();
        const commision = (parseFloat(this.servicePrice) + 3) / 0.964;

        // New service doc info
        const serviceObject = {
          speciality: "",
          currency: "mxn",
          type: serviceType,
          appointmentsNumber: 0,
          addedSince: new Date(),
          title: this.serviceName,
          price: parseInt(this.servicePrice),
          description: this.serviceDescription,
          duration: parseInt(this.serviceDuration),
          display: activeSubscription,
        };
        this.activeSubscription = activeSubscription;
        if (this.commissionAccepted === true) {
          serviceObject.price = Math.ceil(commision);
        }
        this.serviceObject = serviceObject;
      }
      this.validationResult = false;
    },

    async updateNewServiceRequest() {
      // Store new service info into Firestore
      let user = firebase.auth().currentUser.email;
      await firebase
        .firestore()
        .collection("doctors")
        .doc(user)
        .collection("services")
        .add(this.serviceObject)
        .then(() => {
          // Alert success
          this.dialogTitle = "ENHORABUENA";
          if (this.activeSubscription === true) {
            this.dialogMessage =
              "Se ha guardado exitosamente tu nuevo servicio, a partir de ahora los usuarios de Kuida podrán agendar una cita contigo para este servicio.";
          } else {
            this.dialogMessage =
              "Se ha guardado exitosamente tu nuevo servicio. Este servicio estará a la vista de potenciales pacientes en cuanto tengas una suscripción activa y hayamos aceptado tu cuenta de Kuida.";
          }
          this.alertDialog = true;
          this.confirmAutoRenewalDialogTrigger = false;

          // Clear form fields to create more services.
          this.clearFormFields();
        });
    },

    getServiceTypeBasedOnLocation() {
      switch (this.serviceLocation) {
        case this.locations[0]:
          return 0; // 0 --> At Doctor's Room Appointment
        case this.locations[1]:
          return 1; // 1 --> Online Appointment
        case this.locations[2]:
          return 2; // 0 --> At Home Appointment
        default:
          return 0;
      }
    },

    validateOnlyNumbers(data) {
      // Execute only when parameter received
      if (data !== null) {
        // Regex Pattern for only digits
        let numbersRegex = /^\d+$/;
        let result = false;

        // Compare parameter vs regex pattern
        if (data.toString().match(numbersRegex) !== null) {
          result = true;
        }

        // Return result after comparison
        return result;
      }
    },

    dialogCustomEvent(input) {
      // Update local dialog variable when child emits a close dialog request.
      this.alertDialog = input;
    },

    validateEmptyFields() {
      return (
        this.serviceName !== null &&
        this.servicePrice !== null &&
        this.serviceDuration !== null &&
        this.serviceLocation !== null &&
        this.serviceDescription !== null
      );
    },

    clearFormFields() {
      this.serviceName = null;
      this.servicePrice = null;
      this.serviceDuration = null;
      this.serviceLocation = null;
      this.serviceDescription = null;
    },

    formValidation() {
      let result = false;

      let filledForm = this.validateEmptyFields();
      if (filledForm === true) {
        let validPrice = this.validateOnlyNumbers(this.servicePrice);
        if (validPrice === true) {
          // let validDuration = this.validateOnlyNumbers(this.serviceDuration);
          // if (validDuration === true) {
          //   result = true;
          // } else {
          //   this.alertDialog = true;
          //   this.dialogTitle = "Error";
          //   this.dialogMessage = "La duración del servicio debe estar en minutos y compuesta únicamente por números sin decimales ni caracteres especiales"
          // }
          if (this.servicePrice >= 100) {
            let validDuration = this.validateOnlyNumbers(this.serviceDuration);
            if (validDuration === true) {
              result = true;
            } else {
              this.alertDialog = true;
              this.dialogTitle = "Error";
              this.dialogMessage =
                "La duración del servicio debe estar en minutos y compuesta únicamente por números sin decimales ni caracteres especiales";
            }
          } else {
            this.alertDialog = true;
            this.dialogTitle = "Error";
            this.dialogMessage =
              "El precio de una consulta debe ser igual o mayor a $100 pesos";
          }
        } else {
          this.alertDialog = true;
          this.dialogTitle = "Error";
          this.dialogMessage =
            "El precio debe estar compuesto únicamente por números sin decimales ni caracteres especiales";
        }
      } else {
        this.alertDialog = true;
        this.dialogTitle = "Error";
        this.dialogMessage =
          "Debes llenar todos los campos del formulario para crear un nuevo servicio.";
      }

      return result;
    },

    async getSubscriptionStatusFromStripe() {
      let validSubscription = false;
      let requestObject = {
        customerId: this.customerId,
      };

      let response = await cloud_functions_request(
        "getDoctorsSubscriptions",
        requestObject
      );
      this.subscriptionsList = response.data;

      if (this.subscriptionsList.length > 0) {
        this.subscriptionsList.forEach((subscription) => {
          if (
            subscription["status"] === "active" ||
            subscription["status"] === "trialing"
          ) {
            validSubscription = true;
          }
        });
      }

      return validSubscription;
    },

    closeCancellationRequestDialog() {
      this.confirmAutoRenewalDialogTrigger = false;
    },
  },

  computed: {
    newPrice: function() {
      if (this.servicePrice == null) {
        return 0;
      }
      if (this.commissionAccepted === true) {
        const commision = (parseFloat(this.servicePrice) + 3) / 0.964;
        return Math.ceil(commision);
      } else {
        return this.servicePrice;
      }
    },
  },
};
</script>

<style scoped></style>
