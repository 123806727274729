<template>
    <div>
        <v-toolbar class="mt-14 profile_navbar" color="#E2E2E2" dark flat>
            <v-tabs
                v-model="selectedTab"
                active-class="isActive"
                centered
                color="accent"
                slider-color="accent"
                slider-size="3"
            >
                <v-tab class="pr-6 pl-6 custom-tab">
                    INFORMACIÓN
                </v-tab>
                <v-tab class="pr-6 pl-6 custom-tab">
                    SERVICIOS
                </v-tab>
                <v-tab class="pr-6 pl-6 custom-tab">
                    RESEÑAS
                </v-tab>
                <v-tab class="pr-6 pl-6 custom-tab">
                    PUBLICACIONES
                </v-tab>
                <v-tab class="pr-6 pl-6 custom-tab">
                    SUSCRIPCIÓN
                </v-tab>
                <v-tab class="pr-6 pl-6 custom-tab" @click.stop="confirmLogOut = !confirmLogOut">
                    CERRAR SESIÓN
                </v-tab>
            </v-tabs>

        </v-toolbar>

        <v-tabs-items v-model="selectedTab">
            <v-tab-item>
                <DoctorInfo></DoctorInfo>
            </v-tab-item>
            <v-tab-item>
                <DoctorServices></DoctorServices>
            </v-tab-item>
            <v-tab-item>
                <DoctorReviews></DoctorReviews>
            </v-tab-item>
            <v-tab-item>
                <DoctorPosts></DoctorPosts>
            </v-tab-item>
            <v-tab-item>
                <DoctorSubscription></DoctorSubscription>
            </v-tab-item>
        </v-tabs-items>

        <ActionConfirm_Dialog
            :dialog-trigger="confirmLogOut"
            dialog-title="ATENCIÓN"
            dialog-message="¿Segur@ que quieres cerrar sesión?"
            @action-confirm="signOut"
            @action-cancel="cancelFunction"
        ></ActionConfirm_Dialog>
    </div>
</template>

<script>
import firebase from "firebase";
import router from "@/router";
import DoctorInfo from "./tabs/DoctorInfo_Tab";
import DoctorServices from "@/components/profile/tabs/DoctorServices_Tab";
import DoctorPosts from "@/components/profile/tabs/DoctorPosts_Tab";
import DoctorReviews from "@/components/profile/tabs/DoctorReviews_Tab";
import DoctorSubscription from "@/components/profile/tabs/DoctorSubscription_Tab";
import ActionConfirm_Dialog from "@/components/common_widgets/dialogs/ActionConfirm_Dialog";

export default {
    name: "Doctor_Profile",
    components: {
        DoctorInfo,
        DoctorServices,
        DoctorReviews,
        DoctorPosts,
        DoctorSubscription,
        ActionConfirm_Dialog,
    },
    data() {
        return {
            selectedTab: 0,
            confirmLogOut: false,
        }
    },
    methods: {
        async signOut() {
            await firebase.auth().signOut().then(() => {
                console.log('Sign-out successful.')
                router.push('/')
            }).catch((error) => {
                // An error happened.
                console.log(error)
            });
        },

        cancelFunction() {
            this.selectedTab = 0;
            this.confirmLogOut = !this.confirmLogOut;
        }
    },
}
</script>

<style scoped>
.custom-tab {
    color: #43C0C1 !important;
    opacity: 0.66 !important;
}

.isActive {
    color: #43C0C1;
    opacity: 1 !important;
}

.profile_navbar {
    position: sticky;
}
</style>