<template>
    <v-container fill-height fluid>
        <v-row align="center" justify="center">
            <!-- SUBSCRIPTION AND PAYMENT INFO -->
            <v-col align-self="start" class="px-7" cols="4" justify="space-around">
                <!-- SUBSCRIPTION RESUME SECTION -->
                <SubscriptionResume_Section
                    :subscription-status="subscriptionStatus"
                    :subscriptions-list="subscriptionsList"
                    @subscription-cancelled="refreshAfterCancellingSubscription"
                ></SubscriptionResume_Section>

                <!-- PAYMENT METHOD DETAILS -->
                <PaymentMethodDetails_Section
                    :payment-method-data="paymentMethodData"
                    :subscription-status="subscriptionStatus"
                    :payment-method-status="paymentMethodStatus"
                    @updated-payment-method="getDoctorPaymentMethod"
                ></PaymentMethodDetails_Section>
            </v-col>

            <!-- PLAN SELECTION SECTION -->
            <SubscriptionSelection_Section
                v-if="subscriptionStatus === false"
                :stripe-customer-id="customerId"
                :payment-method-status="paymentMethodStatus"
                :available-subscription-trial="trialDaysAvailability"
                :payment-method-data="paymentMethodData"
                @subscription-started="getSubscriptionStatusFromStripe"
            ></SubscriptionSelection_Section>

            <!-- SUBSCRIPTION DETAILS SECTION -->
            <SubscriptionDetails_Section
                v-if="subscriptionStatus === true"
                :subscriptions-list="subscriptionsList"
                @subscription-auto-renewal-change="getSubscriptionStatusFromStripe"
            ></SubscriptionDetails_Section>

            <!-- CIRCULAR PROGRESS INDICATOR WHILE RETRIEVING DATA -->
            <v-col v-if="subscriptionStatus === null" align-self="center" cols="8">
                <v-container fluid style="height: 60vh;">
                    <v-row align="center" justify="center">
                        <v-progress-circular color="accent" indeterminate></v-progress-circular>
                    </v-row>
                </v-container>
            </v-col>

        </v-row>
    </v-container>
</template>

<script>
import SubscriptionResume_Section from "@/components/profile/sections/SubscriptionResume_Section"
import SubscriptionDetails_Section from "@/components/profile/sections/SubscriptionDetails_Section"
import PaymentMethodDetails_Section from "@/components/profile/sections/PaymentMethodDetails_Section"
import SubscriptionSelection_Section from "@/components/profile/sections/SubscriptionSelection_Section"
import firebase from "firebase";
import {cloud_functions_request} from "@/helpers/cloud_functions_request";

export default {
    name: "DoctorSubscription_Component",
    components: {
        SubscriptionResume_Section,
        SubscriptionDetails_Section,
        SubscriptionSelection_Section,
        PaymentMethodDetails_Section,
    },
    data() {
        return {
            userId: '',
            customerId: '',
            subscriptionsList: [],
            paymentMethodData: null,
            subscriptionStatus: null,
            paymentMethodStatus: null,
            bottomSheetTrigger: false,
            bottomSheetMessage: '',
            trialDaysAvailability: null,
        }
    },
    async beforeMount() {
        this.userId = firebase.auth().currentUser.email;

        await this.getDoctorStripeData();

        await this.getSubscriptionStatusFromStripe();

        await this.getDoctorPaymentMethod();
    },
    methods: {
        async getDoctorStripeData() {
            let doctorData = await firebase.firestore().collection('doctors').doc(this.userId).get().then((snapshot) => {
                return snapshot.data();
            });

            this.trialDaysAvailability = doctorData['availableSubscriptionTrial'];
            this.customerId = doctorData['stripeCustomerId'];
        },

        async getSubscriptionStatusFromStripe() {
            this.clearSubscriptionData();

            let requestObject = {
                'customerId': this.customerId,
            }

            // Get subscriptions list from Stripe
            let response = await cloud_functions_request('getDoctorsSubscriptions', requestObject);

            // Sort subscriptions list from latest to oldest
            this.subscriptionsList = response.data.sort((a, b) => {
                return b['start_date'] - a['start_date'];
            });

            // false in case there is no subscription data
            this.subscriptionStatus = this.subscriptionsList.length > 0;
        },

        async getDoctorPaymentMethod() {
            this.clearPaymentMethodData();

            let requestObject = {
                'customerStripeId': this.customerId,
            }

            // console.log(requestObject);
            let response = await cloud_functions_request('getCustomerPaymentMethod', requestObject);
            // console.log(response);

            if (response.data['paymentMethodId'] === null) {
                this.paymentMethodStatus = false;

            } else {
                this.paymentMethodStatus = true;
                this.paymentMethodData = response.data['paymentMethodData'];

            }

        },

        async refreshAfterCancellingSubscription() {
            await this.getDoctorStripeData();

            await this.getSubscriptionStatusFromStripe();
        },

        clearSubscriptionData() {
            this.subscriptionsList = [];
            this.subscriptionStatus = null;
        },

        clearPaymentMethodData() {
            this.paymentMethodData = null;
            this.paymentMethodStatus = null;
        }
    }
}
</script>