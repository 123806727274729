export const stripeFormattedNamesStatesList = [
  'Ags.',
  'B.C.',
  'B.C.S.',
  'Camp.',
  'Chis.',
  'Chih.',
  'CDMX',
  'Coah.',
  'Col.',
  'Dgo.',
  'Méx.',
  'Gto.',
  'Gro.',
  'Hgo.',
  'Jal.',
  'Mich.',
  'Mor.',
  'Nay.',
  'N.L.',
  'Oax.',
  'Pue.',
  'Qro.',
  'Q.R.',
  'S.L.P.',
  'Sin.',
  'Son.',
  'Tab.',
  'Tamps.',
  'Tlax.',
  'Ver.',
  'Yuc.',
  'Zac.'
];