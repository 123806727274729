import firebase from "firebase/app";
import 'firebase/analytics'
import 'firebase/functions'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'

let firebaseConfig = {
    apiKey: "AIzaSyD4Ih-Ulx1UgvVhjhsjTdCuaBBbbya9nNc",
    authDomain: "healthywoapp.firebaseapp.com",
    databaseURL: "https://healthywoapp.firebaseio.com",
    projectId: "healthywoapp",
    storageBucket: "healthywoapp.appspot.com",
    messagingSenderId: "623084791653",
    appId: "1:623084791653:web:81b0384b3a63e84712238c",
    measurementId: "G-KQDL90NC3Z"
};

if(location.hostname === 'localhost') {
    firebaseConfig = {
        apiKey: "AIzaSyD4Ih-Ulx1UgvVhjhsjTdCuaBBbbya9nNc",
        authDomain: "healthywoapp.firebaseapp.com",
        databaseURL: "https://healthywoapp.firebaseio.com",
        projectId: "healthywoapp",
        storageBucket: "default-bucket",
        messagingSenderId: "623084791653",
        appId: "1:623084791653:web:81b0384b3a63e84712238c",
        measurementId: "G-KQDL90NC3Z"
    };

    firebase.initializeApp(firebaseConfig);

    console.log('%c ~~~~ WELCOME HOME KUIDA DEVELOPER ~~~~ ', 'background: #f96767; color: #fff; padding:40px 80px; border-radius: 5px; margin: 10px 0;');
    console.log('%c You are running Kuida Doctors app locally ', 'background: #3ec6c6; color: #fff; padding:20px 70px; border-radius: 5px;');

    firebase.auth().useEmulator("http://localhost:9099");
    firebase.functions().useEmulator("localhost", 5001);
    firebase.firestore().useEmulator("localhost", 8585);
    firebase.storage().useEmulator("localhost", 9999);

} else {
    console.log('%c ~~~~ WELCOME TO KUIDA DEAR NOSY ~~~~ ', 'background: #f96767; color: #fff; padding:40px 80px; border-radius: 5px; margin: 10px 0;');
    console.log('%c If you can see this it means you got curious about our work. We invite you to apply to join us, send your resume to vacantes@ideen.mx ', 'background: #3ec6c6; color: #fff; padding:20px 70px; border-radius: 5px;');

    firebase.initializeApp(firebaseConfig);
}



firebase.analytics();
export const db = firebase.firestore();
