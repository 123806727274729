<template>
    <v-sheet
        class="mb-10 mx-3"
        elevation="2"
    >
        <v-row align="center" class="px-1" justify="center">
            <!-- IMAGE COLUMN -->
            <v-col cols="6">
                <v-container fill-height fluid>
                    <v-carousel dark height="fill" hide-delimiters next-icon="mdi-chevron-right"
                                prev-icon="mdi-chevron-left" show-arrows-on-hover>
                        <v-carousel-item
                            v-for="(img, i) in item.img"
                            :key="i"
                            :src="img"
                        ></v-carousel-item>
                    </v-carousel>
                </v-container>
            </v-col>

            <!-- POST DETAILS COLUMN -->
            <v-col class="pr-6" cols="6">
                <v-container
                    class="align-content-space-between"
                    style="height: 60vh;"
                    fill-height
                    fluid
                >
                    <!-- CREATOR NAME AND LIKES COUNTER -->
                    <v-row class="pt-2">
                        <v-col cols="8">
                            <v-row align="center" justify="start">
                                <p class="card-top-text">{{ name }}</p>
                            </v-row>
                        </v-col>

                        <v-col cols="4">
                            <v-row align="center" justify="end">
                                <p class="card-top-text">{{ item.likes }}</p>
                                <v-icon class="like-icon mt-n5 ml-1">mdi-heart</v-icon>
                            </v-row>
                        </v-col>
                    </v-row>

                    <!-- POST COPY OUT -->
                    <v-row class="pt-n6" align="start" justify="start">
                        <v-container fluid style="height: 48vh; overflow-y: auto; overflow-x: hidden;">
                            <p class="card-text mt-n3">{{ item.content }}</p>
                        </v-container>
                    </v-row>

                    <!-- DATE & DELETE BUTTON -->
                    <v-row class="pt-2 noSpace" align="center" justify="space-between">
                        <v-btn
                            v-if="item.author === this.user"
                            class="ml-n4"
                            color="primary"
                            plain
                            @click="confirmDeletionDialog = true"
                        >
                            <v-icon left>
                                mdi-delete
                            </v-icon>
                            ELIMINAR PUBLICACIÓN
                        </v-btn>

                        <p class="card-bottom-text pt-4" style="text-align: end;">{{ formatDate(item.date) }}</p>
                    </v-row>
                </v-container>
            </v-col>

            <!-- DELETION CONFIRM DIALOG -->
            <ActionConfirm_Dialog
                :dialog-trigger="confirmDeletionDialog"
                dialog-title="Atención"
                dialog-message="¿Estás seguro que deseas eliminar esta publicación? Esta acción no se puede revertir."
                @action-confirm="deletePostRequest"
                @action-cancel="confirmDeletionDialog = false;"
            ></ActionConfirm_Dialog>

            <!-- SUCCESSFUL DELETION DIALOG -->
            <Alert_Dialog
                :dialog-trigger="successfulDeletionDialog"
                dialog-title="Nota"
                dialog-message="Se ha eliminado exitosamente tu publicación"
                @dialog-close="reloadWindow"
            ></Alert_Dialog>
        </v-row>
    </v-sheet>
</template>

<script>
import firebase from "firebase";
import ActionConfirm_Dialog from "@/components/common_widgets/dialogs/ActionConfirm_Dialog";
import Alert_Dialog from "@/components/common_widgets/dialogs/Alert_Dialog";

export default {
    name: "Post_Card",
    components: {
        ActionConfirm_Dialog,
        Alert_Dialog,
    },
    props: [
        'post'
    ],
    data() {
        return {
            item: null,
            name: null,
            formattedDate: null,
            confirmDeletionDialog: false,
            successfulDeletionDialog: false,
            user: '',
        }
    },
    async created() {
        this.item = this.$props.post;
        this.user = firebase.auth().currentUser.email;
        await this.getDoctorFullName();
    },
    methods: {
        async getDoctorFullName() {
            let doctorId = this.item.author;
            await firebase.firestore().collection('doctors').doc(doctorId).get().then((snapshot) => {
                const data = snapshot.data();

                let title = data.title;
                let firstName = data.firstName;
                let firstLastName = data.lastName;
                let secondLastName = data.secondLastName;

                this.name = title + " " + firstName + " " + firstLastName + " " + secondLastName;
            });
        },

        async deletePostRequest() {
            console.log("Deleting " + this.item.id)
            // const postRef = firebase.storage().ref().child(`posts/${this.item.id}/`);
            // await postRef.listAll().then(dir => {
            //    let list = dir.items;
            //    console.log(list)
            //
            //    // list.forEach((file) => {
            //    //     file.delete();
            //    // })
            // });

            await firebase.firestore().collection('feed').doc(this.item.id).delete().then(() => {
                console.log("Deleted post!")
                this.successfulDeletionDialog = true;
            });
        },

        reloadWindow() {
            this.successfulDeletionDialog = false;

            window.location.reload();
        },

        formatDateNumbers(number) {
            if (number.toString().length === 1) return '0' + number;
            else return number;
        },

        formatDate(date) {
            const dateTime = date.toDate();
            let day = this.formatDateNumbers(dateTime.getDate());
            let month = this.formatDateNumbers((dateTime.getMonth() + 1));
            let year = dateTime.getFullYear();
            return day + '/' + month + '/' + year;
        },
    }
}
</script>

<style scoped>
.card-top-text {
    color: #F96767 !important;
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
    font-weight: 600;
}

.card-bottom-text {
    color: #F96767 !important;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 500;
}

.like-icon {
    color: #F96767 !important;
    font-size: 25px;
}

.card-text {
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
}
</style>