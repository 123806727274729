<template>
    <v-col align="center" cols="3">
        <v-row align="center" justify="center">
            <!-- CARD TITLE -->
            <h2 class="card-title">Información General</h2>

            <!-- EDIT BUTTON -->
            <UpdateGeneralInfo_Form></UpdateGeneralInfo_Form>
        </v-row>

        <!-- CARD -->
        <v-sheet class="rounded-xl mt-3" color="primary" dark elevation="3" height="52vh" style="overflow-y: auto;">
            <v-container class="pl-6" fill-height>
                <v-row align="center" justify="center">
                    <v-col align="start">
                        <!-- SPECIALITY -->
                        <p class="card-info-title">Especialidad</p>
                        <p class="card-info-content mt-n5">{{ doctorData.speciality }}</p>

                        <!-- MEMBER SINCE -->
                        <p class="card-info-title">Miembro Desde</p>
                        <p class="card-info-content mt-n5">{{ formattedRegisterDate }}</p>

                        <!-- EMAIL -->
                        <p class="card-info-title">Correo Electrónico</p>
                        <p class="card-info-content mt-n5">{{ user }}</p>

                        <!-- PHONE -->
                        <p class="card-info-title">Teléfono</p>
                        <p class="card-info-content mt-n5">{{ doctorData.phone }}</p>

                        <!-- TAXES ID -->
                        <p class="card-info-title">RFC</p>
                        <p class="card-info-content mt-n5">{{ doctorData.rfc }}</p>

                        <!-- BIRTHDATE -->
                        <p class="card-info-title">Fecha de Nacimiento</p>
                        <p class="card-info-content mt-n5">{{ formattedBirthDate }}</p>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
    </v-col>
</template>

<script>
import firebase  from "firebase";
import UpdateGeneralInfo_Form from "@/components/profile/dialog_forms/UpdateGeneralInfo_Form";
import * as dateHelper from "@/helpers/date_formatting"

export default {
    name: "GeneralInfo_Section",
    components: {
        UpdateGeneralInfo_Form,
    },
    data() {
        return {
            user: '',
            doctorData: {},
            formattedBirthDate: '',
            formattedRegisterDate: '',
        }
    },
    async created() {
        this.user = firebase.auth().currentUser.email;

        await this.getDoctorInfoDocument();
    },
    methods: {
        async getDoctorInfoDocument() {
            let doctorId = this.user;

            await firebase.firestore().collection('doctors').doc(doctorId).onSnapshot((snapshot) => {
                this.doctorData = snapshot.data();

                this.formattedBirthDate = dateHelper.formatDate(this.doctorData['birthDate']);
                this.formattedRegisterDate =  dateHelper.formatDate(this.doctorData['registerDate'])
            });
        },
    }
}
</script>

<style scoped>

</style>