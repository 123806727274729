<template>
    <!-- INVALID FORM DIALOG -->
    <v-dialog
        v-model="dialogTrigger"
        max-width="700"
        overlay-opacity="0.8"
        persistent
    >
        <v-card class="pa-16" color="white">
            <v-row align="center" justify="center">
                <v-col align-self="center">
                    <h1 class="primary--text mt-n8" style="text-align: center;">{{ dialogTitle.toUpperCase() }}</h1>

                    <p class="mt-4" style="text-align: center; font-size: 0.85em; white-space: pre-line;">{{ dialogMessage }}</p>

                    <v-row align="center" class="mt-8" justify="center">
                        <button
                            class="kuida-md-btn coral-btn button-shadow"
                            @click="$emit('dialog-close', false)"
                        >Cerrar
                        </button>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
name: "Alert_Dialog",
    props: {
        dialogTrigger: Boolean,
        dialogTitle: String,
        dialogMessage: String,
    }
}
</script>

<style scoped>

</style>