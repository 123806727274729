<template>
    <!-- NEW EVENT DIALOG -->
    <v-dialog
        v-model="dialogTrigger"
        overlay-opacity="0.8"
        persistent
    >
        <!-- DIALOG BODY -->
        <v-card class="rounded-xl elevation-0" color="accent">
            <v-container class="px-10 py-8" fluid>
                <!-- HEADER -->
                <v-row align="center" justify="space-between">
                    <!-- TITLE -->
                    <p class="dialogTitle text-uppercase">Agregar Evento</p>

                    <!-- CLOSE DIALOG BUTTON -->
                    <v-btn
                        @click="closeDialog"
                        class="mt-n4 mr-n2"
                        color="white"
                        plain
                        small
                        fab
                    >
                        <v-icon large>mdi-close</v-icon>
                    </v-btn>
                </v-row>

                <!-- DIALOG BODY -->
                <v-row align="center" class="px-8" justify="center">
                    <v-col cols="3"></v-col>

                    <v-col align-self="center" cols="6">
                        <!-- NEW EVENT FORM -->
                        <v-row align="center" class="mt-2" justify="center">
                            <!-- HOUR PICKER -->
                            <v-text-field
                                class="white-input custom-label-color"
                                label="Título del evento"
                                v-model="eventTitle"
                                color="white"
                                outlined
                                rounded
                                dense
                                dark
                            ></v-text-field>
                        </v-row>

                        <!-- SELECT SCHEDULE SUBTITLE -->
                        <v-row align="center"  justify="center">
                            <v-col cols="6" justify="start">
                                <v-row align="center" justify="start">
                                    <font-awesome-icon
                                        icon="clock"
                                        style="color: white;">
                                    </font-awesome-icon>
                                    <p style="color: white; font-size: 17px; margin-left: 20px; margin-top: 15px;">
                                        Selecciona un horario
                                    </p>
                                </v-row>
                            </v-col>
                            <v-col cols="6">
                                <v-row align="center" justify="end">
                                    <p style="color: white; font-size: 17px; margin-top: 15px; margin-right: 10px;">
                                        Todo el día
                                    </p>
                                    <v-switch
                                        style="color: white; margin-right: -5px;"
                                        v-model="allDay"
                                        color="white"
                                    ></v-switch>
                                </v-row>
                            </v-col>
                        </v-row>

                        <!-- SELECT SCHEDULE SELECTOR -->
                        <v-row align="center" justify="center">
                            <v-select
                                :rules="[v => !!v || 'Campo obligatorio']"
                                v-model="eventFrequencySelection"
                                label="¿Tu evento se repetirá?"
                                :items="eventFrequencyOptions"
                                append-icon="mdi-arrow-down"
                                item-color="#FFFFFF"
                                color="#FFFFFF"
                                outlined
                                required
                                rounded
                                dense
                                dark
                            ></v-select>
                        </v-row>

                        <!-- MULTIPLE DAY EVENT DATES RANGE PICKER -->
                        <v-row
                            v-if="displayUpperDateLimitField() === true"
                            justify="center"
                            align="center"
                        >
                            <!-- DATE PICKER -->
                            <v-menu
                                :close-on-content-click="false"
                                transition="scale-transition"
                                v-model="datePickerTrigger"
                                ref="menu"
                                offset-y
                                rounded
                                dark
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        label="Fecha inicial y límite del evento (selecciona 2 fechas)"
                                        append-icon="mdi-calendar"
                                        v-model="newEventDate"
                                        v-bind="attrs"
                                        v-on="on"
                                        readonly
                                        outlined
                                        dense
                                        dark
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    @change="saveDateSelection"
                                    v-model="newEventDate"
                                    color="primary"
                                    ref="picker"
                                    width="550"
                                    light
                                    range
                                ></v-date-picker>
                            </v-menu>
                        </v-row>

                        <!-- SINGLE DAY EVENT DATE PICKER -->
                        <v-row
                            justify="center"
                            align="center"
                            v-else
                        >
                            <!-- DATE PICKER -->
                            <v-menu
                                close-on-content-click="false"
                                transition="scale-transition"
                                v-model="datePickerTrigger"
                                ref="menu"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        append-icon="mdi-calendar"
                                        label="Fecha de evento"
                                        v-model="newEventDate"
                                        v-bind="attrs"
                                        v-on="on"
                                        readonly
                                        outlined
                                        dense
                                        dark
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    :min="new Date().toISOString().substr(0, 10)"
                                    @change="saveDateSelection"
                                    v-model="newEventDate"
                                    color="primary"
                                    ref="picker"
                                    width="550"
                                    light
                                ></v-date-picker>
                            </v-menu>
                        </v-row>

                        <!-- EVENT SCHEDULE PICKERS -->
                        <v-row v-if="allDay === false" align="center" justify="center">
                            <!-- START HOUR PICKER -->
                            <v-text-field
                                class="white-input custom-label-color ml-3"
                                @click="timeRangeSelectionDialog = true;"
                                style="margin-left: 0 !important;"
                                v-model="newEventStartHour"
                                label="Hora de inicio"
                                item-color="#FFFFFF"
                                color="#FFFFFF"
                                type="time"
                                outlined
                                rounded
                                dense
                                dark
                            ></v-text-field>

                            <!-- FINISH HOUR PICKER -->
                            <v-text-field
                                class="white-input custom-label-color ml-3"
                                label="Hora de finalización"
                                v-model="newEventFinishHour"
                                item-color="#FFFFFF"
                                color="#FFFFFF"
                                type="time"
                                outlined
                                rounded
                                dense
                                dark
                            ></v-text-field>
                        </v-row>
                    </v-col>

                    <v-col cols="3"></v-col>
                </v-row>

                <!-- SAVE DATA BUTTON -->
                <v-row align="center" class="mt-8 mb-2" justify="center">
                    <button
                        class="kuida-md-btn coral-btn button-shadow mt-2"
                        @click="createNewEventRequest"
                        type="button"
                    >
                        Guardar Evento
                    </button>
                </v-row>

            </v-container>
        </v-card>

        <!-- NEW EVENT HOURS RANGE SELECTION -->
        <v-dialog
            v-model="timeRangeSelectionDialog"
            overlay-color="black"
            overlay-opacity="0.9"
            ref="dialog"
            height="800"
            width="850"
            persistent
        >
            <v-row
                justify="center"
                align="center"
                class="mt-4"
            >
                <!-- START HOUR SELECTOR -->
                <v-col align="center" style="width: 350px;">
                    <!-- TITLE -->
                    <h2>Hora de inicio</h2>

                    <!-- HOUR SELECTOR -->
                    <v-time-picker
                        v-model="newEventStartHour"
                        :max="newEventFinishHour"
                    ></v-time-picker>
                </v-col>

                <!-- FINISH HOUR SELECTOR -->
                <v-col align="center" style="width: 350px;">
                    <!-- TITLE -->
                    <h2>Hora de finalización</h2>

                    <!-- HOUR SELECTOR -->
                    <v-time-picker
                        v-model="newEventFinishHour"
                        :min="newEventStartHour"
                    ></v-time-picker>
                </v-col>
            </v-row>

            <!-- CONFIRM SCHEDULE SELECTION BUTTON -->
            <v-row
                class="mb-4 mt-10"
                justify="center"
            >
                <!-- CLOSE DIALOG BUTTON -->
                <button
                    class="kuida-big-btn coral-btn button-shadow"
                    @click="timeRangeSelectionDialog = false"
                >
                    Confirmar selección
                </button>
            </v-row>

        </v-dialog>

        <!-- ALERT MESSAGES BOTTOM SHEET -->
        <SimpleMessage_BottomSheet
            @close-bottom-sheet="bottomSheetTrigger = false;"
            :bottom-sheet-trigger="bottomSheetTrigger"
            :bottom-sheet-message="bottomSheetMessage"
        ></SimpleMessage_BottomSheet>

        <!-- FILES LOADING INDICATOR -->
        <Loading_Dialog
            :loading-dialog-trigger="loadingDialog"
            dialog-message="Espera un momento"
        ></Loading_Dialog>

    </v-dialog>
</template>

<script>
import SimpleMessage_BottomSheet from "@/components/common_widgets/bottom_sheets/SimpleMessage_BottomSheet"
import Loading_Dialog from "@/components/common_widgets/dialogs/Loading_Dialog";
import {Timestamp} from "@/main";
import firebase from "firebase";

export default {
    name: "NewEvent_DialogForm",
    props: {
        dialogTrigger: Boolean,
    },
    components: {
        SimpleMessage_BottomSheet,
        Loading_Dialog,
    },
    data() {
        return {
            currentUser: '',

            timeRangeSelectionDialog: false,
            eventFrequencyOptions: [
                'No se repite',
                'Repetir entre semana',
                'Repetir Diario'
            ],
            eventFrequencySelection: '',
            newEventFinishHour: null,
            newEventStartHour: null,
            datePickerTrigger: null,
            newEventDate: null,
            eventTitle: '',
            allDay: false,
            schedule: null,
            parsedSchedule: {},

            // Alerts Variables
            bottomSheetTrigger: false,
            bottomSheetMessage: '',
            loadingDialog: false,
        }
    },
    async beforeMount() {
        this.currentUser = firebase.auth().currentUser.email;

        // We get doctor schedule info for future usage
        await this.getDoctorSchedule();

    },
    methods: {
        greaterFinishHourValidation(startHour, startMinute, finishHour, finishMinute) {
            if (startHour > finishHour) {
                return false;
            } else if (startHour < finishHour) {
                return true;
            } else if (startHour === finishHour) {
                if (startMinute > finishMinute) {
                    return false;
                } else if (startMinute < finishMinute) {
                    return true;
                } else if (startHour === finishHour) {
                    return false;
                }
            }
        },

        //Function to saveGraduationDate to the appointments collection
        async saveEvent(date, startDate, finishDate, duration) {
            const eventObject = {
                "date": date, // Appointment date
                "startDate": startDate, // Appointment date
                "finishDate": finishDate, // Appointment date
                "doctor": this.currentUser, // doctor's document id
                "service": this.eventTitle,
                "type": 2, // 1 = Appointment, 2 = Event, 3 = break
                "duration": duration, // In minutes
            };

            await firebase.firestore().collection('appointments').add(eventObject)
        },

        multipleDateEventValidation() {
            if (this.newEventDate.length === 2) {
                let start = this.addDays(new Date(this.newEventDate[0]), 1);
                let finish = this.addDays(new Date(this.newEventDate[1]), 1);

                let finishBeforeStart = start >= finish; // This validates finish date is after start date (not equal, nor before)

                return !finishBeforeStart;

            } else {
                return false;
            }
        },

        //SAVES A NEW EVENT IN FIREBASE
        async createNewEventRequest() {

            if (this.eventTitle !== '') {

                if (this.eventFrequencySelection !== '') {

                    if (this.allDay === true) {

                        if (this.eventFrequencySelection === 'No se repite') {

                            if (this.singleDateEventValidation()) {

                                this.loadingDialog = true;

                                // Get information from selected day by user
                                let date = new Date(this.newEventDate);
                                let day = date.getDate() + 1;
                                let month = date.getMonth();
                                let year = date.getFullYear();
                                let dayName = this.getDayFullName(date.getDay());

                                // Get schedule info from selected day
                                let entryHour = this.schedule[dayName][0].toDate();
                                let leavingHour = this.schedule[dayName][1].toDate();

                                // Create an event that starts at entry hour until leaving hour of selected day
                                let h1 = new Date(year, month, day, entryHour.getHours(), entryHour.getMinutes(), 0);
                                let h2 = new Date(year, month, day, leavingHour.getHours(), leavingHour.getMinutes(), 0);
                                let startHour = Timestamp.fromDate(h1);
                                let finishHour = Timestamp.fromDate(h2);

                                let diff = (h2.getTime() - h1.getTime()) / 1000; // Convert into milliseconds
                                diff /= 60; // Convert into minutes
                                let duration = Math.abs(Math.round(diff)); // Difference between startHour and finishHour in minutes

                                // Store desired full day event
                                await this.saveEvent(startHour, startHour, finishHour, duration);

                                this.loadingDialog = false;

                                //We close the popup
                                this.closeDialog();


                            } else {
                                this.bottomSheetMessage = 'Favor de seleccionar una fecha para la nueva cita.';
                                this.bottomSheetTrigger = true;
                            }

                        } else if (this.eventFrequencySelection === 'Repetir entre semana') {

                            if (this.multipleDateEventValidation()) {
                                this.loadingDialog = true;

                                let initialDate = this.addDays(new Date(this.newEventDate[0]), 1);// Ads 1 day because there's a Vuetify date range picker bug
                                let limitDate = this.addDays(new Date(this.newEventDate[1]), 2); // Ads 2 days to include limit date

                                let diff = (initialDate.getTime() - limitDate.getTime()) / 1000; // Convert into milliseconds
                                diff /= 60; // Convert into minutes
                                diff /= 60;
                                diff /= 24;
                                let daysBetween = Math.abs(Math.round(diff)); // Difference between startHour and finishHour in minutes

                                for (let i = 0; i < daysBetween; i++) {
                                    let auxDate = this.addDays(initialDate, i);
                                    let weekDay = auxDate.getDay();

                                    if (weekDay >= 1 && weekDay <= 5) {
                                        let day = auxDate.getDate();
                                        let month = auxDate.getMonth();
                                        let year = auxDate.getFullYear();
                                        let dayName = this.getDayFullName(weekDay);

                                        // Get schedule info from selected day
                                        let entryHour = this.schedule[dayName][0].toDate();
                                        let leavingHour = this.schedule[dayName][1].toDate();

                                        // Create an event that starts at entry hour until leaving hour of selected day
                                        let h1 = new Date(year, month, day, entryHour.getHours(), entryHour.getMinutes(), 0);
                                        let h2 = new Date(year, month, day, leavingHour.getHours(), leavingHour.getMinutes(), 0);
                                        let startHour = Timestamp.fromDate(h1);
                                        let finishHour = Timestamp.fromDate(h2);

                                        let timeDiff = (h2.getTime() - h1.getTime()) / 1000; // Convert into milliseconds
                                        timeDiff /= 60; // Convert into minutes
                                        let duration = Math.abs(Math.round(timeDiff)); // Difference between startHour and finishHour in minutes

                                        await this.saveEvent(startHour, startHour, finishHour, duration);
                                    }
                                }

                                this.loadingDialog = false;

                                //We close the popup
                                this.closeDialog();

                            } else {
                                this.bottomSheetMessage = 'Favor de seleccionar una fecha de inicio y una fin para definir hasta cuando deseas repetir el nuevo evento.';
                                this.bottomSheetTrigger = true;

                            }

                        } else if (this.eventFrequencySelection === 'Repetir Diario') {

                            if (this.multipleDateEventValidation()) {
                                this.loadingDialog = true;

                                let initialDate = this.addDays(new Date(this.newEventDate[0]), 1);// Ads 1 day because there's a Vuetify date range picker bug
                                let limitDate = this.addDays(new Date(this.newEventDate[1]), 2); // Ads 2 days to include limit date

                                let diff = (initialDate.getTime() - limitDate.getTime()) / 1000; // Convert into milliseconds
                                diff /= 60; // Convert into minutes
                                diff /= 60;
                                diff /= 24;
                                let daysBetween = Math.abs(Math.round(diff)); // Difference between startHour and finishHour in minutes

                                for (let i = 0; i < daysBetween; i++) {
                                    let auxDate = this.addDays(initialDate, i);
                                    let weekDay = auxDate.getDay();

                                    let day = auxDate.getDate();
                                    let month = auxDate.getMonth();
                                    let year = auxDate.getFullYear();
                                    let dayName = this.getDayFullName(weekDay);

                                    // Get schedule info from selected day
                                    let entryHour = this.schedule[dayName][0].toDate();
                                    let leavingHour = this.schedule[dayName][1].toDate();

                                    // Create an event that starts at entry hour until leaving hour of selected day
                                    let h1 = new Date(year, month, day, entryHour.getHours(), entryHour.getMinutes(), 0);
                                    let h2 = new Date(year, month, day, leavingHour.getHours(), leavingHour.getMinutes(), 0);
                                    let startHour = Timestamp.fromDate(h1);
                                    let finishHour = Timestamp.fromDate(h2);

                                    let timeDiff = (h2.getTime() - h1.getTime()) / 1000; // Convert into milliseconds
                                    timeDiff /= 60; // Convert into minutes
                                    let duration = Math.abs(Math.round(timeDiff)); // Difference between startHour and finishHour in minutes

                                    await this.saveEvent(startHour, startHour, finishHour, duration);
                                }

                                this.loadingDialog = false;

                                //We close the popup
                                this.closeDialog();

                            } else {
                                this.bottomSheetMessage = 'Favor de seleccionar una fecha de inicio y una fin para definir hasta cuando deseas repetir el nuevo evento.';
                                this.bottomSheetTrigger = true;

                            }

                        }
                    } else {

                        if (this.newEventStartHour !== null && this.newEventFinishHour !== null) {

                            if (this.eventFrequencySelection === 'No se repite') {

                                if (this.singleDateEventValidation()) {

                                    this.loadingDialog = true;

                                    let date = new Date(this.newEventDate);
                                    let day = date.getDate() + 1;
                                    let month = date.getMonth();
                                    let year = date.getFullYear();
                                    let start = this.newEventStartHour;
                                    let startH = start.toString().substring(0, 2);
                                    let startM = start.toString().substring(3, 5);
                                    let finish = this.newEventFinishHour;
                                    let finishH = finish.toString().substring(0, 2);
                                    let finishM = finish.toString().substring(3, 5);

                                    if (this.greaterFinishHourValidation(parseInt(startH), parseInt(startM), parseInt(finishH), parseInt(finishM))) {
                                        let h1 = new Date(year, month, day, parseInt(startH), parseInt(startM), 0);
                                        let h2 = new Date(year, month, day, parseInt(finishH), parseInt(finishM), 0);
                                        let startHour = Timestamp.fromDate(h1);
                                        let finishHour = Timestamp.fromDate(h2);

                                        let diff = (h2.getTime() - h1.getTime()) / 1000; // Convert into milliseconds
                                        diff /= 60; // Convert into minutes
                                        let duration = Math.abs(Math.round(diff)); // Difference between startHour and finishHour in minutes

                                        await this.saveEvent(startHour, startHour, finishHour, duration);

                                        this.loadingDialog = false;

                                        //We close the popup
                                        this.closeDialog();

                                    } else {
                                        this.loadingDialog = false;

                                        this.bottomSheetMessage = 'La hora de finalización de tu evento debe ser posterior a la de inicio.';
                                        this.bottomSheetTrigger = true;
                                    }

                                } else {
                                    this.bottomSheetMessage = 'Favor de seleccionar una fecha para la nueva cita.';
                                    this.bottomSheetTrigger = true;

                                }

                            } else if (this.eventFrequencySelection === 'Repetir entre semana') {

                                if (this.multipleDateEventValidation()) {

                                    this.loadingDialog = true;

                                    let initialDate = this.addDays(new Date(this.newEventDate[0]), 1);// Ads 1 day because there's a Vuetify date range picker bug
                                    let limitDate = this.addDays(new Date(this.newEventDate[1]), 2);// Ads 2 days to include limit date

                                    let diff = (initialDate.getTime() - limitDate.getTime()) / 1000; // Convert into milliseconds
                                    diff /= 60; // Convert into minutes
                                    diff /= 60;
                                    diff /= 24;
                                    let daysBetween = Math.abs(Math.round(diff)); // Difference between startHour and finishHour in minutes

                                    let start = this.newEventStartHour;
                                    let startH = start.toString().substring(0, 2);
                                    let startM = start.toString().substring(3, 5);
                                    let finish = this.newEventFinishHour;
                                    let finishH = finish.toString().substring(0, 2);
                                    let finishM = finish.toString().substring(3, 5);
                                    let startHour = new Date(0, 0, 0, parseInt(startH), parseInt(startM), 0);
                                    let finishHour = new Date(0, 0, 0, parseInt(finishH), parseInt(finishM), 0);

                                    if (this.greaterFinishHourValidation(parseInt(startH), parseInt(startM), parseInt(finishH), parseInt(finishM))) {
                                        let timeDiff = (finishHour.getTime() - startHour.getTime()) / 1000; // Convert into milliseconds
                                        timeDiff /= 60; // Convert into seconds
                                        let duration = Math.abs(Math.round(timeDiff)); // Difference between startHour and finishHour in minutes

                                        for (let i = 0; i <= daysBetween; i++) {
                                            let auxDate = this.addDays(initialDate, i);
                                            let weekDay = auxDate.getDay();

                                            if (weekDay >= 1 && weekDay <= 5) {
                                                let day = auxDate.getDate();
                                                let month = auxDate.getMonth();
                                                let year = auxDate.getFullYear();
                                                let startDate = new Date(year, month, day, parseInt(startH), parseInt(startM), 0);
                                                let finishDate = new Date(year, month, day, parseInt(finishH), parseInt(finishM), 0);
                                                let startTimestamp = Timestamp.fromDate(startDate);
                                                let finishTimestamp = Timestamp.fromDate(finishDate);

                                                await this.saveEvent(startTimestamp, startTimestamp, finishTimestamp, duration);
                                            }
                                        }

                                        this.loadingDialog = false;

                                        //We close the popup
                                        this.closeDialog();

                                    } else {
                                        this.loadingDialog = false;

                                        this.bottomSheetMessage = 'La hora de finalización de tu evento debe ser posterior a la de inicio.';
                                        this.bottomSheetTrigger = true;

                                    }
                                } else {
                                    this.bottomSheetMessage = 'Favor de seleccionar una fecha de inicio y una fin para definir hasta cuando deseas repetir el nuevo evento.';
                                    this.bottomSheetTrigger = true;

                                }

                            } else if (this.eventFrequencySelection === 'Repetir Diario') {

                                if (this.multipleDateEventValidation()) {

                                    this.loadingDialog = true;

                                    let initialDate = this.addDays(new Date(this.newEventDate[0]), 1);// Ads 1 day because there's a Vuetify date range picker bug
                                    let limitDate = this.addDays(new Date(this.newEventDate[1]), 2);// Ads 2 days to include limit date

                                    let diff = (initialDate.getTime() - limitDate.getTime()) / 1000; // Convert into milliseconds
                                    diff /= 60; // Convert into minutes
                                    diff /= 60;
                                    diff /= 24;
                                    let daysBetween = Math.abs(Math.round(diff)); // Difference between startHour and finishHour in minutes

                                    let start = this.newEventStartHour;
                                    let startH = start.toString().substring(0, 2);
                                    let startM = start.toString().substring(3, 5);
                                    let finish = this.newEventFinishHour;
                                    let finishH = finish.toString().substring(0, 2);
                                    let finishM = finish.toString().substring(3, 5);
                                    let startHour = new Date(0, 0, 0, parseInt(startH), parseInt(startM), 0);
                                    let finishHour = new Date(0, 0, 0, parseInt(finishH), parseInt(finishM), 0);

                                    if (this.greaterFinishHourValidation(parseInt(startH), parseInt(startM), parseInt(finishH), parseInt(finishM))) {
                                        let timeDiff = (finishHour.getTime() - startHour.getTime()) / 1000; // Convert into milliseconds
                                        timeDiff /= 60; // Convert into seconds
                                        let duration = Math.abs(Math.round(timeDiff)); // Difference between startHour and finishHour in minutes

                                        for (let i = 0; i <= daysBetween; i++) {
                                            let auxDate = this.addDays(initialDate, i);

                                            let day = auxDate.getDate();
                                            let month = auxDate.getMonth();
                                            let year = auxDate.getFullYear();
                                            let startDate = new Date(year, month, day, parseInt(startH), parseInt(startM), 0);
                                            let finishDate = new Date(year, month, day, parseInt(finishH), parseInt(finishM), 0);
                                            let startTimestamp = Timestamp.fromDate(startDate);
                                            let finishTimestamp = Timestamp.fromDate(finishDate);

                                            await this.saveEvent(startTimestamp, startTimestamp, finishTimestamp, duration);
                                        }

                                        this.loadingDialog = false;

                                        //We close the popup
                                        this.closeDialog();

                                    } else {
                                        this.loadingDialog = false;

                                        this.bottomSheetMessage = 'La hora de finalización de tu evento debe ser posterior a la de inicio.';
                                        this.bottomSheetTrigger = true;

                                    }
                                } else {
                                    this.bottomSheetMessage = 'Favor de seleccionar una fecha de inicio y una fin para definir hasta cuando deseas repetir el nuevo evento.';
                                    this.bottomSheetTrigger = true;

                                }

                            }

                        } else {
                            this.bottomSheetMessage = 'Favor de seleccionar una hora para la nueva cita.'
                            this.bottomSheetTrigger = true;

                        }
                    }
                } else {
                    this.bottomSheetMessage = 'Favor de seleccionar una frecuencia para el nuevo evento.';
                    this.bottomSheetTrigger = true;

                }
            } else {
                this.bottomSheetMessage = 'Favor de introducir un título para tu nuevo evento.';
                this.bottomSheetTrigger = true;

            }
        },

        displayUpperDateLimitField() {
            return this.eventFrequencySelection === 'Repetir entre semana' || this.eventFrequencySelection === 'Repetir Diario';
        },

        singleDateEventValidation() {
            return this.newEventDate !== null;
        },

        async getDoctorSchedule() {
            await firebase.firestore().collection('doctors').doc(this.currentUser).onSnapshot((snapshot) => {
                this.schedule = snapshot.data()['schedule'];

                this.parseDoctorSchedule();
            });

        },

        parseDoctorSchedule() {
            let scheduleMap = this.schedule;

            this.parsedSchedule['monday'] = this.timestampArrayToDates(scheduleMap['monday']);
            this.parsedSchedule['tuesday'] = this.timestampArrayToDates(scheduleMap['tuesday']);
            this.parsedSchedule['wednesday'] = this.timestampArrayToDates(scheduleMap['wednesday']);
            this.parsedSchedule['thursday'] = this.timestampArrayToDates(scheduleMap['thursday']);
            this.parsedSchedule['friday'] = this.timestampArrayToDates(scheduleMap['friday']);
            this.parsedSchedule['saturday'] = this.timestampArrayToDates(scheduleMap['saturday']);
            this.parsedSchedule['sunday'] = this.timestampArrayToDates(scheduleMap['sunday']);
        },

        timestampArrayToDates(day) {
            let result = [];

            result[0] = day[0].toDate().toTimeString().substring(0, 5);
            result[1] = day[1].toDate().toTimeString().substring(0, 5);

            return result;
        },

        getDayFullName(dayNumber) {
            if (dayNumber === 0) return 'sunday'
            if (dayNumber === 1) return 'monday'
            if (dayNumber === 2) return 'tuesday'
            if (dayNumber === 3) return 'wednesday'
            if (dayNumber === 4) return 'thursday'
            if (dayNumber === 5) return 'friday'
            if (dayNumber === 6) return 'saturday'
        },

        saveDateSelection(date) {
            this.$refs.menu.save(date)

            this.formattedDate = new Date(this.date)
        },

        addDays(date, days) {
            let result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
        },

        clearEventFields() {
            this.eventTitle = '';
            this.select = '';
            this.allDay = false;
            this.newEventDate = null;
            this.newEventStartHour = null;
            this.newEventFinishHour = null;
            this.eventFrequencySelection = '';
        },

        closeDialog() {
            // Clear any form field
            this.clearEventFields();

            // Emit close request
            this.$emit('dialog-close');
        },
    }
}
</script>

<style scoped>
.dialogTitle {
    color: #FFF;
    font-size: 1.5em !important;
    font-weight: bold;
}

.dialogSubtitle {
    color: #FFF;
    font-size: 0.7em !important;
    font-weight: bold;
    text-align: center;
}
</style>
