<!-- GRID 2 * 2-->
<!-- THE UPPER AND LOWER LEFT SIDES OCCUPY 7 COLUMNS, WHILE THEIR COUNTERPARTS ON THE RIGHT SIDE ONLY OCCUPY 5. -->
<!-- THE UPPER AND LOWER LEFT SIDES EACH CONTAIN 3 ELEMENTS, EACH OCCUPYING 4 COLUMNS. -->
<template>
  <v-container class="payments-container" fluid fill-height>
    <v-row>
      <!-- UPPER LEFT SIDE -->
      <v-col cols="12" lg="7" sm="12">
        <v-card class="fill-height" style="box-shadow: none;">
          <p :style="{ color: aquaColor }" class="font-weight-bold text-center text-xl-h4 text-lg-h6 ">
            Ingresos generados este mes con Kuida.
          </p>
          <v-row>
            <v-col cols="12" sm="4" class="pa-0">
              <PaymentInfo_Card
                :color="aquaColor"
                :data=incomesPaymentCard.available
                :icon="'monetization_on'"
                :title="'Total generado'"
              ></PaymentInfo_Card>
            </v-col>
            <v-col cols="12" sm="4" class="pa-0">
              <PaymentInfo_Card
                :color="aquaColor"
                :data=incomesPaymentCard.pending
                :icon="'credit_score'"
                :title="'En tránsito'"
              ></PaymentInfo_Card>
            </v-col>
            <v-col cols="12" sm="4" class="pa-0">
              <PaymentInfo_Card
                :color="aquaColor"
                :data=incomesPaymentCard.payouts
                :icon="'payments'"
                :title="'Depositado en tu banco'"
              ></PaymentInfo_Card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- UPPER RIGHT SIDE -->
      <v-col cols="12" lg="5" sm="12">
        <v-card class="fill-height" style="box-shadow: none;">
          <p :style="{ color: aquaColor }" class="font-weight-bold text-center text-xl-h4 text-lg-h6 ">
            Reporte anual de ingresos.
          </p>
          <ChartAnnualIncomeReport/>
        </v-card>
      </v-col>
      <!-- LOWER LEFT SIDE -->
      <v-col cols="12" lg="7" sm="12">
        <v-card class="fill-height" style="box-shadow: none;">
          <p :style="{ color: aquaColor }" class="font-weight-bold text-center text-xl-h4 text-lg-h6 ">
            Fuentes de Ingresos con Kuida.
          </p>
            <v-row>
              <v-col cols="12" sm="4" class="pa-0">
                <PaymentInfo_Card
                  :color="aquaColor"
                  :data=incomesPaymentCard.sumPatients
                  :icon="'perm_contact_calendar'"
                  :title="'Consultas reservadas por pacientes'"
                ></PaymentInfo_Card>
              </v-col>
              <v-col cols="12" sm="4" class="pa-0">
                <PaymentInfo_Card
                  :color="aquaColor"
                  :data=incomesPaymentCard.sumInvitation
                  :icon="'contact_mail'"
                  :title="'Invitaciones aceptadas por pacientes'"
                ></PaymentInfo_Card>
              </v-col>
              <v-col cols="12" sm="4" class="pa-0">
                <PaymentInfo_Card
                  :color="aquaColor"
                  :data=incomesPaymentCard.sumGeneral
                  :icon="'event'"
                  :title="'Culaquier método de reservación de consulta'"
                ></PaymentInfo_Card>
              </v-col>
            </v-row>
        </v-card>
      </v-col>
      <!-- LOWER RIGHT SIDE -->
      <v-col cols="12" lg="5" sm="12">
        <v-card class="fill-height" style="box-shadow: none;">
          <p :style="{ color: aquaColor }" class="font-weight-bold text-center text-xl-h4 text-lg-h6 ">
            Mi información financiera.
          </p>
          <BankInfoPayments_Section
            :available-money="availableMoney"
            :available-amount="availableAmount"
          ></BankInfoPayments_Section>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PaymentInfo_Card from "@/components/common_widgets/cards/PaymentInfo_Card";
import BankInfoPayments_Section from "@/components/appointments/BankInfoPayments_Section";
import ChartAnnualIncomeReport from "@/components/appointments/charts/ChartAnnualIncomeReport";

import firebase from "firebase";
import {cloud_functions_request} from "@/helpers/cloud_functions_request";

export default {
  components: {
    PaymentInfo_Card,
    BankInfoPayments_Section,
    ChartAnnualIncomeReport,
  },  
  data() {
    return {
      aquaColor: "#5ac6c8",

      incomesPaymentCard: {},
    };
  },
  async created(){
    try {
      // Get the current user's email from Firebase authentication
      const doctorId = firebase.auth().currentUser.email;

      // Make a POST request to a local server or cloud function endpoint
      let response = await cloud_functions_request('paymentsScreenData', { doctorId });

      // Update the component's state with the received data or an empty object if data is undefined
      this.incomesPaymentCard = response.data.incomesPaymentCard || {}; //letrero no se obtienen datos en caso de no encontrar datos
    } catch(error) {
       // Handle errors by logging them to the console
      console.error("Error fetching appointment data: ", error);
    }
  },
};
</script>

<style scoped>
.payments-container {
  margin-top: auto;
  padding-top: 100px;
  height: 100vh;
  width: 100vw;
  background-color: white;
  overflow-y: auto;
}
</style>