<template>
    <!-- EDIT ROOM INFO TRIGGER -->
    <v-dialog
            v-model="openRoomEditDialog"
            overlay-opacity="0.8"
            persistent
            width="fill"
    >
        <!-- EDIT BUTTON -->
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    icon
            >
                <!-- TODO Add functionality -->
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </template>

        <!-- EDIT DIALOG -->
        <v-card class="rounded-xl elevation-0" color="accent">
            <v-container class="px-10 py-8" fluid style="height: 90vh">
                <!-- HEADER -->
                <v-row align="center" justify="space-between">
                    <!-- TITLE -->
                    <p class="dialogTitle text-uppercase">Editar Información de Consultorio</p>

                    <!-- CLOSE DIALOG BUTTON -->
                    <v-btn
                            class="mt-n4 mr-n2"
                            color="white"
                            fab
                            plain
                            small
                            @click="openRoomEditDialog = false"
                    >
                        <v-icon large>mdi-close</v-icon>
                    </v-btn>
                </v-row>

                <!-- DIALOG BODY -->
                <v-row align="center" class="px-8 mt-n6" justify="center">
                    <!-- GENERAL INFO COLUMN -->
                    <v-col align="center" class="px-10" cols="6">
                        <v-container style="height: 57vh; padding-top: 0;">
                            <!-- DETAILS SUBTITLE -->
                            <p class="dialogSubtitle">Detalles de Consultorio</p>

                            <!-- ROOM NAME TEXT FIELD -->
                            <v-text-field
                                    v-model="roomName"
                                    color="white"
                                    dark
                                    dense
                                    label="Nombre de consultorio, clínica u hospital"
                                    outlined
                            ></v-text-field>

                            <!-- ROOM TYPE SELECTOR -->
                            <v-select
                                    v-model="roomType"
                                    :items="types"
                                    append-icon="mdi-arrow-down"
                                    class="mt-n2 mb-n2"
                                    color="#FFFFFF"
                                    dark
                                    dense
                                    item-color="#FFFFFF"
                                    menu-props='{class:coral}'
                                    outlined
                                    placeholder="Tipo de Consultorio"
                            ></v-select>

                            <!-- ROOM NAME TEXT FIELD -->
                            <v-text-field
                                    v-model="roomPhone"
                                    color="white"
                                    dark
                                    dense
                                    label="Teléfono de Consultorio"
                                    outlined
                            ></v-text-field>

                            <v-row align="center" justify="center">
                                <!-- OPEN FILES PICKER BUTTON -->
                                <button
                                        class="kuida-sm-btn whiteaqua-btn button-shadow"
                                        type="button"
                                        @click="onFilesSelectionRequest"
                                >
                                    Añadir imágenes
                                </button>

                                <!-- FILES PICKER -->
                                <input
                                        ref="uploader"
                                        accept="image/*"
                                        class="d-none"
                                        multiple
                                        type="file"
                                        @change="onFileChanged"
                                >
                            </v-row>

                            <!-- IMAGES NUMBER LABEL -->
                            <p class="mt-4" style="color: white; font-size: 0.6em;">
                                Máximo 6 imágenes
                            </p>

                            <!-- SELECTED IMAGES PREVIEW -->
                            <v-row justify="center">
                                <v-col v-for="(image, index) in images" :key="index" class="pa-0"
                                       cols="4">
                                    <v-hover v-slot="{ hover }">
                                        <v-card class="my-2" color="#F96767" height="12vh" width="12vh">
                                            <!-- IMAGE PREVIEW -->
                                            <v-img
                                                    v-if="hover === false"
                                                    :src="image"
                                                    height="100%"
                                            ></v-img>

                                            <!-- DELETE SELECTION BUTTON (ON HOVER) -->
                                            <v-container fill-height fluid style="color: #00c853">
                                                <v-row align="center" class="pa-0" justify="center">
                                                    <v-btn
                                                            v-if="hover === true"
                                                            color="#FFF"
                                                            icon
                                                            x-large
                                                            @click="deleteImages(index)"
                                                    >
                                                        <v-icon>mdi-delete</v-icon>
                                                    </v-btn>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </v-hover>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>

                    <!-- LOCATION COLUMN -->
                    <v-col align="center" cols="6">
                        <v-container style="height: 57vh; padding-top: 0 !important;">
                            <!-- DETAILS SUBTITLE -->
                            <p class="dialogSubtitle">
                                Selecciona la ubicación de tu consultorio en el mapa
                            </p>

                            <!-- LOCATION MARKER SET INSTRUCTIONS -->
                            <p v-if="roomAddress === ''" class="mt-n4 mb-0"
                               style="color: white; font-size: 0.7em;">
                                Haz click sobre el mapa para colocar un marcador.
                            </p>
                            <p v-if="roomAddress !== ''" class="mt-n4 mb-0"
                               style="color: white; font-size: 0.7em;">
                                Haz click sobre el marcador para borrarlo.
                            </p>

                            <!-- MAP -->
                            <v-container class="pa-0" style="height: 40vh; width: 100%;">
                                <gmaps-map :options="mapOptions" @click="addMarker">
                                    <!-- LOCATION MARKER IN MAP -->
                                    <gmaps-marker
                                            v-for="(marker, index) in roomMarker"
                                            :key="index"
                                            :options="marker"
                                            @click="removeMarker(index)"
                                    />
                                </gmaps-map>
                            </v-container>

                            <!-- ROOM FULL ADDRESS -->
                            <p
                                    v-if="roomAddress !== ''"
                                    class="mt-2"
                                    style="color: white; font-size: 0.7em; font-weight: 500;"
                            >
                                {{ this.roomAddress }}
                            </p>
                        </v-container>
                    </v-col>
                </v-row>

                <!-- SAVE DATA BUTTON -->
                <v-row align="center" class="mt-16" justify="center">
                    <button
                            class="kuida-md-btn coral-btn button-shadow mt-8"
                            type="button"
                            @click="saveRoomInfoRequest"
                    >
                        Guardar
                    </button>
                </v-row>

            </v-container>
        </v-card>

        <!-- SENT PROFILE TO REVIEW DIALOG -->
        <v-dialog
                v-model="invalidForm"
                max-width="500px"
                overlay-opacity="0.8"
                persistent
        >
            <v-card color="white" class="pa-16">
                <v-row align="center" justify="center">
                    <v-col align-self="center">
                        <h1 class="primary--text mt-n8">ERROR</h1>

                        <p style="text-align: center;">{{ dialogText }}</p>

                        <v-row align="center" justify="center" class="mt-8">
                            <button
                                    class="kuida-md-btn coral-btn"
                                    @click="invalidForm = false"
                            >Cerrar
                            </button>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>

    </v-dialog>
</template>

<script>
import firebase from "firebase";
import {gmapsMap, gmapsMarker} from "x5-gmaps";
import {checkDoctorProfileCompletionAfterUpdate} from "@/helpers/validate_completion_and_status";
import {useAppState} from "@/store/useAppState";

export default {
  name: "UpdateRoomInfo_Form",
  components: {
    gmapsMap,
    gmapsMarker,
  },
  data() {
    return {
      user: '',
      openRoomEditDialog: false,
      types: ['Casa', 'Clínica', 'Centro Médico', 'Hospital Público', 'Hospital Privado', 'Consultorio Privado'],
      mapOptions: {
        center: {lat: 23.6887771, lng: -105.3488927},
        backgroundColor: "#F96767",
        mapTypeControl: false,
        fullscreenControl: false,
        keyboardShortcuts: false,
        streetViewControl: false,
        zoom: 4,
      },
      roomMarker: [],
      roomAddress: '',
      images: [],
      uploadImages: [],
      roomName: '',
      roomType: '',
      roomPhone: '',
      roomImagesAsUrls: [],
      dialogText: "",
      invalidForm: false,
    }
  },
  beforeMount() {
    this.user = firebase.auth().currentUser.email;
  },
  methods: {
    async storeRoomInfoToFirestore(roomData) {
      return await firebase.firestore().collection('doctors').doc(this.user).collection('room').add(roomData);
    },

    async uploadImagesToStorage(storedRef) {
      let roomImagesAsUrls = [];

      for (let i = 0; i < this.uploadImages.length; i++) {
        let extension = this.uploadImages[i].name.split('.')[1];
        const metadata = {contentType: `image/${extension}`};
        const storageRef = firebase.storage().ref(`doctors/${this.user}/room/${this.uploadImages[i].name}`).put(this.uploadImages[i], metadata);

        storageRef.on(
          `state_changed`, snapshot => {
            /*this.uploadValue can help to check the progress, if you want to use it please declare it in data
            It does not do anything currently,but it is necessary since snapshot should not be empty. Do not erase snapshot nor this.uploadValue*/
            this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          error => {
            console.log(error)
          },
          () => {
            storageRef.snapshot.ref.getDownloadURL().then(async (url) => {
              // WE STORE THE URLS TO UPLOAD THEM IN FIREBASE
              roomImagesAsUrls.push(url);

              await storedRef.update({
                'img': firebase.firestore.FieldValue.arrayUnion.apply(null, roomImagesAsUrls)

              }).then(() => {
                console.log('UPLOADED IMAGES!')

              }).catch(err => {
                console.log('Error: ')
                this.error = err.message;

              })
            });
          }
        );
      }
    },

    async saveRoomInfoRequest() {
      let validForm = this.validateFormInput();

      if (validForm === true) {
        let geoPoint = new firebase.firestore.GeoPoint(this.roomMarker[0].position.lat, this.roomMarker[0].position.lng);

        let roomData = {
          location: geoPoint,
          address: this.roomAddress,
          roomName: this.roomName,
          type: this.roomType,
          phone: this.roomPhone,
          creationDate: new Date(),
        }

        let stored = await this.storeRoomInfoToFirestore(roomData);

        await this.uploadImagesToStorage(stored);

        this.roomMarker = this.roomImagesAsUrls = this.images = [];
        this.roomType = this.roomName = this.roomAddress = '';
        this.openRoomEditDialog = this.foundRoomAddress = false;

        // Always store new Room info to analyze if profile is complete
        useAppState().addNewRoomInfo(stored);

        // Validate if doctor can be sent to Review after updating resume
        await checkDoctorProfileCompletionAfterUpdate();

      } else {
        this.dialogText = "Debes llenar todos los campos, seleccionar la ubicación del consultorio en el mapa y cargar al menos una imágen.";
        this.invalidForm = true;
      }
    },

    getMarkerAddress(position) {
      const key = "AIzaSyB5SwThXcToGwslLnBKp1Q6pADDpvWUkss";
      let url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + position.lat + "," + position.lng + "&key=" + key;

      fetch(url).then((response) => response.json()).then(data => {
        this.roomAddress = data.results[0].formatted_address;
        this.foundRoomAddress = true;

      }).catch(e => {
        console.log("ERROR: " + e.toString());
        this.roomAddress = "Ha ocurrido un error al encontrar tu ubicación, intentalo de nuevo";
      });
    },

    onFilesSelectionRequest() {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, {once: true})
      this.$refs.uploader.click()
    },

    validationExpression() {
      return Boolean(this.roomMarker.length === 1 && this.roomAddress !== '' && this.roomName !== '' && this.roomType !== '' && this.roomPhone !== '' && this.images.length > 0);
    },

    validateFormInput() {
      let validationResult = false;

      if (this.validationExpression()) {
        validationResult = true;
      }

      return validationResult;
    },

    deleteImages(index) {
      this.images.splice(index, 1);
      // this.disableButton()
    },

    removeMarker(index) {
      this.roomMarker.splice(index, 1);
      this.roomAddress = '';
    },

    addMarker($event) {
      console.log($event.latLng);

      if (this.roomMarker.length < 1) {
        const position = $event.latLng.toJSON();
        this.roomMarker.push({position});

        this.getMarkerAddress(position);
      }
    },

    onFileChanged(e) {
      let desired = this.images.length + e.target.files.length;

      if (e.target.files.length <= 6 && desired <= 6) {
        for (let x = 0; x < e.target.files.length; x++) {
          this.selectedFile = e.target.files[x];

          //WE STORE SELECTED FILES IN ARRAY SO WE CAN USE THEM LATER ON THE UPLOAD
          this.uploadImages.push(this.selectedFile)
          //THIS READS THE IMAGE UPLOADED BY THE USER
          const fileReader = new FileReader()
          fileReader.addEventListener('load', () => {
            this.images.push(fileReader.result)
          })

          fileReader.readAsDataURL(this.selectedFile)
        }
      } else {
        alert("Selecciona máximo 6 imágenes de tu consultorio")
      }
    },
  }
}
</script>

<style scoped>
.dialogTitle {
    color: #FFF;
    font-size: 1.5em !important;
    font-weight: bold;
}

.dialogSubtitle {
    color: #FFF;
    font-size: 0.7em !important;
    font-weight: bold;
    text-align: center;
}

</style>