<template>
    <v-col align="center" cols="3">
        <v-row align="center" justify="center">
            <!-- CARD TITLE -->
            <h2 class="card-title">Formación Académica</h2>

            <!-- ADD FORMATION INFO -->
            <AddFormationInfo_Form></AddFormationInfo_Form>
        </v-row>

        <!-- CARD -->
        <v-sheet
            class="rounded-xl mt-3 pl-1 py-0"
            color="primary"
            dark
            elevation="3"
            height="52vh"
            style="overflow: auto;"
        >
            <v-row align="center" justify="center" class="noSpace">
                <v-col
                    v-if="formationData.length > 0"
                    align-self="start"
                    align="center"
                    cols="12"
                >
                    <DynamicScroller
                        :items="formationData"
                        :min-item-size="250"
                        class="scroller"
                        key-field="title"
                    >
                        <template v-slot="{ item, index, active }">
                            <DynamicScrollerItem
                                :active="active"
                                :data-index="index"
                                :item="item"
                                :size-dependencies="[ item.school ]"
                            >
                                <Formation_Card :formation-data="item"></Formation_Card>
                            </DynamicScrollerItem>
                        </template>
                    </DynamicScroller>
                </v-col>

                <v-row v-else align="center" class="noSpace" justify="center">
                    <v-col align-self="center" class="mx-8">
                        <p class="white--text" style="font-size: 0.8em; font-weight: bold; margin-top: 65%;">
                            Añade tu información académica
                        </p>
                    </v-col>
                </v-row>

            </v-row>
        </v-sheet>
    </v-col>
</template>

<script>
import firebase from "firebase";
import Formation_Card from "@/components/common_widgets/cards/Formation_Card";
import AddFormationInfo_Form from "@/components/profile/dialog_forms/AddFormationInfo_Form";

export default {
    name: "FormationInfo_Section",
    components: {
        Formation_Card,
        AddFormationInfo_Form,
    },
    data() {
        return {
            user: '',
            formationData: [],
        }
    },
    async mounted() {
        this.user = firebase.auth().currentUser.email;

        await this.getDoctorFormationDocuments();
    },
    methods: {
        async getDoctorFormationDocuments() {
            let doctorId = this.user;

            firebase.firestore().collection('doctors')
                .doc(doctorId).collection('education')
                .orderBy('graduationDate', 'desc')
                .onSnapshot(async (snapshot) => {
                this.createFormationList(snapshot.docs);
            });

        },

        createFormationList(formationDocs) {
            this.formationData = [];

            formationDocs.forEach((item) => {
                let data = item.data();

                let map = {
                    id: item.id,
                    title: data.title,
                    graduation: data.graduationDate,
                    speciality: data.speciality,
                    school: data.school,
                    professionalId: data.professionalId,
                };

                this.formationData.push(map);
            });
        },
    }
}
</script>

<style scoped>

</style>