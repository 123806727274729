<template>
    <v-card  class="rounded-xl my-5 pt-2 pb-2 pr-2 pl-6" elevation="4" color="accent">
        <v-container>
                    <!-- NOTE'S TITLE -->
                    <v-row justify="space-between">
                        <v-card-title v-text="this.noteData['title']" class="px-2 justify-center head"></v-card-title>

                        <v-btn fab class="delete my-2 mx-2" plain small @click="deleteNote()">
                            <v-icon color="white">mdi-close-thick</v-icon>
                        </v-btn>
                    </v-row>

                    <v-row justify="start">
                        <!-- DESCRIPTION -->
                        <v-card-text v-text="this.noteData['description']" class="description_text"></v-card-text>
                    </v-row>

                    <!-- IMAGES -->
                    <v-container fluid>
                        <v-row >
                            <v-col v-for="image in this.noteData['images']" :key="image" cols="3">
                                <v-img :src="image" :aspect-ratio="1" height="150"></v-img>
                            </v-col>
                        </v-row>
                    </v-container>

                    <!-- DATE -->
                    <v-row justify="end">
                        <v-card-text v-text="this.noteData['date']" class="pa-0 date_text"></v-card-text>
                    </v-row>
        </v-container>
    </v-card>

</template>

<script>
import firebase from "firebase";

export default {
    name: "NoteCard",
    props: {
        noteData: Object
    },
    methods: {
        async deleteNote(){
            await firebase.firestore().collection('notes').doc(this.noteData['noteId']).update({
                'display': false
            })
        }
    }
}
</script>

<style scoped>
.head{
    padding: 0.5rem;
    color: white;
    font-size: 1em;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}
.date_text{
    color: white !important;
    text-align: right;
    font-size: 1rem;
    font-weight: bold;
    padding: 0 10px 0 0!important;
    font-family: 'Montserrat', sans-serif;
}
.description_text{
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    color: white!important;
}
.title_text{
    font-size: 1.7rem;
    color: #F96767 !important;
    font-weight: bold;
}
.subtitle_text{
    color: #F96767 !important;
}
.delete{
    border-radius: 50%;
    color: #3ec6c6;
}

</style>