<template>
    <v-container class="appointments-container px-2 px-lg-10" fluid fill-height>
        <v-row>
            <!-- COMPLETED APPOINTMENTS -->
            <v-col cols="12" md="6" lg="3" xl="2" sm="6" class="order-lg-first">
                <div class="fill-height" style="box-shadow: none;">
                    <p
                        :style="{ color: pink }"
                        class="font-weight-bold text-center text-sm-h5"
                    >
                        CITAS CONCLUIDAS
                    </p>
                    <Appointments_Cards
                        :data="finished"
                        :icon="appointmentsCardsInfoConfig.finishedData.icons"
                        :title="appointmentsCardsInfoConfig.finishedData.titles"
                    />
                </div>
            </v-col>

            <!-- CURRENT YEAR APPOINTMENTS -->
            <v-col cols="12" lg="6" xl="8" sm="12" class="order-first">
                <div class="fill-height" style="box-shadow: none;">
                    <p
                        :style="{ color: aquaColor }"
                        class="font-weight-bold text-center text-sm-h5"
                    >
                        CITAS DEL AÑO EN CURSO
                    </p>
                    <ChartCurrentYearAppointments style="width: 90%; margin: 0 auto;"/>
                </div>
            </v-col>

            <!-- PENDING APPOINTMENTS -->
            <v-col cols="12" md="6" lg="3" xl="2" sm="6">
                <div class="fill-height" style="box-shadow: none;">
                    <p
                        :style="{ color: pink }"
                        class="font-weight-bold text-center text-sm-h5"
                    >
                        CITAS PENDIENTES
                    </p>
                    <Appointments_Cards
                        :color="aquaColor"
                        :data="pending"
                        :icon="appointmentsCardsInfoConfig.pendingData.icons"
                        :title="appointmentsCardsInfoConfig.pendingData.titles"
                    />
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ChartCurrentYearAppointments from "@/components/appointments/charts/ChartCurrentYearAppointments";
import Appointments_Cards from "@/components/common_widgets/cards/Appointments_Cards";

import firebase from "firebase";
import {cloud_functions_request} from "@/helpers/cloud_functions_request";

export default {
    components: {
    ChartCurrentYearAppointments,
    Appointments_Cards,
},
    data() {
        return {
            aquaColor: "#5ac6c8",
            pink: "#f96767",
            finished: {},
            pending: {},

            appointmentsCardsInfoConfig: {
                finishedData: {
                    titles: ["Total historico de citas", "Citas este año", "Citas este mes", "Citas esta semana", "Citas hoy"],
                    icons: ["history", "calendar_month", "today", "date_range", "today"]
                },
                pendingData: {
                    titles: ["Total citas pendientes", "Pendientes este año", "Pendientes este mes", "Pendientes esta semana", "Pendientes hoy",],
                    icons: ["pending_actions", "pending_actions", "pending", "date_range", "today",],
                },
            },
        };
    },
    async created() {
        try {
            const doctorId = firebase.auth().currentUser.email;
            let response = await cloud_functions_request('appointmentsInfo', { doctorId });

            this.finished = response.data.finished || {};
            this.pending = response.data.pending || {};
        } catch (error) {
            console.error("Error fetching appointment data: ", error);
        }
    },
}
</script>

<style scope>
.appointments-container {
    margin-top: auto;
    padding-top: 100px;
    height: 100vh;
    width: 100vw;
    background-color: white;
    overflow-y: auto;
    padding: 100px;
}

p {
    font-family: 'Montserrat', sans-serif;
}
</style>

