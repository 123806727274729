<!--suppress JSVoidFunctionReturnValueUsed -->
<template>
    <v-container class="accent mt-n6">
        <v-toolbar color="accent" flat dense>
            <v-row align="center" justify="center">
                <!-- START APPOINTMENT REQUEST BUTTON -->
                <button
                    class="kuida-md-btn coral-btn button-shadow"
                    v-show="beginAppointment"
                    @click="startAppointmentDialogTriggerFunction"
                >
                    Comenzar próxima cita
                </button>

                <!-- FINISH APPOINTMENT REQUEST BUTTON -->
                <button
                    class="kuida-md-btn whitecoral-btn button-shadow"
                    v-show="!beginAppointment"
                    @click="overlay =! overlay;"
                >
                    Terminar cita
                </button>
            </v-row>
        </v-toolbar>

        <v-overlay :value="overlay" z-index="1">
            <!-- START APPOINTMENT DIALOG -->
            <v-card
                v-show="beginAppointment"
                elevation="2"
                rounded="xl"
                color="accent"
                width="750"
                class="pb-6 pt-10"
            >
                <!-- DIALOG HEADER -->
                <v-row align="center" justify="space-around" class="mb-6">
                    <!-- CLOSE DIALOG ICON BUTTON HELPER (DO NOT DELETE) -->
                    <v-btn color="accent"  class="mr-12" top fab x-small plain></v-btn>

                    <!-- DIALOG TITLE -->
                    <h1 style="font-size: 2em;">{{ startAppointmentDialogTitle }}</h1>

                    <!-- CLOSE DIALOG ICON BUTTON HELPER -->
                    <v-btn color="white" class="ml-12" top fab x-small @click="overlay=!overlay;">
                        <v-icon color="accent" medium>mdi-close-thick</v-icon>
                    </v-btn>
                </v-row>

                <!-- DIALOG BODY -->
                <v-col align="center">
                    <h2 class="px-8">{{ startAppointmentDialogBoldText }}</h2>
                    <p class="mt-4 px-8 mb-4">{{ startAppointmentDialogText }}</p>
                </v-col>

                <!-- DIALOG CTA (Displayed only when there is an available appointment to start) -->
                <v-row v-if="availableAppointment === true" align="center" justify="center">
                    <button
                        class="kuida-md-btn coral-btn button-shadow mt-4 mb-4"
                        v-show="beginAppointment"
                        @click="startAppointmentRequest"
                    >
                        Comenzar próxima cita
                    </button>
                </v-row>
            </v-card>

            <!-- FINISH APPOINTMENT DIALOG -->
            <v-card
                v-show="!beginAppointment"
                elevation="2"
                rounded="xl"
                color="accent"
                height="260"
                width="600"
                class="pb-6 pt-10"
            >
                <!-- DIALOG HEADER -->
                <v-row align="center" justify="space-around" class="mb-6">
                    <!-- CLOSE DIALOG ICON BUTTON HELPER (DO NOT DELETE) -->
                    <v-btn color="accent"  class="mr-12" top fab x-small plain></v-btn>

                    <!-- DIALOG TITLE -->
                    <h2>CITA EN CURSO</h2>

                    <!-- CLOSE DIALOG ICON BUTTON HELPER -->
                    <v-btn color="white" class="ml-12" top fab x-small @click="overlay=!overlay;">
                        <v-icon color="accent" medium>mdi-close-thick</v-icon>
                    </v-btn>
                </v-row>

                <!-- DIALOG BODY -->
                <v-card-text align="center">
                    <div style="height: 40px;"></div>
                    <v-row justify="center"><h3>¿Estás seguro de que quieres finalizar la cita en curso?</h3></v-row>
                    <div style="height: 50px;"></div>
                </v-card-text>

                <!-- DIALOG CTA -->
                <v-row align="center" justify="space-around" class="mr-16 ml-16">
                    <button
                        class="kuida-sm-btn whitecoral-btn button-shadow"
                        @click="overlay=false;"
                    >
                        Cancelar
                    </button>

                    <button
                        class="kuida-sm-btn coral-btn button-shadow"
                        @click="finishAppointment(); $emit('changeAppointment');"
                    >
                        Terminar
                    </button>
                </v-row>

            </v-card>
        </v-overlay>

        <!-- WAITING FOR APPOINTMENT ACTIONS FROM USER LOADING DIALOG -->
        <v-overlay :value="loadingOverlay" z-index="1">
            <v-dialog v-model="loadingDialog" hide-overlay persistent width="450">
                <v-card color="primary" dark>
                    <v-card-text>
                        <v-row align="center" justify="center" class="px-8 pt-12">
                            <h3>Espera a que el/la paciente comience la cita. Esta acción debe llevarse a cabo desde la pestaña de 'citas' en su aplicación para pacientes de Kuida.</h3>

                            <v-progress-linear class="mb-1 mt-5" color="white" indeterminate></v-progress-linear>
                        </v-row>

                        <v-row align="center" justify="center" class="pt-8 pb-6">
                            <button
                                class="kuida-md-btn whitecoral-btn button-shadow"
                                @click="changeAppointmentWaitingStatus(false)"
                            >
                                Cancelar
                            </button>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-overlay>
    </v-container>
</template>

<script>
import firebase from "firebase";

export default {
    name: "CurrentAppointment_Component.vue",
    props: {
        appointmentsListProp: Array,
    },
    data: () => ({
        overlay: false,
        loadingOverlay: false,
        beginAppointment: true,
        professionalId: '',
        currentAppointment: '',
        appointmentsList: [],
        availableAppointment: false,
        startAppointmentDialogTitle: '',
        startAppointmentDialogBoldText: '',
        startAppointmentDialogText: '',
        closestAppointment: null,
        onService: false,
        onServiceWait: false,
        loadingDialog: false,
    }),
    watch:{
        appointmentsListProp(val) {
            this.appointmentsListProp = val;
            this.appointmentsList = this.appointmentsListProp;
            this.lookForClosestAppointmentAvailable();
        },

        onServiceWait(value) {
            this.onServiceWait = value;
            this.displayLoadingDialog(value);
        },

        onService(value) {
            this.onService = value;
            this.displayFinishAppointmentTools(value);
        },
    },
    async mounted() {
        await  this.getOnServiceWaitStatus();
    },
    methods:{
        // Update appointment and doctor status to "Waiting" while patient confirms transaction
        async changeAppointmentWaitingStatus(startOrCancel) {
            let patientId = this.closestAppointment.customerId;
            let doctorId = firebase.auth().currentUser.email;
            let onServiceWaitStatus = null;

            onServiceWaitStatus = startOrCancel === true;

            await firebase.firestore().collection('doctors').doc(doctorId).update({
                'onServiceWait': onServiceWaitStatus,
            });

            await firebase.firestore().collection('users').doc(patientId).update({
                'onAppointmentConfirm': onServiceWaitStatus,
            });
        },

        // Check if appointment is scheduled between tolerance time behind and ahead
        checkIfClosestAppointmentCanBeStarted(appointment) {
            let currentDate = new Date(); // Current date
            let nowMinus20 = new Date();
            let nowPlus20 = new Date();

            nowMinus20.setMinutes(currentDate.getMinutes() - 20); // Current date + 20 minutes
            nowPlus20.setMinutes(currentDate.getMinutes() + 20); // Current date - 20 minutes

            // Check if appointment is scheduled to happen on last 20 minutes
            let appointmentCloserThan20MinutesBehind = nowMinus20 <= appointment.hour;
            // Check if appointment is scheduled to happen on upcoming 20 minutes
            let appointmentCloserThan20MinutesAhead = appointment.hour <= nowPlus20;

            // If appointment is not scheduled to happen on last 20 minutes from now, delete this appointment from upcoming appointments list
            if(appointmentCloserThan20MinutesBehind === false) {
                let index = this.appointmentsList.indexOf(appointment);
                if(index > -1) {
                    this.appointmentsList.splice(index, 1);
                }
            }

            // Return if appointment is scheduled between tolerance time behind and ahead
            return appointmentCloserThan20MinutesAhead === true && appointmentCloserThan20MinutesBehind === true;
        },

        // Enable finish appointment tools in case appointment was started successfully
        displayFinishAppointmentTools(onService) {
            this.beginAppointment = onService !== true;
        },

        startAppointmentDialogTriggerFunction() {
            this.lookForClosestAppointmentAvailable();
            this.overlay = !this.overlay;
            this.$emit('startAppointment');
        },

        // Look for closest appointment available and display correct data based on search result
        lookForClosestAppointmentAvailable() {
            // Original upcoming appointments list
            let unsortedList = this.appointmentsList;

            // Do this whenever there are upcoming appointments
            if(unsortedList.length >= 1) {
                // Reset the closest appointment stored to re-evaluate every time the method is triggered
                this.closestAppointment = null;
                let closestAppointmentDate = new Date();
                closestAppointmentDate.setMinutes(closestAppointmentDate.getMinutes() + 360);

                // Store closest appointment found
                unsortedList.forEach((appointment) => {
                    console.log(appointment.hour)
                    if(closestAppointmentDate > appointment.hour) {
                        this.closestAppointment = appointment;
                        closestAppointmentDate = appointment.hour;
                    }
                });

                // Check if closest appointment found is between tolerance time behind and ahead now
                let canStartAppointment = this.checkIfClosestAppointmentCanBeStarted(this.closestAppointment);

                if(canStartAppointment === true) {
                    // Type 0 --> You can start your closest appointment
                    this.availableAppointment = true;
                    this.setStartAppointmentDialogTexts(0);

                } else {
                    // Type 1 --> Your closest appointment is further than 20 minutes from now
                    this.availableAppointment = false;
                    this.setStartAppointmentDialogTexts(1);
                }

            } else {
                // DO this when there AREN'T upcoming appointments
                // Type 2 --> No available appointment
                this.availableAppointment = false;
                this.setStartAppointmentDialogTexts(2);
            }
        },

        setStartAppointmentDialogTexts(type) {
            // DIALOG TYPES
            // 0 --> You can start your closest appointment
            // 1 --> Your closest appointment is further than 20 minutes from now
            // 2 --> No available appointment
            if(type === 0) {
                this.startAppointmentDialogTitle = 'Atención';
                this.startAppointmentDialogBoldText = 'Haz click en el botón "Comenzar Cita" para comenzar tu cita de ' + this.closestAppointment.service + ' a las ' + this.closestAppointment.formattedHour + 'hrs con ' + this.closestAppointment.customerName;
                this.startAppointmentDialogText = 'Tu paciente tiene que confirmar el inicio de la consulta desde su aplicación y en ese momento se intentará realizar el cargo. NO debes comenzar el servicio hasta que se realice exitosamente el pago. Este proceso lo pueden intentar varias veces dentro del tiempo de tolerancia de 20 minutos.';

            } else if(type === 1) {
                this.startAppointmentDialogTitle = 'Espera un poco';
                this.startAppointmentDialogBoldText = 'Aún no tienes ninguna consulta cercana por comenzar.';
                this.startAppointmentDialogText = 'Recuerda que las consultas agendadas solo pueden comenzarse entre 20 minutos antes y 20 minutos después de la hora a la que estén agendadas.';

            } else if(type === 2) {
                this.startAppointmentDialogTitle = 'Lo sentimos';
                this.startAppointmentDialogBoldText = 'No hay ninguna cita que puedas comenzar en este momento.';
                this.startAppointmentDialogText = 'Si estás intentando comenzar una consulta que estaba agendada con un paciente, recuerda que este/esta paciente solo tiene 20 minutos de tolerancia. Si este tiempo ya pasó, la reservación se perdió.';

            }
        },

        // Display this dialog based on "onServiceWait" status from doctor remote data to wait to patient to pay the appointment
        displayLoadingDialog(onServiceWait) {
            this.loadingDialog = onServiceWait;
            this.loadingOverlay = onServiceWait;
        },

        async startAppointmentRequest() {
            // Need to evaluate the closest appointment availability every time doctor wishes to start an appointment.
            let canStartAppointment = this.checkIfClosestAppointmentCanBeStarted(this.closestAppointment);

            // Do this in case doctor has an available appointment between tolerance time ahead and behind
            if(canStartAppointment === true) {
                this.availableAppointment = true;
                this.setStartAppointmentDialogTexts(0);

                this.currentAppointment = this.closestAppointment;
                this.overlay = false;
                // Update appointment and doctor status to "Waiting" while patient confirms transaction
                await this.changeAppointmentWaitingStatus(true);

            } else {
                // Do this in case doctor DOES NOT HAVE an available appointment between tolerance time ahead and behind
                this.availableAppointment = false;

                if(this.appointmentsList.length <= 1) {
                    // Display when you have no appointment closer than 20 minutes from now
                    this.setStartAppointmentDialogTexts(1);
                } else {
                    // Display when you have no appointment available to start (Patient didn't arrive between tolerance time)
                    this.setStartAppointmentDialogTexts(2);
                }

            }
        },

        // Listen to doctor onWaiting and onService status
        async getOnServiceWaitStatus() {
            let doctorId = firebase.auth().currentUser.email;
            await firebase.firestore().collection('doctors').doc(doctorId).onSnapshot((snapshot) => {
                let doctorData = snapshot.data();
                this.onServiceWait = doctorData['onServiceWait'];
                this.onService = doctorData['onService'];
            });
        },

        async finishAppointment() {
            let appointmentDoc = await firebase.firestore().collection('appointments').doc(this.closestAppointment.appointmentId).get();
            let patientId = appointmentDoc.data()['patient'];
            let doctorId = firebase.auth().currentUser.email;

            await firebase.firestore().collection('users').doc(patientId).update({
                'onService': false,
            });

            await firebase.firestore().collection('appointments').doc(this.currentAppointment.appointmentId).update({
                status: 'Finished',
                finishDate: firebase.firestore.Timestamp.now()
            });

            await firebase.firestore().collection('doctors').doc(doctorId).update({
                'onService': false,
            })


            // Create notification for user
            // let body = {appointmentId: this.currentAppointment}
            // await cloud_functions_request('createAppointmentFinishedNotification', body);


            // Add appointment price to balance
            // await cloud_functions_request('addAppointmentToDoctorBalance', body);


            // await firebase.firestore().collection('doctors').doc(this.email).update({
            //     totalAppointments: firebase.firestore.FieldValue.increment(1)
            // });

            this.overlay = false;
            this.beginAppointment = true;
        },
    }
}
</script>