<template>
    <v-container>
        <!--FEED-->
        <MainFeed></MainFeed>

        <!--FLOATING BUTTON-->
        <AddFloatingButton @childToParent="createPostRequest" @modal="createPostRequest"></AddFloatingButton>

        <!-- CREATE NEW POST DIALOG -->
        <CreatePostDialog :dialog="createPostDialogTrigger" @childToParent="createPostRequest" @modal="createPostRequest"></CreatePostDialog>

        <!-- SIMPLE MESSAGE BOTTOM SHEET -->
        <SimpleMessage_BottomSheet
            :bottom-sheet-message="bottomSheetMessage"
            :bottom-sheet-trigger="bottomSheetTrigger"
            @close-bottom-sheet="bottomSheetTrigger = false"
        ></SimpleMessage_BottomSheet>

    </v-container>
</template>

<script>
import SimpleMessage_BottomSheet from "@/components/common_widgets/bottom_sheets/SimpleMessage_BottomSheet";
import AddFloatingButton from "@/components/common_widgets/floatingButton/AddFloatingButton";
import CreatePostDialog from "@/components/common_widgets/dialogs/CreatePost_Dialog";
import MainFeed from "@/components/feed/MainFeed_Section";
import firebase from "firebase";
import {cloud_functions_request} from "@/helpers/cloud_functions_request";

export default {
    name: 'DoctorsFeed',
    components: {
        SimpleMessage_BottomSheet,
        AddFloatingButton,
        CreatePostDialog,
        MainFeed,
    },
    props: {
        doctorStatus: {
            type: Boolean,
            default: false,
        },
        subscriptionStatus: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        email: '',
        customerId: null,
        cantPostAlert: false,
        subscriptionsList: [],
        bottomSheetMessage: '',
        bottomSheetTrigger: false,
        createPostDialogTrigger: false,
        doctorStatus: false,
        subscriptionStatus: false,
    }),
    async mounted() {
        this.email = firebase.auth().currentUser.email;

        await this.getDoctorAcceptanceStatus();

        await this.getSubscriptionStatusFromStripe();

    },
    methods: {
        async createPostRequest() {
            if (this.doctorStatus === 'Approved') {

                if(this.subscriptionStatus === true) {
                    this.createPostDialogTrigger = !this.createPostDialogTrigger

                } else {
                    this.bottomSheetMessage = "Al parecer no estás suscrito a Kuida aún o tu suscripción ha vencido. Te invitamos a revisar el estado de tu suscripción en tu perfil."
                    this.bottomSheetTrigger = true;

                }

            } else {
                this.bottomSheetMessage = 'No puedes generar contenido dentro de Kuida hasta que hayamos verificado tu identidad y hayas sido aceptado por nuestro equipo. Espera tu confirmación por currentUser.'
                this.bottomSheetTrigger = true;
            }

        },

        async getDoctorAcceptanceStatus() {
            // Get current user account status
            const doctorData = await firebase.firestore().collection('doctors').doc(this.email).get().then((snap) => {
                return snap.data();
            });

            this.customerId = doctorData['stripeCustomerId'];
            this.doctorStatus = doctorData['status'];

            console.log("Acceptance Status: " + this.doctorStatus);
        },

        async getSubscriptionStatusFromStripe() {
            let requestObject = {
                'customerId': this.customerId,
            }

            let response = await cloud_functions_request('getDoctorsSubscriptions', requestObject);
            this.subscriptionsList = response.data;

            if (this.subscriptionsList.length > 0) {
                this.subscriptionsList.forEach((subscription) => {
                    this.subscriptionStatus = subscription['status'] === 'active' || subscription['status'] === 'trialing';
                });

            } else {
                this.subscriptionStatus = false;
            }
        },
    }
}
;
</script>
