<template>
    <v-container class="grey-rounded-card">
        <v-row align="center" justify="center">
            <v-col align="start" class="ml-2">
                <v-row align="start" class="pa-0 ma-0 mt-5" justify="start">
                    <!-- TITLE INFO -->
                    <v-col align="start" class="pa-0 ma-0">
                        <p class="grey-card-info-title">Título</p>
                        <p class="grey-card-info-content">{{ formationData.title }}</p>
                    </v-col>

                    <!-- DELETE BUTTON -->
                    <v-btn
                        @click="deletionConfirm = true;"
                        class="mt-n5 mr-n2"
                        color="primary"
                        icon
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </v-row>

                <!-- PROFESSIONAL ID FOR TITLE -->
                <p class="grey-card-info-title">Cédula Profesional</p>
                <p class="grey-card-info-content">{{ formationData.professionalId }}</p>

                <!-- GRADUATION DATE INFO -->
                <p class="grey-card-info-title">Fecha de graduación</p>
                <p class="grey-card-info-content">{{ this.formattedGraduationDate }}</p>

                <!-- SPECIALITY INFO -->
                <p class="grey-card-info-title">Especialidad</p>
                <p class="grey-card-info-content">{{ formationData.speciality }}</p>

                <!-- SCHOOL INFO -->
                <p class="grey-card-info-title">Escuela</p>
                <p class="grey-card-info-content">{{ formationData.school }}</p>
            </v-col>
        </v-row>

        <!-- DELETION CONFIRM DIALOG -->
        <v-dialog
            v-model="deletionConfirm"
            max-width="750px"
            overlay-opacity="0.8"
            persistent
        >
            <v-card class="pa-16" color="white">
                <v-row align="center" justify="center">
                    <v-col align-self="center">
                        <h1 class="primary--text mt-n8">ATENCIÓN</h1>

                        <p style="text-align: center;">¿Estás seguro que quieres borrar esta información académica?</p>

                        <v-row align="center" class="mt-8" justify="space-around">
                            <button
                                class="kuida-md-btn aqua-btn"
                                @click="deleteFormationInfoRequest"
                            >Eliminar
                            </button>

                            <button
                                class="kuida-md-btn coral-btn"
                                @click="deletionConfirm = false"
                            >Cancelar
                            </button>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import * as dateHelper from "@/helpers/date_formatting"
import firebase from "firebase";

export default {
    name: "Formation_Card",
    props: [
        'formationData',
    ],
    data() {
        return {
            formattedGraduationDate: null,
            deletionConfirm: false,
        }
    },
    mounted() {
        this.formattedGraduationDate = dateHelper.formatDate(this.formationData.graduation);
    },
    methods: {
        async deleteFormationInfoRequest() {
            let user = firebase.auth().currentUser.email;

            await firebase.firestore().collection('doctors').doc(user).collection('education').doc(this.formationData.id).delete();

            this.deletionConfirm = false
        },
    }
}
</script>

<style scoped>
.grey-card-info-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #F96767;
    margin-top: -8px;
}

.grey-card-info-content {
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 500 !important;
    margin-top: -16px;
    color: #F96767;
}

.grey-rounded-card {
    background-color: #E2E2E2;
    border-radius: 20px;
    elevation: higher;
    margin: 10px 0;
}

.v-list-item {
    padding: 0 10px 0 10px;
}
</style>