<template>
  <!-- ACTION CONFIRM DIALOG -->
  <v-dialog
    v-model="dialogTrigger"
    max-width="750px"
    overlay-opacity="0.8"
    persistent
  >
    <v-card class="pa-16" color="white">
      <v-row align="center" justify="center">
        <v-col align-self="center">
          <h1 class="primary--text mt-n8">{{ dialogTitle.toUpperCase() }}</h1>
          <p>
            Estas seguro en dar de alta este nuevo servicio, este es el resumen:
          </p>

          <div>
            <p><strong>Título: </strong> {{ dialogMessage.title }}</p>
            <p><strong>Precio: </strong> ${{ parseFloat(dialogMessage.price).toFixed(0) }} MXN</p>
            <p>
              <strong>Duración: </strong> {{ dialogMessage.duration }} minutos
            </p>
            <p>
              <strong>Descripción: </strong> {{ dialogMessage.description }}
            </p>
          </div>

          <v-row align="center" class="mt-8" justify="space-around">
            <button
              class="kuida-md-btn aqua-btn"
              @click="$emit('action-confirm')"
            >
              {{ acceptLabel }}
            </button>

            <button
              class="kuida-md-btn coral-btn"
              @click="$emit('action-cancel')"
            >
              {{ cancelLabel }}
            </button>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ActionConfirm_Dialog",
  props: {
    dialogTrigger: Boolean,
    dialogTitle: String,
    dialogMessage: String,
    cancelLabel: {
      type: String,
      default: "Cancelar",
    },
    acceptLabel: {
      type: String,
      default: "Aceptar",
    },
  },
};
</script>

<style scoped></style>
