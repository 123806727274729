<template>
    <!-- add note card -->
    <v-dialog v-model="dialog" height="800" max-width="600">
        <v-flex>
            <v-card class="mx-auto rounded-xl" color="#5AC6C8" flat max-height="100%" max-width="100%"
                    style="padding: 20px 20px 40px 20px;">
                <v-row justify="end">
                    <v-icon color="white" style="margin-right: 10px; margin-top: 10px;" @click="closeDialog">
                        mdi-close-thick
                    </v-icon>

                </v-row>

                <v-row justify="center">
                    <!-- TITLE -->
                    <v-col cols="12">
                        <h1 style="font-size: 30px">CREAR NUEVA NOTA</h1>
                    </v-col>

                    <!-- CURRENT PATIENT HEADER -->
                    <v-col cols="12">
                        <h2>Paciente: {{ patientName }}</h2>
                    </v-col>

                    <v-col cols="12">
                        <v-form v-model="isValid">
                            <!-- NOTE TITLE TEXT FIELD -->
                            <v-row>
                                <v-text-field
                                    v-model="title"
                                    :rules="titleRules"
                                    class="mx-10 note"
                                    color="white"
                                    dense
                                    label="Título de la nota"
                                    outlined
                                ></v-text-field>
                            </v-row>

                            <!-- NOTE TEXT AREA -->
                            <v-row>
                                <v-textarea
                                    v-model="note"
                                    :rules="contentRules"
                                    class="mx-10 note"
                                    color="white"
                                    dense
                                    full-width
                                    height="200"
                                    label="Escribe el contenido de tu nota aquí..."
                                    outlined
                                ></v-textarea>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-row>

                <!-- IMAGES -->
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-row align="center" class="mt-1" justify="center">
                                <!-- IMG SELECTION BUTTON -->
                                <button
                                    :disabled="disabledImageButton"
                                    class="button-shadow kuida-md-btn whiteaqua-btn"
                                    @click="onButtonClick"
                                >Añadir imágenes
                                </button>

                                <!-- IMG SELECTOR -->
                                <input
                                    ref="uploader"
                                    accept="image/*"
                                    class="d-none"
                                    type="file"
                                    multiple
                                    @change="onFileChanged"
                                >
                            </v-row>

                            <v-row justify="center">
                                <p style="color:white; margin-top: 5px; font-size: 15px;">Máximo 8 imágenes
                                    (opcional)</p>
                            </v-row>

                            <!-- IMAGES PREVIEW -->
                            <v-container style="min-height: 150px;">
                                <v-row>
                                    <v-col v-for="(image, index) in images" :key="index" class="d-flex child-flex"
                                           cols="4">
                                        <v-card align="center">
                                            <v-img :src="image" align="center" contain height="120">
                                                <v-btn icon style="float: right" x-large @click="deleteImages(index)">
                                                    <v-icon color="#F96767">mdi-close</v-icon>
                                                </v-btn>
                                            </v-img>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>

                        </v-col>
                    </v-row>
                </v-container>

                <!-- PUBLISH BUTTON -->
                <v-row align="center" justify="center">
                    <button
                        class="kuida-md-btn coral-btn button-shadow"
                        @click="registrationHandler"
                    >Guardar Nota
                    </button>
                </v-row>
            </v-card>
        </v-flex>

        <!-- EMPTY TITLE BOTTOM SHEET -->
        <v-bottom-sheet v-model="emptyField">
            <v-sheet class="text-center" height="200px">
                <v-btn class="mt-6" color="primary" text @click="emptyField = false">
                    CERRAR
                </v-btn>
                <div class="py-3">
                    Favor de introducir título.
                </div>
            </v-sheet>
        </v-bottom-sheet>

        <!-- EMPTY CONTENT BOTTOM SHEET -->
        <v-bottom-sheet v-model="emptyContent">
            <v-sheet class="text-center" height="200px">
                <v-btn class="mt-6" color="primary" text @click="emptyContent = false">
                    CERRAR
                </v-btn>
                <div class="py-3">
                    Favor de introducir contenido a la nota.
                </div>
            </v-sheet>
        </v-bottom-sheet>

        <!-- FILES LOADING INDICATOR -->
        <v-dialog v-model="loadingDialog" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text style="padding-top: 10px;">
                    Espera un momento
                    <v-progress-linear class="mb-0" color="white" indeterminate>
                    </v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-dialog>
</template>

<script>
import firebase from "firebase";

export default {
    name: "CreateNote_Dialog",
    props: [
        'dialog',
        'patientId',
        'patientName'
    ],
    data() {
        return {
            title: '',
            note: '',
            images: [],
            disabledImageButton: false,
            uploadImages: [],
            notesImages: [],
            isValid: true,
            titleRules: [
                value => !!value || 'Se necesita un título'
            ],
            contentRules: [
                value => !!value || 'Necesitas agregar contenido'
            ],
            emptyField: false,
            emptyContent: false,
            loadingDialog: false,
            email: '',
            patient: '',
        }
    },
    mounted() {
        this.email = firebase.auth().currentUser.email;
    },
    methods: {
        closeDialog() {
            this.dialog = !this.dialog;
            this.$emit('modal')
        },

        disableButton() {
            //WHEN 8 IMAGES ARE UPLOADED THE BUTTON WILL BE DISABLED
            this.disabledImageButton = this.images.length === 8;
        },

        deleteImages(index) {
            this.images.splice(index, 1);
            this.disableButton()
        },

        onButtonClick() {
            window.addEventListener(
                'focus',
                () => {
                },
                {once: true},
            );

            this.$refs.uploader.click()
        },

        onFileChanged(e) {
            for(let x = 0; x < e.target.files.length; x++) {
                this.selectedFile = e.target.files[x];
                //WE STORE SELECTED FILES IN ARRAY SO WE CAN USE THEM LATER ON THE UPLOAD
                this.uploadImages.push(this.selectedFile)

                //THIS READS THE IMAGE UPLOADED BY THE USER
                const fileReader = new FileReader()
                fileReader.addEventListener('load', () => {
                        this.images.push(fileReader.result)
                        this.disableButton()
                    }
                )
                fileReader.readAsDataURL(this.selectedFile)
            }
        },

        async registrationHandler() {
            if (this.title !== '') {
                if (this.note !== '') {
                    this.loadingDialog = true;

                    await this.storeNoteData();

                    //Reset the values so you can add new notes
                    this.notesImages = [];
                    this.images = [];
                    this.uploadImages = [];
                    this.title = '';
                    this.note = '';

                    this.loadingDialog = false;

                } else {
                    this.emptyContent = true;
                }

            } else {
                this.emptyField = true;
            }


        },

        // Uploads note fields except for images
        async storeNoteData() {

            if(this.uploadImages.length > 0) {
                let noteImages = [];
                console.log("NOTE WITH IMAGES");

                let noteId = await firebase.firestore().collection('notes').add({
                    'date': new Date(),
                    'display': true,
                    'doctor': this.email,
                    'patient': this.$props.patientId,
                    'title': this.title,
                    'note': this.note,
                    'img': noteImages
                }).then((value) => {
                    return value.id;
                });

                //Updates the newly created note to upload images
                for (let i = 0; i < this.uploadImages.length; i++) {
                    let extension = this.uploadImages[i].name.split('.')[1];
                    const metadata = {contentType: `image/${extension}`,};
                    const storageRef = firebase.storage().ref(`doctors/${this.email}/notes/${this.patientName}/${this.uploadImages[i].name}`).put(this.uploadImages[i], metadata);

                    await storageRef.on(
                        'state_changed',
                        snapshot => {
                            this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        },
                        error => {
                            console.log(error)
                        },
                        async () => {
                            await storageRef.snapshot.ref.getDownloadURL().then(async (url) => {
                                noteImages.push(url);
                            });

                            await firebase.firestore().collection('notes').doc(noteId).update({
                                img: noteImages,
                            });
                        }
                    );

                }
            } else {
                await firebase.firestore().collection('notes').add({
                    'date': new Date(),
                    'display': true,
                    'doctor': this.email,
                    'patient': this.$props.patientId,
                    'title': this.title,
                    'note': this.note,
                    'img': [],
                });
            }

            this.closeDialog();

            // window.location.reload();
        }
    }
}
</script>

<style scoped>

</style>