<template>
    <!-- NEW APPOINTMENT DIALOG -->
    <v-dialog
        v-model="dialogTrigger"
        overlay-opacity="0.8"
        persistent
    >
        <!-- DIALOG BODY -->
        <v-card class="rounded-xl elevation-0" color="accent">
            <v-container class="px-10 py-8" fluid>
                <!-- HEADER -->
                <v-row align="center" justify="space-between">
                    <!-- TITLE -->
                    <p class="dialogTitle text-uppercase">AÑADIR NUEVO MÉTODO DE PAGO</p>

                    <!-- CLOSE DIALOG BUTTON -->
                    <v-btn
                        class="mt-n4 mr-n2"
                        color="white"
                        fab
                        plain
                        small
                        @click="closeDialog"
                    >
                        <v-icon large>mdi-close</v-icon>
                    </v-btn>
                </v-row>

                <!-- DIALOG BODY -->
                <v-row align="center" class="px-8" justify="center">
                    <v-col cols="3"></v-col>

                    <v-col align-self="center" cols="6">
                        <!-- CARD OWNER NAME -->
                        <label class="card-details-label">Nombre completo de cuenta habiente</label>
                        <v-text-field
                            v-model="cardName"
                            :rules="nameRules"
                            color="white"
                            outlined
                            dense
                            dark
                        ></v-text-field>

                        <!-- CARD TEXT FIELD -->
                        <label class="card-details-label" for="card-name">Datos de la tarjeta</label>
                        <div class="card-details-text-field" id="card-name"></div>

                        <!-- STRIPE ERRORS DISPLAY -->
                        <div class="card-details-label" id="card-error"></div>

                    </v-col>

                    <v-col cols="3"></v-col>
                </v-row>

                <!-- SAVE DATA BUTTON -->
                <v-row align="center" class="mt-8 mb-2" justify="center">
                    <button
                        class="kuida-md-btn coral-btn button-shadow mt-2"
                        @click="savePaymentMethodRequest"
                    >
                        Añadir Método de Pago
                    </button>
                </v-row>

            </v-container>
        </v-card>

        <!-- ALERT MESSAGES DIALOG -->
        <Alert_Dialog
            :dialog-message="alertDialogMessage"
            :dialog-title="alertDialogTitle"
            :dialog-trigger="alertDialogTrigger"
            @dialog-close="alertDialogTrigger = false;"
        ></Alert_Dialog>

        <!-- FILES LOADING INDICATOR -->
        <Loading_Dialog
            :loading-dialog-trigger="loadingDialog"
            dialog-message="Espera un momento"
        ></Loading_Dialog>

    </v-dialog>
</template>

<script>
import Loading_Dialog from "@/components/common_widgets/dialogs/Loading_Dialog";
import Alert_Dialog from "@/components/common_widgets/dialogs/Alert_Dialog";
import firebase from "firebase";
import {cloud_functions_request} from "@/helpers/cloud_functions_request";

export default {
    name: "AddPaymentMethod_Form",
    props: {
        dialogTrigger: Boolean,
    },
    components: {
        Loading_Dialog,
        Alert_Dialog,
    },
    data() {
        return {
            // Alerts Variables
            alertDialogTrigger: false,
            alertDialogMessage: '',
            alertDialogTitle: '',
            loadingDialog: false,

            // Payment Method data
            nameRules: [
                value => !!value || 'Se requiere nombre de cuenta habiente.',
                value => {
                    const pattern = /^([A-Z]|[ ]|['´-])*$/
                    return pattern.test(value) || 'El nombre solo puede contener letras y espacios.'
                },
            ],
            currentUser: '',
            customerId: '',
            cardData: null,
            cardName: '',

        }
    },
    watch: {
        cardName(value) {
            this.cardName = value.toString().toUpperCase();
        },
        // Watching to when dialog is opened to request the Stripe Card field element to be created before component creation
        dialogTrigger(value) {
            if(value === true) {
                this.$nextTick(function () {
                    this.createStripeElement();
                });
            }
        }
    },
    computed: {
        stripeElements() {
            return this.$stripe.elements();
        },
    },
    async beforeMount() {
        this.currentUser = firebase.auth().currentUser.email;

        await this.getDoctorStripeData();
    },
    beforeDestroy() {
        this.cardData.destroy();
    },
    methods: {
        createStripeElement() {
            // 1. Set Up Text Field styles
            let stripeTextFieldStyle = {
                base: {
                    color: '#fff',
                    fontWeight: '600',
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: '14px',
                    '::placeholder': {
                        fontWeight: '400',
                        color: '#fff',
                    },
                }
            }

            // 2. Request Card Field element creation
            this.cardData = this.stripeElements.create('card', {style: stripeTextFieldStyle});
            this.cardData.mount('#card-name');
        },

        async assignCardAsDefaultPaymentMethod(cardToken) {
            try {
                // 1. Set Up Request body
                let requestObject = {
                    cardToken: cardToken['id'],
                    customerId: this.customerId,
                }

                // 2. HTTPS Request to Kuida Server to assign given token to current user
                let response = await cloud_functions_request('assignCardTokenAsDefaultPaymentMethod', requestObject);

                // 3.A Return successful response
                if (response.status === 200) {
                    return true;
                }

            } catch (e) {
                // 3.B Return error response
                return false;
            }
        },

        async savePaymentMethodRequest() {
            this.loadingDialog = true;

            // 0. CHECK FOR EMPTY FIELDS
            if (this.checkEmptyName()) {
                // 1. CREATE CARD TOKEN FOR GIVEN CARD
                let cardToken = await this.createCardToken()

                if (cardToken === null) {
                    this.loadingDialog = false;

                    // 1.B Display error on Card Token creation
                    this.alertDialogTitle = "ERROR";
                    this.alertDialogMessage = "Ha ocurrido un error con tu tarjeta, favor de introducir una tarjeta válida o inténtalo más tarde.";
                    this.alertDialogTrigger = true;

                    return;
                }

                // 2. ASSIGN CARD TOKEN TO CURRENT USER AND ASSIGN IT AS DEFAULT PAYMENT METHOD
                let cardAssignationResult = await this.assignCardAsDefaultPaymentMethod(cardToken);

                if (cardAssignationResult === false) {
                    this.loadingDialog = false;

                    // 2.B Display error on Card Token assignation
                    this.alertDialogTitle = "ERROR";
                    this.alertDialogMessage = "Ha ocurrido un error al dar de alta tu información bancaria, favor de introducir cuentas bancarias válidas o inténtalo más tarde.";
                    this.alertDialogTrigger = true;

                    return;
                }

                // 3. CLOSE DIALOG
                this.loadingDialog = false;
                this.$emit('dialog-close');

            } else {
                this.loadingDialog = false;
                this.alertDialogTitle = "ERROR";
                this.alertDialogMessage = "Favor de introducir nombre completo del propietario de la cuenta bancaria.";
                this.alertDialogTrigger = true;
            }


        },

        async getDoctorStripeData() {
            let doctorData = await firebase.firestore().collection('doctors').doc(this.currentUser).get().then((snapshot) => {
                return snapshot.data();
            });

            this.customerId = doctorData['stripeCustomerId'];
        },

        async createCardToken() {
            // 1. Set Up Card details body request
            let body = {
                name: this.cardName,
            };

            // 2. Send Card data from Stripe Element directly to Stripe Server to receive a token for given payment method
            const {token, error} = await this.$stripe.createToken(this.cardData, body);

            // 3.A Display possible errors on Card form
            if (error) {
                // handle error here and display it to user
                document.getElementById('card-error').innerHTML = error.message;
                this.loadingDialog = false;

                return null;

            } else {
                this.loadingDialog = false;

                // 3.B Return generated Card Token from given Payment Method.
                return token;
            }
        },

        checkEmptyName() {
            return this.cardName !== '';
        },

        clearTextFields() {
            this.cardName = '';
        },

        closeDialog() {
            this.clearTextFields();

            this.$emit('dialog-close')
        },

    }
}
</script>

<style scoped>
.dialogTitle {
    color: #FFF;
    font-size: 1.5em !important;
    font-weight: bold;
}

.card-details-text-field {
    border: #FFF 2px solid;
    padding: 10px 10px;
    margin-bottom: 5px;
    border-radius: 13px;
}

.card-details-label {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: white;
}

</style>
