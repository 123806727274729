<template>
    <!-- PLAN SELECTION SECTION -->
    <v-col align-self="start" cols="8">
        <v-row align="center" justify="center">
            <p class="section-title mt-5">Suscripciones Kuida para especialistas de la salud</p>
        </v-row>
        <v-row align="center" justify="center">
            <p
                    v-if="availableSubscriptionTrial === true"
                    class="section-title mt-n4 mb-0"
                    style="color:#A1A1A1;"
            >¡Disfruta 30 días de Kuida gratis!</p>
            <p
                    v-else
                    class="section-title mt-n4 mb-0"
                    style="color:#A1A1A1;"
            >¡Utiliza todas las herramientas de Kuida!</p>
        </v-row>
        <v-row align="center" justify="center">
            <p
                    v-if="availableSubscriptionTrial === true"
                    class="details-text mb-2"
                    style="color: #A1A1A1; font-weight: 500;"
            >
                Selecciona cualquiera de nuestros planes y comienza tu prueba gratis.
            </p>
            <p
                    v-else
                    class="details-text mb-2"
                    style="color: #A1A1A1; font-weight: 500;"
            >
                Selecciona cualquiera de nuestros planes y comienza a agendar citas.
            </p>
        </v-row>

        <v-container fill-height fluid>
            <!-- PLANS DISPLAY -->
            <v-row align="center" justify="center">
                <!-- PREMIERE + SUBSCRIPTION -->
                <v-col cols="12" md="4" sm="4">
                    <v-card
                            id="premiere-plus-card"
                            class="isNotActive plan"
                            elevation="2"
                            rounded="xl"
                            @click.native="selectPlanCard('premiere-plus-card')"
                    >
                        <v-card-text>
                            <v-row justify="center">
                                <p class="title-tag">PREMIERE +</p>
                            </v-row>
                            <v-row justify="center">
                                <p class="price-tag">$18,999.00</p>
                            </v-row>
                            <v-row justify="center">
                                <p class="currency-tag">SIN COMISIONES POR CITA</p>
                            </v-row>
                            <v-row justify="center">
                                <p class="duration-tag">POR 1 AÑO</p>
                            </v-row>
                            <v-row justify="center">
                                <p class="resume-tag">Pago anual</p>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

                <!-- PREMIERE SUBSCRIPTION -->
                <v-col cols="12" md="4" sm="4">
                    <v-card
                            id="premiere-card"
                            class="isNotActive plan"
                            elevation="2"
                            rounded="xl"
                            @click.native="selectPlanCard('premiere-card')"
                    >
                        <v-card-text>
                            <v-row justify="center">
                                <p class="title-tag">PREMIERE</p>
                            </v-row>
                            <v-row justify="center">
                                <p class="price-tag">$1,399.00</p>
                            </v-row>
                            <v-row justify="center">
                                <p class="currency-tag">+ 5% COMISIÓN POR CITA</p>
                            </v-row>
                            <v-row justify="center">
                                <p class="duration-tag">POR 1 AÑO</p>
                            </v-row>
                            <v-row justify="center">
                                <p class="resume-tag">Pago anual</p>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

                <!-- STANDARD SUBSCRIPTION -->
                <v-col cols="12" md="4" sm="4">
                    <v-card
                            id="standard-card"
                            class="isNotActive plan"
                            elevation="2"
                            rounded="xl"
                            @click.native="selectPlanCard('standard-card')"
                    >
                        <v-card-text>
                            <v-row justify="center">
                                <p class="title-tag">STANDARD</p>
                            </v-row>
                            <v-row justify="center">
                                <p class="price-tag">$499.00</p>
                            </v-row>
                            <v-row justify="center">
                                <p class="currency-tag">+ 5% COMISIÓN POR CITA</p>
                            </v-row>
                            <v-row justify="center">
                                <p class="duration-tag">POR 3 MESES</p>
                            </v-row>
                            <v-row justify="center">
                                <p class="resume-tag">Pago trimestral</p>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>


            <v-col cols="12" md="11">
                <!-- PLAN RULES -->
                <p class="details-text">
                    {{ subscriptionRulesText }}
                </p>

                <!-- CTA BUTTON -->
                <v-row align="center" justify="center">
                    <!-- START FREE TRIAL BUTTON -->
                    <button
                            class="kuida-md-btn grey-btn button-shadow mt-2"
                            @click="couponCodeFormTrigger = true"
                    >
                        ¿Tienes un cupón?
                    </button>
                </v-row>

                <!-- CTA BUTTON -->
                <v-row align="center" justify="center">
                    <!-- START FREE TRIAL BUTTON  -->
                    <button
                            v-if="availableSubscriptionTrial === true"
                            class="kuida-md-btn grey-btn button-shadow mt-2"
                            @click="startFreeTrialRequest"
                    >
                        Comenzar Prueba Gratis
                    </button>

                    <!-- START SUBSCRIPTION BUTTON -->
                    <button
                            v-if="availableSubscriptionTrial === false"
                            class="kuida-md-btn aqua button-shadow mt-2"
                            @click="validatePlanSelected"
                    >
                        Comenzar Periodo
                    </button>
                </v-row>
            </v-col>
        </v-container>

        <!-- REDEEM PROMOTION CODE DIALOG -->
        <RedeemPromotionCode_Form
                :dialog-trigger="couponCodeFormTrigger"
                :stripe-customer-id="stripeCustomerId"
                @dialog-close="couponCodeFormTrigger = false"
                @redeemed-coupon="redeemedCouponSuccessfully"
        ></RedeemPromotionCode_Form>

        <!-- ALERT MESSAGES BOTTOM SHEET -->
        <SimpleMessage_BottomSheet
                :bottom-sheet-message="bottomSheetMessage"
                :bottom-sheet-trigger="bottomSheetTrigger"
                @close-bottom-sheet="bottomSheetTrigger = false"
        ></SimpleMessage_BottomSheet>

        <!-- SUBSCRIPTION START CONFIRM DIALOG -->
        <ActionConfirm_Dialog
                :dialog-message="'¿Estás segur@ que quieres comenzar tus 30 días de prueba para la suscripción ' + this.selectedPlan + ' Kuida? Recuerda que es totalmente gratis y puedes editar tu suscripción posteriormente'"
                :dialog-trigger="confirmDialogTrigger"
                accept-label="Comenzar"
                cancel-label="Más tarde"
                dialog-title="Atención"
                @action-cancel="closeConfirmDialog"
                @action-confirm="startSubscriptionTrialRequest"
        ></ActionConfirm_Dialog>

        <!-- RESULTS DIALOG -->
        <Alert_Dialog
                :dialog-message="resultDialogMessage"
                :dialog-title="resultDialogTitle"
                :dialog-trigger="resultDialogTrigger"
                @dialog-close="resultDialogTrigger = false"
        ></Alert_Dialog>

        <!-- LOADING DIALOG -->
        <Loading_Dialog
                :loading-dialog-trigger="loadingDialogTrigger"
                dialog-message="Espera un momento por favor :)"
        ></Loading_Dialog>

        <!-- New Action DIalog  -->
        <!-- Dialogo con datos de subs  -->
        <ActionConfirmSubscription_Dialog
          :dialog-message="'Estás a punto de iniciar una suscripción en Kuida. Antes de confirmar, revisa cuidadosamente los siguientes detalles:'"
          :data-list="dataDetails"
          :dialog-important="'Por favor, ten en cuenta que esta acción no puede revertirse. Además, esta es una suscripción recurrente que puedes cancelar en cualquier momento desde tu perfil.'"
          :dialog-trigger="confirmActionDialogTrigger"
          accept-label="Comenzar"
          cancel-label="Cancelar"
          dialog-title="Resumen de Compra"
          @action-cancel="closeActionConfirmDialog"
          @action-confirm="startSubscriptionRequest"
        ></ActionConfirmSubscription_Dialog>
    </v-col>
</template>

<script>
import SimpleMessage_BottomSheet from "@/components/common_widgets/bottom_sheets/SimpleMessage_BottomSheet";
import ActionConfirm_Dialog from "@/components/common_widgets/dialogs/ActionConfirm_Dialog";
import Alert_Dialog from "@/components/common_widgets/dialogs/Alert_Dialog";
import Loading_Dialog from "@/components/common_widgets/dialogs/Loading_Dialog";
import RedeemPromotionCode_Form from "@/components/profile/dialog_forms/RedeemPromotionCode_Form"
import firebase from "firebase";
import {changeServicesDisplayState} from "@/helpers/change_services_state";

// adding the new dialog component
import ActionConfirmSubscription_Dialog from "@/components/common_widgets/dialogs/ActionConfirmSubscription_Dialog";
import {cloud_functions_request} from "@/helpers/cloud_functions_request";

export default {
  name: "SubscriptionSelection_Section",
  props: {
    stripeCustomerId: {
      type: String,
    },
    availableSubscriptionTrial: {
      type: Boolean,
    },
    paymentMethodStatus: {
      type: Boolean,
    },
    paymentMethodData: Object,
  },
  components: {
    SimpleMessage_BottomSheet,
    RedeemPromotionCode_Form,
    ActionConfirm_Dialog,
    Loading_Dialog,
    Alert_Dialog,

    //adding new component
    ActionConfirmSubscription_Dialog,
  },
  data() {
    return {
      currentUser: '',
      selectedPlan: '',
      bottomSheetMessage: '',
      bottomSheetTrigger: false,
      confirmDialogTrigger: false,
      loadingDialogTrigger: false,
      resultDialogTrigger: false,
      couponCodeFormTrigger: false,
      resultDialogTitle: '',
      resultDialogMessage: '',
      subscriptionRulesText: '',

      //
      confirmActionDialogTrigger: false,
      cardLastFourDigits: '',
      dataDetails: [],
    }
  },
  beforeMount() {
    this.currentUser = firebase.auth().currentUser.email;

    this.selectSubscriptionRulesText();
  },
  watch: {
    paymentMethodData(value) {
      this.parsePaymentMethodData(value);
    }
  },
  methods: {
    async startSubscriptionTrialRequest() {
      this.loadingDialogTrigger = true;

      let requestObject = {
        doctorId: this.currentUser,
        selectedPlan: this.selectedPlan,
        customerId: this.stripeCustomerId,
      }

      let response = await cloud_functions_request('startDoctorSubscriptionTrial', requestObject);

      this.loadingDialogTrigger = false;

      this.closeConfirmDialog();

      if (response['status'] === 200) {
        this.$emit('subscription-started');

        await changeServicesDisplayState(true);

      } else {
        this.resultDialogTitle = "ERROR";
        this.resultDialogMessage = "Ha ocurrido un error al comenzar tu suscripción, intentalo más tarde."
        this.resultDialogTrigger = true;

      }
    },

    async startSubscriptionRequest() {
      if (this.paymentMethodStatus === true) {
        this.loadingDialogTrigger = true;

        let requestObject = {
          doctorId: this.currentUser,
          selectedPlan: this.selectedPlan,
          customerId: this.stripeCustomerId,
        }

        let response = await cloud_functions_request('startDoctorSubscription', requestObject);

        this.loadingDialogTrigger = false;

        this.closeConfirmDialog();

        if (response['status'] === 200) {
          this.$emit('subscription-started');

          await changeServicesDisplayState(true);

        } else {
          this.resultDialogTitle = "ERROR";
          this.resultDialogMessage = "Ha ocurrido un error al comenzar tu suscripción, intentalo más tarde."
          this.resultDialogTrigger = true;

        }
      } else {
        this.resultDialogTitle = "ERROR";
        this.resultDialogMessage = "Necesitas tener un método de pago válido para poder adquirir una suscripción en Kuida."
        this.resultDialogTrigger = true;
      }


    },

    selectSubscriptionRulesText() {
      if (this.availableSubscriptionTrial === true) {
        this.subscriptionRulesText = 'La selección de suscripción no genera ningún cargo. Todos los planes tienen 30 días de prueba' +
          ' gratuita a partir del día que tu hayas seleccionado el plan que más te acomode. Se requiere' +
          ' una tarjeta de débito o crédito VISA, MasterCard o American Express para poder realizar' +
          ' el pago de tu suscripción. La suscripción (en cualquiera de sus planes) te permite hace uso' +
          ' de todas la herramientas de la plataforma Kuida para especialistas de la salud. Puedes' +
          ' cambiar de plan de suscripción posteriormente. En planes Standard y Premiere se cobra 5%' +
          ' de comisión por cada consulta facturada a través de nuestra plataforma cada que quieras' +
          ' disponer de tu saldo disponible. Más información en la pestaña de “Pagos” dentro de la' +
          ' plataforma.'
      } else {
        this.subscriptionRulesText = 'El cargo se realizará al principio de cada periodo de la suscripción. Se requiere' +
          ' una tarjeta de débito o crédito VISA, MasterCard o American Express para poder realizar' +
          ' el pago de tu suscripción. La suscripción (en cualquiera de sus planes) te permite hace uso' +
          ' de todas la herramientas de la plataforma Kuida para especialistas de la salud. Puedes' +
          ' cambiar de plan de suscripción posteriormente. En planes Standard y Premiere se cobra 5%' +
          ' de comisión por cada consulta facturada a través de nuestra plataforma cada que quieras' +
          ' disponer de tu saldo disponible. Más información en la pestaña de “Pagos” dentro de la' +
          ' plataforma.';
      }
    },

    redeemedCouponSuccessfully() {
      this.$emit('subscription-started');
    },

    selectPlanCard(selection) {
      if (selection === 'standard-card') {
        document.getElementById('premiere-card').classList.remove("isActive");
        document.getElementById('premiere-card').classList.add("isNotActive");

        document.getElementById('premiere-plus-card').classList.remove("isActive");
        document.getElementById('premiere-plus-card').classList.add("isNotActive");

        document.getElementById(selection).classList.remove("isNotActive");
        document.getElementById(selection).classList.add("isActive");

      } else if (selection === 'premiere-card') {
        document.getElementById('standard-card').classList.remove("isActive");
        document.getElementById('standard-card').classList.add("isNotActive");

        document.getElementById('premiere-plus-card').classList.remove("isActive");
        document.getElementById('premiere-plus-card').classList.add("isNotActive");

        document.getElementById(selection).classList.remove("isNotActive");
        document.getElementById(selection).classList.add("isActive");

      } else if (selection === 'premiere-plus-card') {
        document.getElementById('standard-card').classList.remove("isActive");
        document.getElementById('standard-card').classList.add("isNotActive");

        document.getElementById('premiere-card').classList.remove("isActive");
        document.getElementById('premiere-card').classList.add("isNotActive");

        document.getElementById(selection).classList.remove("isNotActive");
        document.getElementById(selection).classList.add("isActive");

      }


      switch (selection) {
        case 'standard-card':
          this.selectedPlan = 'Standard';
          break;
        case 'premiere-card':
          this.selectedPlan = 'Premiere';
          break;
        case 'premiere-plus-card':
          this.selectedPlan = 'Premiere +';
          break;
      }
    },

    startFreeTrialRequest() {
      if (this.selectedPlan !== '') {
        this.confirmDialogTrigger = true;

      } else {
        this.bottomSheetMessage = "Debes seleccionar un plan antes de comenzar tu prueba. Cualquiera de las 3 opciones tienen 30 días de prueba totalmente gratuitos.";
        this.bottomSheetTrigger = true;

      }
    },

    closeConfirmDialog() {
      this.confirmDialogTrigger = false;
    },

    // Data for ActionConfirmSubscription_Dialog 
    showDataListDetails() {
      // Create an array of data details containing subscription information
      let dataDetailsList = [
        { label: 'Tipo de suscripción', value: this.selectedPlan },
        { label: 'Precio de suscripción', value: this.getPriceForSubscription() },
        { label: 'Duracion de la suscripción', value: this.getDurationForSubscription() },
        { label: 'Fecha en la que acabara tu suscripción', value: this.getEndSubscriptionDate() },
        { label: 'Método de pago', value: '**** **** **** ' + this.cardLastFourDigits },
      ];
      return dataDetailsList;
    },

    getPriceForSubscription() {
      // Determine the subscription price based on the selected plan
      if(this.selectedPlan === 'Premiere +'){
        return '$18,999.00';
      } else if (this.selectedPlan === 'Premiere') {
        return '$1,399.00';
      } else {
        return '$499.00';
      }
    },

    getDurationForSubscription() {
      // Determine the subscription duration based on the selected plan
      if(this.selectedPlan === 'Premiere +'){
        return '1 Año, Pago anual';
      } else if (this.selectedPlan === 'Premiere') {
        return '1 Año, pago anual';
      } else {
        return '3 Meses, pago trimestral';
      }
    },

    getEndSubscriptionDate() {
      // Calculate the end date of the subscription based on the selected plan
      const currentDate = new Date();
      const endOneYearLater = new Date();
      const endThreeMonthsLater = new Date();

      if((this.selectedPlan === 'Premiere +') || (this.selectedPlan === 'Premiere')){
        endOneYearLater.setFullYear(currentDate.getFullYear() + 1);
        return this.formatDate(endOneYearLater);
      } else if (this.selectedPlan === 'Standard') {
        endThreeMonthsLater.setMonth(currentDate.getMonth() + 3);
        return this.formatDate(endThreeMonthsLater);
      } 

    },

    formatDate(date) {
      // Format the date to a string with day, month, and year
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    },

    parsePaymentMethodData(paymentMethodData) {
      // Parse the last four digits of the card from payment method data
      this.cardLastFourDigits = paymentMethodData['card']['last4'];
    },

    validatePlanSelected() {
      // Validate if a subscription plan is selected
      if (this.selectedPlan !== ''){
        // Show confirmation dialog and populate data details
        this.confirmActionDialogTrigger = true;
        this.dataDetails = this.showDataListDetails();
      } else {
        // Show error dialog if no subscription plan is selected
        this.resultDialogTitle = "Atencion"
        this.resultDialogMessage = "No se selecciono ningun plan de suscripcion, favor de seleccionar alguno."
        this.resultDialogTrigger = true;
      }
    },

    closeActionConfirmDialog() {
      this.confirmActionDialogTrigger = false;
    },
  }
}
</script>


<style scoped>
.section-title {
    color: #F96767;
    font-weight: bold;
    font-size: 18px;
}

.plan {
    cursor: pointer;
    height: 35vh;
    width: 45vh;
}

.plan:hover {
    background-color: #eff;
}

.title-tag {
    color: #F96767;
    font-weight: bold;
    font-size: 30px;
    margin-top: 4vh;
}

.price-tag {
    color: #3ec6c6;
    font-weight: bold;
    font-size: 28px;
    margin-top: 3vh;
}

.currency-tag {
    color: #3ec6c6;
    font-weight: bold;
    font-size: 12px;
}

.duration-tag {
    color: #3ec6c6;
    font-weight: bold;
    font-size: 15px;
    margin-top: 2vh;
    margin-bottom: -5px;
}

.resume-tag {
    text-align: center;
    color: #F96767;
    font-weight: bold;
    font-size: 12px;
}

.details-text {
    color: #3ec6c6;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    text-align: center;
}

.isActive {
    border: 8px solid #3ec6c6;
}

.isNotActive {
    border: 8px solid transparent;
}
</style>