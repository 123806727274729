<template>
    <v-container id="room-container">
        <v-row>
            <v-col>
                <!-- LOG OUT BUTTON -->
                <v-row justify="end">
                    <v-icon class="pt-6 pr-8" color="#fffff" medium @click="signOut" style="color: #FFFFFF;">mdi-logout-variant</v-icon>
                </v-row>

                <!-- TAB TITLE -->
                <v-col cols="12">
                    <h1 style="margin-top: -30px;">COMPLETA TU REGISTRO</h1>
                    <h3>INFORMACIÓN DE IDENTIFICACIÓN OFICIAL <v-icon class="mt-n1" color="#fffff" medium @click="infoDialog = true" style="color: #FFFFFF;">mdi-help-circle</v-icon></h3>
                </v-col>

                <v-col cols="12">
                    <v-row align="center" justify="center">
                        <v-col cols="12" sm="6" md="6">
                            <!-- LAST NAME -->
                            <v-text-field
                                    style="font-family: 'Montserrat', sans-serif;"
                                    color="white"
                                    label="Primer Apellido"
                                    outlined
                                    v-model="firstLastName"
                                    dark
                                    dense
                                    class="mb-n2 mt-n2"
                            ></v-text-field>

                            <!-- SECOND LAST NAME -->
                            <v-text-field
                                    style="font-family: 'Montserrat', sans-serif;"
                                    color="white"
                                    label="Segundo Apellido"
                                    outlined
                                    v-model="secondLastName"
                                    dark
                                    dense
                                    class="mb-n2"
                            ></v-text-field>

                            <!-- NAME(S) -->
                            <v-text-field
                                    style="font-family: 'Montserrat', sans-serif;"
                                    color="white"
                                    label="Nombres"
                                    outlined
                                    v-model="names"
                                    dark
                                    dense
                                    class="mb-n2"
                            ></v-text-field>

                            <!-- ADDRESS (FIRST LINE) -->
                            <v-text-field
                                    style="font-family: 'Montserrat', sans-serif;"
                                    color="white"
                                    label="Dirección Línea 1"
                                    outlined
                                    v-model="address1"
                                    dark
                                    dense
                                    class="mb-n2"
                            ></v-text-field>

                            <!-- ADDRESS (SECOND LINE) -->
                            <v-text-field
                                    style="font-family: 'Montserrat', sans-serif;"
                                    color="white"
                                    label="Dirección Línea 2"
                                    outlined
                                    v-model="address2"
                                    dark
                                    dense
                                    class="mb-n4"
                            ></v-text-field>

                            <v-row align="center" justify="center">
                                <v-col cols="12"  md="6">
                                    <!-- ZIP CODE -->
                                    <v-text-field
                                            style="font-family: 'Montserrat', sans-serif;"
                                            color="white"
                                            label="Código Postal"
                                            outlined
                                            v-model="zip"
                                            dark
                                            dense
                                            type="number"
                                            class="mb-n2"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <!-- LOCATION -->
                                    <v-text-field
                                            style="font-family: 'Montserrat', sans-serif;"
                                            color="white"
                                            label="Municipio"
                                            outlined
                                            v-model="city"
                                            dark
                                            dense
                                            @keyup="uppercase"
                                            class="mb-n2"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <!-- STATE -->
                            <v-select
                                    v-model="state"
                                    style="font-family: 'Montserrat', sans-serif;"
                                    color="white"
                                    placeholder="Selecciona un estado"
                                    :items="completeNameStates"
                                    outlined
                                    item-color="white"
                                    menu-props='{class:coral}'
                                    dark
                                    dense
                                    append-icon="mdi-arrow-down"
                            ></v-select>

                        </v-col>
                    </v-row>
                </v-col>

                <!-- ADVANCE STEPS BUTTONS -->
                <v-col cols="12">
                    <v-row justify="center" align="center">
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <!-- GO BACK BUTTON -->
                        <button class="kuida-sm-btn disabled-btn button-shadow">
                            Regresar
                        </button>
                        <v-spacer></v-spacer>
                        <!-- GO NEXT BUTTON -->
                        <button class="kuida-sm-btn aqua-btn button-shadow" @click="storeDataRequest">
                            Continuar
                        </button>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                    </v-row>
                </v-col>

                <!-- INCOMPLETE DATA -->
                <v-bottom-sheet v-model="missingField">
                    <v-sheet class="text-center" height="200px">
                        <v-btn class="mt-6" text color="primary" @click="missingField = false">
                            CERRAR
                        </v-btn>
                        <div class="py-3">
                            Favor de llenar todos los campos.
                        </div>
                    </v-sheet>
                </v-bottom-sheet>

                <!-- INCORRECT IMAGES NUMBER -->
                <v-bottom-sheet v-model="alertError">
                    <v-sheet class="text-center" height="200px">
                        <v-btn class="mt-6" text color="primary" @click="alertError  = false">
                            CERRAR
                        </v-btn>
                        <div class="py-3">
                            Se tienen que subir al entre 2 y 8 imágenes de tu consultorio.
                        </div>
                    </v-sheet>
                </v-bottom-sheet>

                <!-- FILES LOADING INDICATOR -->
                <Loading_Dialog
                        dialog-message="Estamos registrando toda tu información, esto puede tardar unos minutos dependiendo de la calidad de tu  conexión a internet :)"
                        :loading-dialog-trigger="loadingDialog"
                        color="accent"
                ></Loading_Dialog>

                <!-- CITIZEN ID INFO DIALOG -->
                <v-dialog v-model="infoDialog" width="500">
                    <v-card>
                        <v-card-title class="text-h5 lighten-2" color="primary">
                            Información Personal
                        </v-card-title>

                        <v-card-text>
                            Para que el banco nos permita depositarte tu balance disponible dentro de Kuida, necesitamos verificar que toda tu información coincida. Por favor llena las preguntas de esta pestaña con la información tal y como está en tu credencial de elector (INE).
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                    color="primary"
                                    text
                                    @click="infoDialog = false"
                            >
                                Entendido
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import firebase from "firebase";
import router from "@/router";
import * as fullNamesList from "@/constants/completeNamesSatesList"
import * as formattedNamesList from "@/constants/stripeFormattedNamesStatesList"
import Loading_Dialog from "@/components/common_widgets/dialogs/Loading_Dialog"
import {cloud_functions_request} from "@/helpers/cloud_functions_request";

export default {
    name: "ConsultorioForm_Component.vue",
    components: {
        Loading_Dialog,
    },
    data: () => ({
        doctorsCollection: firebase.firestore().collection('doctors'),
        completeNameStates: null,
        states: null,
        state: null,
        names: '',
        firstLastName: '',
        secondLastName: '',
        address1: '',
        address2: '',
        zip: '',
        city: '',
        selectedState: '',
        isSelecting: false,
        alertError: false,
        missingField: false,
        user: '',
        loadingDialog: false,
        infoDialog: true,
    }),
    watch: {
        names(value) {
            this.names = value.toString().toUpperCase();
        },

        firstLastName(value) {
            this.firstLastName = value.toString().toUpperCase();
        },

        secondLastName(value) {
            this.secondLastName = value.toString().toUpperCase();
        },

        address1(value) {
            this.address1 = value.toString().toUpperCase();
        },

        address2(value) {
            this.address2 = value.toString().toUpperCase();
        },

        city(value) {
            this.city = value.toString().toUpperCase();
        },
    },
    async beforeMount() {
        this.user = firebase.auth().currentUser.email;

        this.completeNameStates = fullNamesList.completeNamesStatesList;
        this.states = formattedNamesList.stripeFormattedNamesStatesList;
    },
    methods: {
        async createStripeCustomAccount() {
            // 1. GET DOCTOR'S WHOLE DOCUMENT
            await firebase.firestore().collection('doctors').doc(this.user).get().then(async (snapshot) => {
                const doctorRef = snapshot.ref;
                const doctor = snapshot.data();

                // 2.1 CREATE ADDRESS OBJECT AS STRIPE REQUIRES
                let address = {
                    city: this.city,
                    line1: this.address1,
                    line2: this.address2,
                    postal_code: this.zip,
                    state: this.states[this.selectedState],
                    country: 'MX',
                }

                let date = doctor['birthDate'].toDate();
                let day = date.getDate();
                let month = date.getMonth() + 1;
                let year = date.getFullYear();

                // 2.2 CREATE NEW DOCTOR OBJECT AS STRIPE REQUIRES
                const body = {
                    "doctorId": this.user,
                    "firstName": this.names,
                    "lastName": this.firstLastName + ' ' + this.secondLastName,
                    "rfc": doctor['rfc'],
                    "phone": doctor['phone'],
                    "birthDay": day,
                    "birthMonth": month,
                    "birthYear": year,
                    "idFront": '',
                    "idBack": '',
                    "address": address
                };

                // 3. HTTP REQUEST FOR ACCOUNT & CUSTOMER CREATION
                let response = await cloud_functions_request('newStripeDoctorAccount', body);

                let stripeInfo = response.data;

                this.customerId = stripeInfo['customerId']; // Store doctor customer Id
                this.accountId = stripeInfo['accountId']; // Store doctor custom account Id

                // 4 STORE NEW DOCTOR'S STRIPE IDS
                await doctorRef.set({
                    'stripeCustomerId': stripeInfo['customerId'],
                    'stripeAccountId': stripeInfo['accountId'],
                }, {merge: true});

                // 5. ADD CITIZEN ID IMAGES TO STRIPE CUSTOM ACCOUNT
                let doctorInfo = {
                    doctorId: this.user,
                    stripeAccountId: stripeInfo['accountId']
                }

                let filesResponse = await cloud_functions_request('uploadCitizenIdToStripe', doctorInfo);

                if (filesResponse.status === 400) {
                    this.loadingDialog = false;
                }
            });
        },

        async cleanWholeSubCollection(sub) {
            await firebase.firestore().collection('doctors').doc(this.user).collection(sub).get().then((snapshot) => {
                let docs = snapshot.docs;

                if(docs.length > 0) {
                    docs.forEach(async (doc) => {
                        let documentId = doc.id;
                        console.log("Deleting " + documentId);

                        await firebase.firestore().collection('doctors').doc(this.user).collection(sub).doc(documentId).delete();
                    });
                }
            });
        },

        async storeRemainingFields() {
            let citizenIdObject = {
                'firstLastName': this.firstLastName,
                'secondLastName': this.secondLastName,
                'names': this.names,
                'address1': this.address1,
                'address2': this.address2,
                'zip': this.zip,
                'city': this.city,
                'state': this.states[this.selectedState],
                'country': 'MX'
            }

            await firebase.firestore().collection('doctors').doc(this.user).collection('citizen').add(citizenIdObject).then(async () => {
                console.log('Data has been written into firestore!')

            }).catch(err => {
                console.log(err)
                this.error = err.message;

            })
        },

        async storeDataRequest() {
            // Get abbreviated selected state name
            this.selectedState = this.completeNameStates.findIndex(data => data === this.state);

            // VERIFY EVERY FIELD HAS A VALUE
            if (!this.checkEmptyFields()) {
                // Clear any data previously registered through incomplete form from user.
                await this.deleteOldForm();

                this.loadingDialog = true;

                // THIS STORES TEXT FIELDS DATA ON STRIPE
                await this.createStripeCustomAccount().then(async () => {
                    // THIS STORES TEXT FIELDS DATA ON FIREBASE
                    await this.storeRemainingFields().then(() => {
                        this.loadingDialog = false;
                        // Go to next form tab
                        this.goToTab('welcome-tab')
                    });
                });

            } else {
                this.missingField = true
            }
        },

        async deleteOldForm() {
            // Clear room info
            await this.cleanWholeSubCollection('room');

            // Clear services info
            await this.cleanWholeSubCollection('services');

            // Clear identity info
            await this.cleanWholeSubCollection('citizen');
        },

        checkEmptyFields() {
            return this.firstLastName === '' || this.secondLastName === '' || this.names === '' || this.address1 === '' || this.address2 === '' || this.zip === '' || this.city === '' || this.selectedState === '';
        },

        goToTab(tabName) {
            if (tabName !== '') {
                this.$emit('clickedNext', tabName)
            } else {
                console.log("You can't change tab...");
            }
        },

        async signOut() {
            await firebase.auth().signOut().then(() => {
                console.log('Sign-out successful.')
                router.push('/')
            }).catch((error) => {
                // An error happened.
                console.log(error)
            });
        },

        uppercase() {
            this.names = this.names.toUpperCase();
            this.firstLastName = this.firstLastName.toUpperCase();
            this.secondLastName = this.secondLastName.toUpperCase();
            this.address1 = this.address1.toUpperCase();
            this.address2 = this.address2.toUpperCase();
            this.city = this.city.toUpperCase();
        },
    }
}
</script>

<style scoped>
@import './../../styles/styles.css';

#room-container {
    height: 95vh;
    overflow-y: scroll;
}
</style>
