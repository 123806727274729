<template>
  <v-dialog
    v-model="dialogTrigger"
    max-width="1200"
    overlay-opacity="0.8"
    persistent
  >
    <v-card class="pa-16" color="white">
      <v-row align="center" justify="center">
        <v-col align-self="center">
          <h1 class="primary--text mt-n8" style="text-align: center;">
            {{ dialogTitle.toUpperCase() }}
          </h1>
          <div
            class="mt-2"
            style="text-align: center; font-size: 0.85em; white-space: pre-line;"
          >
            <p>
              {{ dialogMessage }}
            </p>

            <div v-for="(field, index) in dialogFields" :key="index">
              <p>
                <strong>{{ field.field }}</strong>
              </p>
              <p class="mt-n12">
                {{ field.section }}
              </p>
            </div>
          </div>

          <v-row align="center" class="mt-8" justify="center">
            <button
              class="kuida-md-btn coral-btn button-shadow"
              @click="$emit('dialog-close', false)"
            >
              Cerrar
            </button>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AlertPerfilIncomplete_Dialog",
  props: {
    dialogTrigger: Boolean,
    dialogTitle: String,
    dialogMessage: String,
    dialogFields: Array,
  },
};
</script>
