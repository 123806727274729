<template>
    <div>
        <!-- PAYMENT METHOD TITLE -->
        <p class="section-title mt-16">Método de Pago</p>

        <!-- CIRCULAR PROGRESS INDICATOR WHILE RETRIEVE PAYMENT METHOD DATA -->
        <v-container
            v-if="paymentMethodStatus === null"
            fluid
            style="height: 25vh;"
        >
            <v-row align="center" justify="center">
                <v-progress-circular class="mt-8" color="accent" indeterminate></v-progress-circular>
            </v-row>
        </v-container>

        <!-- NO PAYMENT METHOD CONTENT -->
        <v-container
            v-if="paymentMethodStatus === false"
            fluid
            style="height: 25vh;"
        >
            <v-row align="center" justify="start">
                <v-col align-self="center">
                    <h6 class="accent--text" style="text-align: start;">
                        No has registrado ningún método de pago.
                    </h6>
                    <p class="details-text mb-2" style="color: #A1A1A1; font-weight: 500; text-align: start;">
                        {{ pendingPaymentMethodMessage }}
                    </p>

                </v-col>
            </v-row>
        </v-container>

        <!-- PAYMENT METHOD NUMBER LABEL -->
        <SubscriptionInfo_Text
            v-if="paymentMethodStatus === true"
            class="mt-n4"
            :data="'**** **** **** ' + cardLastFourDigits"
            label="Numero de Tarjeta"
        ></SubscriptionInfo_Text>

        <!-- PAYMENT METHOD TYPE LABEL -->
        <SubscriptionInfo_Text
            v-if="paymentMethodStatus === true"
            class="mt-n7"
            :data="cardBrand.toUpperCase()"
            label="Tipo de Tarjeta"
        ></SubscriptionInfo_Text>

        <!-- PAYMENT METHOD NAME LABEL -->
        <SubscriptionInfo_Text
            v-if="paymentMethodStatus === true"
            class="mt-n7"
            :data="cardOwnerName"
            label="Nombre en Tarjeta"
        ></SubscriptionInfo_Text>

        <!-- PAYMENT METHOD EXPIRE DATE LABEL -->
        <SubscriptionInfo_Text
            v-if="paymentMethodStatus === true"
            class="mt-n7"
            :data="cardExpDate"
            label="Fecha de Expiración"
        ></SubscriptionInfo_Text>

        <!-- UPDATE PAYMENT METHOD BUTTON -->
        <v-row align="start">
            <button
                class="kuida-md-btn coral-btn button-shadow mt-4"
                style="width: 300px !important; padding: 8px 25px !important;"
                @click="addPaymentMethodDialogTrigger = true"
            >
                Actualizar Método de Pago
            </button>
        </v-row>

        <!-- ADD PAYMENT METHOD FORM -->
        <AddPaymentMethod_Form
            :dialog-trigger="addPaymentMethodDialogTrigger"
            @dialog-close="updatedPaymentMethodBroadcast"
        ></AddPaymentMethod_Form>
    </div>
</template>

<script>
import SubscriptionInfo_Text from "@/components/common_widgets/texts/SubscriptionInfo_Text"
import AddPaymentMethod_Form from "@/components/profile/dialog_forms/AddPaymentMethod_Form"

export default {
    name: "PaymentMethod_Section",
    props: {
        subscriptionStatus: Boolean,
        paymentMethodStatus: Boolean,
        paymentMethodData: Object,
    },
    components: {
        SubscriptionInfo_Text,
        AddPaymentMethod_Form,

    },
    data() {
        return {
            cardBrand: '',
            cardExpDate: '',
            cardOwnerName: '',
            cardLastFourDigits: '',
            pendingPaymentMethodMessage: '',
            addPaymentMethodDialogTrigger: false,
        }
    },
    watch: {
        subscriptionStatus(value) {
            this.getCorrectMessageBasedOnSubscriptionStatus(value);
            return value;
        },

        paymentMethodData(value) {
            this.parsePaymentMethodData(value);
        }
    },
    methods: {
        getCorrectMessageBasedOnSubscriptionStatus(value) {
            if (value === false) {
                this.pendingPaymentMethodMessage = "Introduce los datos de la tarjeta con la que quieres pagar tu suscripción Kuida. Aceptamos cualquier tarjeta de crédito o débito."
            }
            if (value === true) {
                this.pendingPaymentMethodMessage = "Introduce los datos de la tarjeta con la que quieres pagar tu suscripción Kuida. Aceptamos cualquier tarjeta de crédito o débito. Si tu periodo de prueba caduca y no tienes un método de pago válido tu suscripción será cancelada."
            }
        },

        updatedPaymentMethodBroadcast() {
            this.addPaymentMethodDialogTrigger = false;

            this.$emit('updated-payment-method');
        },

        parsePaymentMethodData(paymentMethodData) {
            let expMonth = paymentMethodData['card']['exp_month'].toString();
            let expYear = paymentMethodData['card']['exp_year'].toString();

            this.cardOwnerName = paymentMethodData['billing_details']['name'];
            this.cardBrand = paymentMethodData['card']['brand'];
            this.cardLastFourDigits = paymentMethodData['card']['last4'];
            this.cardExpDate = expMonth + '/' + expYear.substring(2,4);
        }
    }
}
</script>

<style scoped>
.section-title {
    color: #F96767;
    font-weight: bold;
    font-size: 18px;
}

.details-text {
    color: #3ec6c6;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    text-align: center;
}
</style>