<template>
    <div>
        <!-- SUBSCRIPTION DETAILS SECTION TITLE -->
        <p class="section-title mt-2">Resumen de tu Suscripción Kiuda</p>

        <!-- CIRCULAR PROGRESS INDICATOR WHILE RETRIEVE SUBSCRIPTION DATA -->
        <v-container
                v-if="subscriptionStatus === null"
                fluid
                style="height: 24vh;"
        >
            <v-row align="center" justify="center">
                <v-progress-circular class="mt-8" color="accent" indeterminate></v-progress-circular>
            </v-row>
        </v-container>

        <!-- NO SUBSCRIPTION DATA CONTENT -->
        <v-container
                v-if="subscriptionStatus === false"
                fluid
                style="height: 24vh;"
        >
            <v-row align="center" justify="start">
                <v-col align-self="center">
                    <h6 class="accent--text" style="text-align: start;">
                        No tienes ninguna suscripción Kuida activa.
                    </h6>
                    <p class="details-text mb-2" style="color: #A1A1A1; font-weight: 500; text-align: start;">
                        Puedes usar los días de prueba de cualquier de nuestras suscripciones para conocer la
                        nueva forma de conseguir, atender y cobrar tus citas.
                    </p>
                </v-col>
            </v-row>
        </v-container>

        <!-- SUBSCRIPTION STATUS LABEL -->
        <SubscriptionInfo_Text
                v-if="subscriptionStatus === true"
                class="mt-n4"
                label="Estado de suscripción"
                :data="subscriptionStatusLabel"
        ></SubscriptionInfo_Text>

        <!-- SUBSCRIPTION TYPE LABEL -->
        <SubscriptionInfo_Text
                v-if="subscriptionStatus === true"
                class="mt-n7"
                label="Tipo de suscripción"
                :data="subscriptionType"
        ></SubscriptionInfo_Text>

        <!-- SUBSCRIPTION START DATE LABEL -->
        <SubscriptionInfo_Text
                v-if="subscriptionStatus === true"
                class="mt-n7"
                label="Fecha de Inicio"
                :data="subscriptionStartDate"
        ></SubscriptionInfo_Text>

        <!-- SUBSCRIPTION EXPIRE DATE LABEL -->
        <SubscriptionInfo_Text
                v-if="subscriptionStatus === true"
                class="mt-n7"
                label="Fecha de Expiración"
                :data="subscriptionFinishDate"
        ></SubscriptionInfo_Text>

        <!-- CANCEL SUBSCRIPTION BUTTON -->
        <v-row
                v-if="subscriptionStatus === true"
                align="start"
        >
            <button
                    class="kuida-md-btn grey-btn button-shadow"
                    @click="cancelSubscriptionDialogTrigger = true;"
            >
                Cancelar Suscripción
            </button>
        </v-row>

        <!-- CANCEL SUBSCRIPTION CONFIRM DIALOG -->
        <ActionConfirm_Dialog
                :dialog-trigger="cancelSubscriptionDialogTrigger"
                dialog-title="Atención"
                dialog-message="¿Estás segur@ que quieres cancelar tu suscripción? Si cancelas tu suscripción no podrás agendar nuevas consultas ni hacer publicaciones dentro de Kuida. Esta acción no se puede revertir"
                @action-cancel="closeCancelSubscriptionDialog"
                @action-confirm="cancelSubscriptionRequest"
        ></ActionConfirm_Dialog>

        <!-- RESULTS DIALOG -->
        <Alert_Dialog
                :dialog-trigger="resultDialogTrigger"
                :dialog-title="resultDialogTitle"
                :dialog-message="resultDialogMessage"
        ></Alert_Dialog>

        <!-- LOADING DIALOG -->
        <Loading_Dialog
                :loading-dialog-trigger="loadingDialogTrigger"
                dialog-message="Espera un momento por favor :)"
        ></Loading_Dialog>
    </div>
</template>

<script>
import SubscriptionInfo_Text from "@/components/common_widgets/texts/SubscriptionInfo_Text"
import ActionConfirm_Dialog from "@/components/common_widgets/dialogs/ActionConfirm_Dialog"
import Loading_Dialog from "@/components/common_widgets/dialogs/Loading_Dialog"
import Alert_Dialog from "@/components/common_widgets/dialogs/Alert_Dialog"
import * as dateHelpers from "@/helpers/date_formatting"
import {cloud_functions_request} from "@/helpers/cloud_functions_request";
import {changeServicesDisplayState} from "@/helpers/change_services_state";

export default {
  name: "SubscriptionResume_Section",
  props: {
    subscriptionStatus: Boolean,
    subscriptionsList: Array,
  },
  components: {
    SubscriptionInfo_Text,
    ActionConfirm_Dialog,
    Loading_Dialog,
    Alert_Dialog
  },
  data() {
    return {
      subscriptionFinishDate: null,
      subscriptionStartDate: null,
      subscriptionStatusLabel: '',
      latestSubscription: null,
      subscriptionType: '',
      cancelSubscriptionDialogTrigger: false,
      loadingDialogTrigger: false,
      resultDialogTrigger: false,
      resultDialogTitle: '',
      resultDialogMessage: '',

    }
  },
  watch: {
    subscriptionsList(value) {
      this.selectLatestSubscription(value);
      return value;
    }
  },
  methods: {
    selectLatestSubscription(subscriptionsList) {
      // Select latest subscription from list
      this.latestSubscription = subscriptionsList[0];

      // Create a user-friendly label for subscription status
      this.createStatusLabel();

      // Get Kuida Plan from subscription
      this.subscriptionType = this.latestSubscription['plan']['nickname'];

      // Get & format subscription current period start date
      let startDate = new Date(this.latestSubscription['current_period_start'] * 1000);
      this.subscriptionStartDate = dateHelpers.formatDateFromDateType(startDate);

      // Get & format subscription current period finish date
      let endDate = new Date(this.latestSubscription['current_period_end'] * 1000);
      this.subscriptionFinishDate = dateHelpers.formatDateFromDateType(endDate);
    },

    async cancelSubscriptionRequest() {
      this.loadingDialogTrigger = true;

      let requestObject = {
        subscriptionId: this.latestSubscription['id'],
      }

      let response = await cloud_functions_request('cancelSubscription', requestObject);

      if (response['status'] === 200) {
        this.$emit('subscription-cancelled');

        await changeServicesDisplayState(false);

      } else {

        this.resultDialogTitle = "Error";
        this.resultDialogMessage = "Ha ocurrido un error al cancelar tu suscripción, favor de intentarlo de nuevo.";
        this.resultDialogTrigger = true;
      }

      this.loadingDialogTrigger = false;

      this.closeCancelSubscriptionDialog();
    },

    closeCancelSubscriptionDialog() {
      this.cancelSubscriptionDialogTrigger = false;
    },

    createStatusLabel() {
      if (this.latestSubscription !== null) {
        let status = this.latestSubscription['status'];

        switch (status) {
          case 'trialing':
            this.subscriptionStatusLabel = "Periodo de Prueba";
            return
          case 'active':
            this.subscriptionStatusLabel = "Activa";
            return
          case 'past_due':
            this.subscriptionStatusLabel = "Vencida";
            return
          case 'unpaid':
            this.subscriptionStatusLabel = "Falta de Pago";
            return
          case 'canceled':
            this.subscriptionStatusLabel = "Cancelada";
            return
          case 'incomplete':
            this.subscriptionStatusLabel = "Incompleta";
            return
          case 'incomplete_expired':
            this.subscriptionStatusLabel = "Incompleta & Vencida";
            return
          case 'ended':
            this.subscriptionStatusLabel = "Terminada";
            return
          default:
            this.subscriptionStatusLabel = "Error al obtener información";
            return;
        }
      }
    }
  }
}
</script>

<style scoped>
.section-title {
    color: #F96767;
    font-weight: bold;
    font-size: 18px;
}

.details-text {
    color: #3ec6c6;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    text-align: center;
}
</style>
