<template>
    <!--PATIENTS LIST -->
    <v-col cols="5">
        <v-flex>
            <v-col>
                <!--TITLE: MIS PACIENTES-->
                <v-container>
                    <v-text-field class="title_text" v-text="'MIS PACIENTES'"></v-text-field>
                </v-container>

                <!--CARD WITH PATIENTS-->
                <v-card class="" elevation="4">
                    <!--LIST OF PATIENTS-->
                    <v-data-table
                        :headers="headers"
                        :items="patients"
                        class="patients-table"
                        no-data-text="Aún no tienes pacientes"
                        style="color: #5AC6C8; font-weight: bold; border-radius: 20px !important; width: 100%;"
                        @click:row="handleClick"
                        hide-default-footer
                    >
                    </v-data-table>
                </v-card>
            </v-col>
        </v-flex>
    </v-col>
</template>

<script>
import firebase from "firebase";

export default {
    name: "PatientsCard",
    data: () => ({
        selectedItem: 1,
        appointments: new Map(),
        appointmentsCollection: firebase.firestore().collection('appointments'),
        patientsCollection: firebase.firestore().collection('users'),
        patients: [],
        names: [],
        patientsIds: [],
        headers: [
            {
                text: 'Nombre',
                align: 'start',
                filterable: false,
                value: 'name',
                class: "white--text subtitle-1 font-weight-bold"
            },
            {text: 'Última cita', value: 'date', class: "white--text subtitle-1 font-weight-bold"},
            {text: '', value: 'ver', sortable: false, class: "white--text"}
        ]
    }),
    created: async function () {
        //We get the logged user currentUser
        this.email = this.getLoggedInUserEmail();

        //Stream listener to get the users appointment
        let userAppointments = firebase.firestore().collection('appointments').where('doctor', "==", this.email);
        userAppointments.get().then(async (snap) => {
                //For every doc
                for (let i = 0; i < snap.docs.length; i++) {
                    this.appointments.has(snap.docs[i].data()['patient']) === false ? this.appointments.set(snap.docs[i].data()['patient'], snap.docs[i].data()) : null;
                }


                this.appointments.forEach(async (element) => {
                    let patientId = element['patient'];

                    if(patientId !== '' && patientId !== null && patientId !== undefined) {

                        let patientName = await firebase.firestore().collection('users').doc(patientId).get().then((snap) => {
                            if (snap.exists) {
                                return snap.data()['name'];
                            } else {
                                return null;
                            }
                        });

                        //Get last appointment date
                        let date = await new Date(element['date'].seconds * 1000);
                        date = date.toLocaleDateString();

                        await this.patientsCollection.doc(patientId).onSnapshot(function () {
                            window.name = patientName;
                        });

                        //Save it to patients list
                        this.patientsIds.push(patientId);
                        this.patients.push({id: patientId, name: patientName, date: date, ver: 'Ver'});
                    }
                });
            }
        );
    },
    methods: {
        getLoggedInUserEmail() {
            return firebase.auth().currentUser.email;
        },

        handleClick(e) {
            this.$emit('childToParent', e.name, e.date, e.id)
        }
    }
}

</script>

<style>
thead {
    background-color: #F96767;
}

.table-header /deep/ {

thead {
    border: 10px solid red;
}

}

.patients-table {
    border-radius: 10px !important;
}

.my-table table tr {
    border-radius: 10px !important;
    font-size: 16px;
    color: #f96767;
    background-color: #f96767;
}

.title_text {
    font-size: 1.7rem;
    color: #F96767 !important;
    font-weight: bold;
}

.theme--light.v-input {
    color: #5AC6C8;
}
</style>