<template>
    <v-overlay :value="loadingDialogTrigger" z-index="2">
        <v-dialog v-model="loadingDialogTrigger" hide-overlay persistent width="450">
            <v-card :color="color" dark>
                <v-card-text style="padding: 30px;">
                    <h3>{{ dialogMessage }}</h3>

                    <v-progress-linear class="mb-1 mt-5" color="white" indeterminate></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-overlay>
</template>

<script>
export default {
    name: "Loading_Dialog",
    props: {
        loadingDialogTrigger: Boolean,
        dialogMessage: String,
        color: {
            type: String,
            default: "primary"
        }
    }
}
</script>

<style scoped>

</style>