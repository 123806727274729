<template>
    <v-col cols="7">
        <v-flex>
            <v-card class="mx-auto" max-width="100%" elevation="0">
                <!-- DISPLAY CONTENT IN CASE PATIENT HAS ALREADY BEEN CHOSEN -->
                <v-col v-if="patientName !== ''">
                    <!--TITLE: NOTAS DE PACIENTE-->
                    <v-container>
                        <v-row justify="start" class="my-3 mt-4">
                            <h1 style="font-size: 1.7rem;" class="primary--text">NOTAS DE PACIENTE</h1>
                        </v-row>
                        <v-row justify="start" class="my-3">
                            <h2 style="font-weight: bold;" class="grey--text">Nombre de paciente: {{ patientName }}</h2>
                        </v-row>
                        <v-row justify="start" class="my-3">
                            <h2 style="font-weight: bold;" class="grey--text">Última cita: {{ lastAppointment }}</h2>
                        </v-row>
                    </v-container>

                    <!-- NOTES CARDS LIST -->
                    <v-col v-if="notes.length > 0">
                        <Notes_Card
                                v-for="note in notes"
                                :key="note['noteId']"
                                v-show="note.display"
                                :note-data="note"
                        ></Notes_Card>
                    </v-col>

                    <v-row v-else justify="center" align="center" class="my-3" style="height: 100%;">
                        <h2 style="font-weight: bold; margin-top: 25%;" class="grey--text">
                            No existe ninguna nota en el historial del paciente seleccionado.
                        </h2>
                    </v-row>

                </v-col>

                <!-- DISPLAY TEXT IN CASE NO PATIENT HAS BEEN CHOSEN -->
                <v-col v-else>
                    <!--TITLE: NOTAS DE PACIENTE-->
                    <v-container>
                        <v-row justify="start" class="my-3 mt-4">
                            <h1 style="font-size: 1.7rem;" class="primary--text">NOTAS DE PACIENTE</h1>
                        </v-row>
                        <v-row justify="center" align="center" class="my-3" style="height: 100%;">
                            <h2 style="font-weight: bold; margin-top: 40%;" class="grey--text">
                                Selecciona un paciente
                            </h2>
                        </v-row>
                    </v-container>

                </v-col>
            </v-card>

        </v-flex>
    </v-col>
</template>

<script>
import Notes_Card from "../common_widgets/cards/Notes_Card";
import firebase from "firebase";

export default {
  name: 'PatientsNotes',
  components: {
    Notes_Card
  },
  data: () => ({
    showPosts: false,
    notesDocuments: [],
    notesCollection: firebase.firestore().collection('notes'),
    notes: [],
    email: ''
  }),
  async created() {
    //We get the logged user currentUser
    this.email = this.getLoggedInUserEmail();
  },
  props: {
    patientName: String,
    lastAppointment: String,
    patientId: String
  },
  watch: {
    patientId: {
      immediate: true,
      async handler() {
        await firebase.firestore().collection('notes')
          .where('doctor', '==', this.email)
          .where('patient', '==', this.$props.patientId)
          .orderBy('date', "desc")
          .onSnapshot((snap) => {
            this.notesDocuments = [];

            for (let i = 0; i < snap.docs.length; i++) {
              if (snap.docs[i].data()['display'] === true) {
                this.notesDocuments.push(snap.docs[i]);
              }
            }
            this.getNotesDetails();
          });
      }
    }
  },
  methods: {
    getLoggedInUserEmail() {
      return firebase.auth().currentUser.email;
    },

    async getNotesDetails() {
      this.notes = [];

      for (let i = 0; i < this.notesDocuments.length; i++) {
        let noteID = await this.notesDocuments[i].id;
        let patient = await this.notesDocuments[i].data()['patient'];
        let date = await new Date(this.notesDocuments[i].data()['date'].seconds * 1000);
        let title = await this.notesDocuments[i].data()['title'];
        let note = await this.notesDocuments[i].data()['note'];
        let display = await this.notesDocuments[i].data()['display'];
        let images = await this.notesDocuments[i].data()['img'];
        date = date.toLocaleDateString();

        this.notes.push({
          title: title,
          date: date,
          description: note,
          images: images,
          display: display,
          patient: patient,
          noteId: noteID
        });
      }
    },
  }

}
</script>