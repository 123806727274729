<template>
    <v-col
        align-self="center"
        align="center"
        class="px-4"
        cols="12"
    >
        <v-card
            class="rounded-xl"
            :color="color"
            elevation="5"
            height="30vh"
        >
            <!-- CIRCULAR PROGRESS INDICATOR WHILE DATA DONT EXIST -->
            <v-container
                v-if="data === null"
                style="height: 10vh;"
                fluid
            >
                <v-row align="center" justify="center">
                    <v-progress-circular class="mt-5" color="white" indeterminate></v-progress-circular>
                </v-row>
            </v-container>

            <v-container class="fill-height">
                <v-col
                    align-self="center"
                >
                    <!-- CARD TITLE -->
                    <p
                        class="text-center white--text font-weight-bold card-text px-2 text-md-body-1 text-sm-body-2"
                        style="font-size: 0.7em;"
                    >{{ title }}</p>

                    <!-- CARD ICON -->
                    <p class="text-center mb-1 mt-n2 card-text  text-lg-h6 text-md-body-1 text-sm-body-2">
                        <v-icon class="white--text" size="270%">{{ icon }}</v-icon>
                    </p>

                    <!-- CARD DATA -->
                    <p
                        class="text-center white--text mb-0 font-weight-bold card-text  text-lg-h6 text-md-body-1 text-sm-body-2"
                    >${{ data.toString() }}</p>

                </v-col>
            </v-container>
        </v-card>
    </v-col>
</template>

<script>
    export default {
        name: 'AppointmentsInfoCard',
        props: {
            color: {
                type: String,
                default: '#f96767'
            },
            title: {
                type: String,
            },
            icon: {
                type: String,
            },
            data: {
                type: Number
            },
        }
    }
</script>

<style>
p {
    font-family: 'Montserrat', sans-serif;
}

.card-text {
    font-size: 20px;
}
</style>