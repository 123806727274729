<template>
    <v-col align="center" cols="6">
        <v-row align="center" justify="center">
            <!-- CARD TITLE -->
            <h2 class="card-title">Información de Consultorio</h2>

            <!-- UPDATE ROOM INFO DIALOG FORM -->
            <UpdateRoomInfo_Form></UpdateRoomInfo_Form>
        </v-row>

        <!-- CARD -->
        <v-sheet class="rounded-xl mt-3" color="primary" dark elevation="3" height="52vh" style="overflow-y: auto;">
            <v-container class="pa-6" fill-height>
                <v-row v-if="foundRoomData === true" align="center" justify="center" style="width: 90%;">
                    <!-- INFO COLUMN -->
                    <v-col align="start" class="pl-3" cols="7">
                        <p class="card-info-title mt-2">Nombre de Consultorio</p>
                        <p class="card-info-content mt-n4">{{ roomData.roomName }}</p>

                        <p class="card-info-title mt-1">Tipo</p>
                        <p class="card-info-content mt-n4">{{ roomData.type }}</p>

                        <p class="card-info-title mt-1">Dirección</p>
                        <p class="card-info-content mt-n4">{{ roomData.address }}</p>

                        <p class="card-info-title mt-1">Añadido Desde</p>
                        <p class="card-info-content mt-n4">{{ formattedCreationDate }}</p>

                        <p class="card-info-title mt-1">Teléfono</p>
                        <p class="card-info-content mt-n4">{{ roomData.phone }}</p>
                    </v-col>

                    <!-- ROOM MEDIA COLUMN -->
                    <v-col class="" cols="5">
                        <v-container fill-height style="width: 95%;">
                            <v-row>
                                <!-- ROOM IMAGES SLIDER -->
                                <v-carousel
                                    dark
                                    height="150"
                                    hide-delimiters
                                    next-icon="mdi-chevron-right"
                                    prev-icon="mdi-chevron-left"
                                    show-arrows-on-hover
                                >
                                    <v-carousel-item
                                        v-for="(item,i) in roomData.img"
                                        :key="i"
                                        :src="item"
                                    ></v-carousel-item>
                                </v-carousel>

                                <!-- ROOM LOCATION ON MAP -->
                                <gmaps-map
                                    id="roomLocationMap"
                                    :options="mapOptions"
                                >
                                    <gmaps-marker
                                        :clickable="true"
                                        :position="roomLocation"
                                        @click="center=roomLocation"
                                    />
                                </gmaps-map>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>

                <v-row v-else align="center" class="noSpace" justify="center">
                    <v-col align-self="center" class="mx-8">
                        <p class="white--text" style="font-size: 0.8em; font-weight: bold;">
                            No tienes información de consultorio, haz click en el ícono de editar para agregar tus
                            datos.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
    </v-col>
</template>

<script>
import firebase from "firebase";
import UpdateRoomInfo_Form from "@/components/profile/dialog_forms/UpdateRoomInfo_Form";
import * as dateHelper from "@/helpers/date_formatting"
import {gmapsMap, gmapsMarker} from "x5-gmaps";

export default {
    name: "RoomInfo_Section",
    components: {
        UpdateRoomInfo_Form,
        gmapsMap,
        gmapsMarker,
    },
    data() {
        return {
            center: null,
            user: null,
            roomData: {},
            roomLocation: {},
            foundRoomData: false,
            formattedCreationDate: null,
            mapOptions: {},
        }
    },
    beforeMount() {
        this.user = firebase.auth().currentUser.email;
    },
    async mounted() {
        await this.getDoctorRoomDocument();
    },
    methods: {
        async getDoctorRoomDocument() {
            let doctorId = this.user;

            await firebase.firestore().collection('doctors').doc(doctorId).collection('room').orderBy('creationDate', 'desc').onSnapshot((snapshot) => {
                if (!snapshot.empty) {
                    this.foundRoomData = true;

                    this.roomData = snapshot.docs[0].data();

                    this.roomLocation = {
                        lat: this.roomData.location.latitude,
                        lng: this.roomData.location.longitude,
                    }

                    this.mapOptions = {
                      center: { lat: this.roomLocation.lat, lng: this.roomLocation.lng, },
                      zoom: 10,
                    }

                    this.formattedCreationDate = dateHelper.formatDate(this.roomData.creationDate);

                } else {
                    this.foundRoomData = false;
                }
            });
        },
    }
}
</script>