<template>
    <v-container style="margin-top: 50px;">
        <!--FEED-->
        <v-row align="center" justify="center">
            <v-col v-for="(card) in cards" :key="card.id" class="mt-6" cols="10">
                <Post_Card :post=card></Post_Card>
            </v-col>
        </v-row>

        <v-row align="center" justify="center">
            <v-btn v-if="!noPosts" v-on:click="nextPosts">
                Más posts
            </v-btn>
        </v-row>

        <v-row align="center" justify="center">
            <v-col align-self="center">
                <v-container fluid style="height: 85vh">
                    <p
                        v-if="noPosts"
                        id="noPostsLabel"
                    >
                        No se ha encontrado ninguna publicación
                    </p>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import firebase from 'firebase'
import Post_Card from "@/components/common_widgets/cards/Post_Card";

export default {
    name: 'MainFeed',
    components: {
        Post_Card,
    },
    props: [
        'query'
    ],
    data: () => ({
        user: '',
        cards: [],
        feedCollection: firebase.firestore().collection('feed').limit(3),
        dates: [],
        noPosts: false,
        lastPost: null,
        youSureModal: false,
    }),
    created() {
        this.user = firebase.auth().currentUser.email;

        this.feedCollection = this.query == null ? this.feedCollection : this.query
        this.fetchPosts()
    },
    methods: {
        fetchPosts() {
            this.feedCollection.orderBy('date', "desc").get().then(query => {
                if (query.empty) {
                    this.noPosts = true
                } else {
                    this.lastPost = query.docs[query.docs.length - 1]
                    query.forEach(item => {
                        this.cards.push({
                            'id': item.id,
                            'title': item.data().title,
                            'date': item.data().date,
                            'content': item.data().content,
                            'img': item.data().img,
                            'likes': item.data().likes,
                            'author': item.data().author
                        })
                    })

                    // console.log(this.cards);
                }
            }).catch((err) => {
                console.log(err)
            })
        },

        nextPosts() {
            this.feedCollection.orderBy('date', "desc").startAfter(this.lastPost).get().then(query => {
                this.lastPost = query.docs[query.docs.length - 1]
                query.forEach(item => {
                    this.cards.push({
                        'title': item.data().title,
                        'date': item.data().date,
                        'content': item.data().content,
                        'img': item.data().img,
                        'likes': item.data().likes,
                        'author': item.data().author
                    })
                })
            })
        },
    }
}
</script>

<style scoped>
#noPostsLabel {
    text-align: center !important;
    color: #3ec6c6;
    margin-top: 20%;
    font-size: 1.5em;
    font-weight: bold;
}

.head {
    padding: 0.3rem 1.5rem;
    color: #F96767;
}

.tag_text {
    font-size: 1.1rem;
    font-weight: bold;
    color: #C7C7C7;
}

.date_text {
    color: #F96767;
    text-align: right;
    font-size: 1rem;
}

.description_text {
    font-size: 1.3rem;
    font-weight: 500;
}
</style>