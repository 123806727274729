<template>
    <v-container class="py-2" fluid>
        <v-row align="center" justify="center">
            <v-container class="mt-6">
                <v-row v-if="reviewsList.length === 0" justify="center" align="center">
                    <h1 style="color: #F96767;">Aún no tienes ninguna reseña</h1>
                </v-row>
                <v-row justify="center" align="center" class="px-16">
                    <p style="text-align: center;">Invita a tus pacientes a agendar una cita contigo a través de Kuida para que puedan realizar una reseña sobre ti.</p>
                </v-row>
                <v-row justify="center" align="center" class="px-16 mb-0 mt-4">
                    <v-dialog
                        v-model="dialog"
                        width="500"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <button
                                class="aqua-btn kuida-big-btn button-shadow"
                                @click="getUsersAppLink()"
                                v-bind="attrs"
                                v-on="on"
                            >
                                Copiar link de app para usuarios
                            </button>
                        </template>

                        <v-card class="px-12 py-4" color="primary">
                            <v-card-title class="text-h5 white--text" style="text-align: center;">
                                Link copiado en portapapeles
                            </v-card-title>
                        </v-card>
                    </v-dialog>
                </v-row>
            </v-container>

            <v-col v-if="reviewsList.length > 0"  align="center" cols="8">
                <v-container style="overflow: auto;">
                    <DynamicScroller
                        :items="reviewsList"
                        :min-item-size="200"
                        :key-field="id"
                        bench="3"
                    >
                        <template v-slot="{ item, index, active }">
                            <DynamicScrollerItem
                                :data-index="index"
                                :item="item"
                                :active="active"
                                :size-dependencies="[ item.comment ]"
                            >
                                <Review_Card :review="item"></Review_Card>
                            </DynamicScrollerItem>
                        </template>
                    </DynamicScroller>
                </v-container>
            </v-col>

            <v-btn
                fab
                dark
                fixed
                bottom
                right
                color="primary"
                style="border-radius: 45px;"
                @click="toTop"
            >
                <v-icon large>mdi-chevron-up</v-icon>
            </v-btn>
        </v-row>

    </v-container>
</template>

<script>
import Review_Card from "@/components/common_widgets/cards/Review_Card";
import firebase from "firebase";

// Method used to delay scripts execution
const delay = ms => new Promise(res => setTimeout(res, ms));

export default {
    name: "DoctorReviews_Component",
    components: {
        Review_Card,
    },
    data() {
        return {
            user: '',
            dialog: false,
            reviewsList: []
        }
    },
    async created() {
        this.user = firebase.auth().currentUser.email;

        await this.getReviewsDocumentsList();
    },
    methods: {
        async getReviewsDocumentsList() {
            await firebase.firestore().collection('doctors').doc(this.user).collection('reviews').onSnapshot((snapshot) => {
                this.reviewsList = [];

                snapshot.docs.forEach((item) => {
                    const data = item.data();
                    const id = item.id;

                    const map = {
                        id: id,
                        rate: data.rate,
                        date: this.formatDate(data.date),
                        patient: data.patient,
                        comment: data.comment,
                    }

                    this.reviewsList.push(map);
                });
            });
        },

        async getUsersAppLink() {
            /* Get the text field */
            let copyText = "https://kuida-users.firebaseapp.com/";

            try {
                await navigator.clipboard.writeText(copyText);

                // Sleep for 1sec
                await delay(800);

                this.dialog = false;

            } catch($e) {
                alert('No hemos podido agregar el link a portapapeles, pero aquí está el link --> ' + copyText);
            }
        },

        formatDateNumbers(number) {
            if (number.toString().length === 1) return '0' + number;
            else return number;
        },

        formatDate(date) {
            const dateTime = date.toDate();
            let day = this.formatDateNumbers(dateTime.getDate());
            let month = this.formatDateNumbers((dateTime.getMonth() + 1));
            let year = dateTime.getFullYear();
            return day + '/' + month + '/' + year;
        },

        onScroll (e) {
            if (typeof window === 'undefined') return
            const top = window.pageYOffset || e.target.scrollTop || 0
            this.fab = top > 20;
        },

        toTop () {
            this.$vuetify.goTo(0)
        }
    }
}
</script>

<style scoped>
</style>