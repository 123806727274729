<template>
    <v-container id="room-container">
        <v-row>
            <!-- LOG OUT BUTTON -->
            <v-row justify="end">
                <v-icon class="pt-10 pr-10" color="#fffff" medium @click="signOut" style="color: #FFFFFF;">mdi-logout-variant</v-icon>
            </v-row>

            <!-- TAB TITLE -->
            <v-col cols="12">
                <h1 style="margin-top: -20px;">BIENVENID@ A KUIDA</h1>
                <h2>INFORMACIÓN DE IDENTIFICACIÓN OFICIAL</h2>
            </v-col>

            <v-col cols="12">
                <v-row align="center" justify="center">
                    <v-col cols="12" sm="6" md="6">
                        <v-container fluid style="height: 55vh;">
                            <!-- TODO Place something here -->
                            <h5 class="white--text" style="text-align: center;">
                                Ha llegado el momento de conocer Kuida, la solución para especialistas de la salud.
                            </h5>

                            <h4 class="mt-16 white--text" style="text-align: center;">
                                Haz click en "Continuar" para comenzar a usar Kuida. Te recomendamos completes tu perfil
                                para que la gente puede conocer más sobre ti desde el momento en el que aceptemos tu cuenta.
                            </h4>
                        </v-container>
                    </v-col>
                </v-row>
            </v-col>

            <!-- ADVANCE STEPS BUTTONS -->
            <v-col cols="12">
                <v-row justify="center" align="center">
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <!-- GO BACK BUTTON -->
                    <button class="kuida-sm-btn whitecoral-btn button-shadow" @click="goToTab('id-tab')">
                        Regresar
                    </button>
                    <v-spacer></v-spacer>
                    <!-- GO NEXT BUTTON -->
                    <button class="kuida-sm-btn aqua-btn button-shadow" @click="goToDoctorProfile">
                        Continuar
                    </button>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import firebase from "firebase";
import router from "@/router";
import {cloud_functions_request} from "@/helpers/cloud_functions_request";

export default {
    name: "Welcome_Component.vue",
    data: () => ({}),
    methods: {
        goToTab(tabName) {
            if (tabName !== '') {
                this.$emit('clickedNext', tabName)
            } else {
                console.log("You can't change tab...");
            }
        },

        async goToDoctorProfile() {
            const doctorEmail = firebase.auth().currentUser.email;
            await firebase.firestore().collection('doctors').doc(doctorEmail).update({
                'status': 'Incomplete',
            });

            let requestObject = {
                'email': doctorEmail,
            };

            await cloud_functions_request('createNewDoctorTelegramNotification', requestObject);

            await router.push('/app/profile')
        },

        async signOut() {
            await firebase.auth().signOut().then(() => {
                console.log('Sign-out successful.')
                router.push('/')
            }).catch((error) => {
                // An error happened.
                console.log(error)
            });
        },
    }
}
</script>

<style scoped>
@import './../../styles/styles.css';

#room-container {
    height: 97vh;
}
</style>
