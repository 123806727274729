import Vue from 'vue';
import App from './App.vue';
import router from '@/router/index';
import vuetify from './plugins/vuetify';
import '@/plugins/firebaseConfig';
import VueGeolocation from "vue-browser-geolocation/src";
import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import firebase from "firebase";
import {StripePlugin} from "@vue-stripe/vue-stripe";
import x5GMaps from 'x5-gmaps';
import { createPinia } from 'pinia'

const pinia = createPinia();

Vue.config.productionTip = false
library.add(fas);
library.add(fab);
library.add(far);
Vue.component('font-awesome-icon', FontAwesomeIcon);

const options = {
    pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    locale: process.env.VUE_APP_LOCALE,
};

Vue.use(StripePlugin, options);

Vue.use(VueVirtualScroller);
Vue.use(VueGeolocation);

Vue.use(x5GMaps, { key: process.env.VUE_APP_GOOGLE_MAPS_KEY, libraries: ['places'] });

Vue.use(pinia);

const {Timestamp} = firebase.firestore
export {Timestamp}

let app;
firebase.auth().onAuthStateChanged(() => {
    if (!app) {
        app = new Vue({
            router,
            vuetify,
            render: h => h(App)
        }).$mount('#app');
    }
})
