import {defineStore} from 'pinia';

export const useAppState = defineStore('appState', {
  state: () => {
    return {
      doctorId: null,
      doctorData: null,
      doctorRoomData: null,
      doctorFormationData: null,
      doctorServicesData: null,
      mainDialogTrigger: false,
      mainDialogTitle:'',
      mainDialogText: '',
    };
  },
  actions: {
    storeDoctorId(email) {
      this.doctorId = email;
    },
    storeDoctorData(document) {
      this.doctorData = document;
    },
    storeDoctorRoomData(document) {
      this.doctorRoomData = document;
    },
    storeDoctorFormationData(documents) {
      this.doctorFormationData = documents;
    },
    storeDoctorServicesData(documents) {
      this.doctorServicesData = documents;
    },
    changeProfileCompletionState(completed) {
      this.doctorData['completeProfile'] = completed;
    },
    storeNewResume(resume) {
      this.doctorData['resume'] = resume;
    },
    storeNewAvatar(url) {
      this.doctorData['profilePicture'] = url;
    },
    addNewRoomInfo(newRoom) {
      this.doctorRoomData = (newRoom);
    },
    addNewFormationInfo(newRoom) {
      this.doctorFormationData = (newRoom);
    },
    changeMainDialogTriggerStatus(display) {
      this.mainDialogTrigger = display;
    },
    changeMainDialogTitle(text) {
      this.mainDialogTitle = text;
    },
    changeMainDialogText(text) {
      this.mainDialogText = text;
    }
  },
});