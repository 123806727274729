<template>
    <v-container style="background-color: #F96767;" fluid fill-height class="ma-0 pl-2">
        <v-tabs vertical background-color="primary" hide-slider v-model='tab' dark>
            <img class="mr-3" :src="require('../assets/Kuida_logo.png')" width="150" alt="kuida"/>

            <v-spacer></v-spacer>

            <v-tab class="step-label" :disabled="this.citizenIdDisabled" href='#id-tab'>
                <span class="material-icons step-icon">looks_one</span>  IDENTIDAD
            </v-tab>

            <v-tab class="step-label" :disabled="this.welcomeDisabled" href='#welcome-tab'>
                <span class="material-icons step-icon">looks_two</span>  PERFIL
            </v-tab>

            <v-spacer></v-spacer>

            <v-tab-item class="primary" value="id-tab">
                <CitizenIdInfoForm_Component v-on:clickedNext="changeTab"></CitizenIdInfoForm_Component>
            </v-tab-item>
            <v-tab-item class="primary" value="welcome-tab">
                <Welcome_Component v-on:clickedNext="changeTab"></Welcome_Component>
            </v-tab-item>

        </v-tabs>
    </v-container>
</template>

<script>
import CitizenIdInfoForm_Component from "@/components/finish_account_steps/CitizenIdInfoForm_Component";
import Welcome_Component from "@/components/finish_account_steps/Welcome_Component";
import "@/plugins/FontAwesome"
import '@/styles/profile/styles.css'

export default {
    name: "DoctorsForm.vue",
    components: {
        CitizenIdInfoForm_Component,
        Welcome_Component,
    },
    data: () => ({
        citizenIdDisabled: false,
        welcomeDisabled: true,
        subscriptionDisabled: true,
        tab: 'id-tab',
    }),
    methods: {
        changeTab(tab) {
            // console.log("Moving to " + tab);

            switch (tab) {
                case 'id-tab':
                    this.citizenIdDisabled = false;
                    this.welcomeDisabled = true;
                    break

                case 'welcome-tab':
                    this.citizenIdDisabled = true;
                    this.welcomeDisabled = false;
                    break
            }

            this.tab = tab
        },
    }
}
</script>

<style scoped>
@import '../styles/styles.css';
@import '../styles/box-shadows.css';

.step-label {
    font-size: large;
    align-self: start;
    text-align: left;
    color: #00bfa5;
}

.step-icon {
    margin-right: 10px;
    font-size: 40px !important;
}
</style>