<template>
    <v-hover v-slot="{ hover }">
        <v-row justify="center" align="center" class="pt-2 mb-n5 px-4" :style="hover ? 'background-color: #f7f7f7;' : 'background-color: white;'">
            <v-col align-self="start" cols="7" class="noSpace">
                <p class="data-label" style="text-align: start;">{{ label }}</p>
            </v-col>
            <v-col align-self="start" cols="5" class="noSpace">
                <p class="data-text" style="text-align: end;">{{ value }}</p>
            </v-col>
        </v-row>
    </v-hover>
</template>

<script>
export default {
    name: "SubscriptionDetail_Text",
    props: {
        label: String,
        value: String,
    }
}
</script>

<style scoped>
.data-label, .data-text {
    font-size: 0.75em;
}

.data-label {
    color: #3ec6c6;
    font-weight: 600;
}

.data-text {
    color: #9F9E9E;
    font-weight: 500;
}
</style>