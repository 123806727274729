<template>
    <v-container class="pt-2 pb-2" fluid>
        <!-- PROFILE HEADER ROW -->
        <v-row align="start" justify="start">
            <!-- PROFILE PICTURE -->
            <v-col align="end" cols="2" justify="center">
                <div class="profile-picture">
                    <v-hover>
                        <template v-slot:default="{ hover }">
                            <v-avatar size="120">
                                <v-img :src="doctorData.profilePicture === '' ? 'https://preview.pixlr.com/images/800wm/100/1/100184686.jpg' : doctorData.profilePicture"/>

                                <v-fade-transition>
                                    <v-overlay v-if="hover" absolute color="primary">
                                        <v-btn
                                                :loading="isSelecting"
                                                color="accent"
                                                fab
                                                rounded
                                                small
                                                @click="openImagesPicker"
                                        >
                                            <font-awesome-icon
                                                    class="fa-2x"
                                                    icon="edit"
                                                    style="color: white;"
                                            ></font-awesome-icon>
                                        </v-btn>

                                        <!-- PROFILE PIC SELECTOR -->
                                        <input
                                                ref="uploader"
                                                accept="image/*"
                                                class="d-none"
                                                type="file"
                                                @change="onFileChanged"
                                        >
                                    </v-overlay>
                                </v-fade-transition>
                            </v-avatar>
                        </template>
                    </v-hover>
                </div>
            </v-col>

            <!-- RESUME -->
            <v-col cols="10">
                <v-row>
                    <!-- MAIN DOCTOR INFO COLUMN -->
                    <v-col cols="8">
                        <h2 class="profile-title coral-text mb-5">{{ name }}</h2>
                        <p class="subtitle mt-n5" style="color: #F96767;">Username: @{{ doctorData.username }}</p>
                        <p class="subtitle mt-n4">Cédula: {{ doctorData.professionalId }}</p>
                        <p class="subtitle mt-n5">Status: {{ createStatusInSpanish(doctorData.status) }}</p>
                    </v-col>

                    <!-- REFERRALS INVITE BUTTON -->
                    <v-col cols="4">
                        <!--                        <v-row align="center" justify="center">
                                                    <p class="button-label mt-6">Gana dinero con Kuida y tus colegas</p>
                                                </v-row>
                                                <v-row align="center" justify="center">
                                                    <button
                                                            class="kuida-sm-btn aqua-btn button-shadow mt-n2"
                                                    >
                                                        Ver detalles
                                                    </button>
                                                </v-row>-->
                    </v-col>
                </v-row>

                <!-- DOCTOR PERSONAL DESCRIPTION -->
                <v-row class="mt-0">
                    <v-col cols="12">
                        <p class="resume-text" style="overflow-wrap: break-word;">
                            <b>Resumen:</b> {{ resume }}
                        </p>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <!-- PROFILE CARDS ROW -->
        <v-row align="center" class="noSpace" justify="center">
            <!-- ROOM INFO CARD -->
            <RoomInfo_Section></RoomInfo_Section>

            <!-- FORMATION CARD -->
            <FormationInfo_Section></FormationInfo_Section>

            <!-- GENERAL INFO CARD -->
            <GeneralInfo_Section :doctor-data="doctorData"></GeneralInfo_Section>

        </v-row>

        <!-- Botones que ejecutan las pruebas de las funciones A11 & A12 -->
        <!-- INTENTAN Y CONFIRMAN PAYMENT INTENT DE A11 -->
        <!-- <v-btn @click="pruebaPayIntent">Intent</v-btn>
        <v-btn @click="confirmaPayIntent">Confirm</v-btn> -->
        <!-- EJECUTA A12 -->
        <!-- <v-btn @click="dailyCharge">Charge</v-btn> -->

    </v-container>
</template>

<script>
import firebase from "firebase";
import RoomInfo_Section from "@/components/profile/sections/RoomInfo_Section";
import FormationInfo_Section from "@/components/profile/sections/FormationInfo_Section";
import GeneralInfo_Section from "@/components/profile/sections/GeneralInfo_Section";
import {useAppState} from "@/store/useAppState";
import {checkDoctorProfileCompletionAfterUpdate} from "@/helpers/validate_completion_and_status";
import {cloud_functions_request} from "@/helpers/cloud_functions_request";

export default {
  name: "DoctorInfo_Component",
  components: {
    RoomInfo_Section,
    FormationInfo_Section,
    GeneralInfo_Section,
  },
  data() {
    return {
      documentRef: null,
      doctorData: {},
      isSelecting: null,
      data: '',
      user: '',
      name: '',
      resume: '',
      index: '',
      foundRoomAddress: false,
      filesPickerTrigger: false,
      profilePictureUrl: '',
      selectedFile: null,
      uploadImages: [],
      images: [],
    }
  },
  async beforeMount() {
    this.user = firebase.auth().currentUser.email;

    await this.getDoctorInfoDocument();
  },
  methods: {
    async getDoctorInfoDocument() {
      let doctorId = this.user;

      await firebase.firestore().collection('doctors').doc(doctorId).onSnapshot((snapshot) => {
        this.doctorData = snapshot.data();

        this.name = this.createFullName();
        this.resume = this.checkForEmptyResume(this.doctorData.resume);
      });
    },

    checkForEmptyResume(resume) {
      if (resume === "" || resume === undefined || resume === null) {
        return "No tienes resumen, edita tu información para que te conozcan mejor quienes te visiten.";
      } else {
        return resume;
      }
    },

    createFullName() {
      return this.doctorData.title + " " +
        this.doctorData.firstName + " " +
        this.doctorData.lastName + " " +
        this.doctorData.secondLastName;
    },

    openImagesPicker() {
      this.filesPickerTrigger = true
      window.addEventListener('focus', () => {
        this.filesPickerTrigger = false
      }, {once: true})

      this.$refs.uploader.click()
    },

    async onFileChanged(e) {
      this.selectedFile = e.target.files[0];

      //WE STORE SELECTED FILES IN ARRAY SO WE CAN USE THEM LATER ON THE UPLOAD
      this.uploadImages.push(this.selectedFile)

      //THIS READS THE IMAGE UPLOADED BY THE USER
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
          this.images.push(fileReader.result)
        }
      )

      fileReader.readAsDataURL(this.selectedFile)

      await this.storeNewProfilePicture();
    },

    async storeNewProfilePicture() {
      let extension = this.uploadImages[0].name.split('.')[1];
      const metadata = {contentType: `image/${extension}`,};

      let storageRef = firebase.storage().ref();
      let imageRef = storageRef.child(`doctors/${this.user}/avatar/${this.uploadImages[0].name}`);

      await imageRef.put(this.uploadImages[0], metadata);
      await imageRef.put(this.uploadImages[0], metadata).then(async () => {
        this.profilePictureUrl = await imageRef.getDownloadURL();
      });

      await firebase.firestore().collection('doctors').doc(this.user).update({
        profilePicture: this.profilePictureUrl,
      });

      // Always store new resume to analyze if profile is complete
      useAppState().storeNewAvatar(this.profilePictureUrl);

      // Validate if doctor can be sent to Review after updating resume
      await checkDoctorProfileCompletionAfterUpdate();

      this.profilePictureUrl = ''
      this.selectedFile = null
      this.uploadImages = []
      this.images = []
    },

    createStatusInSpanish(originalStatus) {
      if(originalStatus === "Incomplete") return "Perfil Incompleto";
      if(originalStatus === "Review") return "Perfil En Revisión";
      if(originalStatus === "Approved") return "Activo";
    },


    // INTENTA A11
    async pruebaPayIntent() {
      let objeto = {
          appointmentId: "lLSYQZCIU8JSfxHhbu67",
          serviceId: "vplyMiW5mZkiSFCy90bU",
          doctorId: "benjamin@kuida.io",
          customerStripeId: "cus_N0uoB8WqnP8DQS",
          email: "elbicho@siu.com",
      };
      await cloud_functions_request('createPaymentIntentForAppointment', objeto);
    },

    // CONFIRMA A11
    async confirmaPayIntent() {
      let objeto = {
          id: "pi_3O2NUiGgGVVDt3sR0jsnG9ZO",
          payment_method: "pm_1Nzi9oGgGVVDt3sRc1Tctemd",
          appointmentId: "lLSYQZCIU8JSfxHhbu67",
          doctor: "benjamin@kuida.io",
      };
      await cloud_functions_request('confirmPaymentIntentForAppointment', objeto);
    },

    // EJECUTA A12
    async dailyCharge() {
        await cloud_functions_request('dailyChargePrueba', {});
    }
  },
}
</script>

<style scoped>
.button-label {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #F96767;
}
</style>