<template>
    <v-app-bar id="hw_navbar" color="#F96767" fixed flat height="56">
        <v-row align="center" class="fill-height mt-n4 mx-0 px-0" justify="center">
            <!-- KUIDA LOGO -->
            <v-img
                :src="require('@/assets/Kuida_logo.png')"
                alt="logo"
                max-width="120"
            ></v-img>

            <!-- AGENDA BUTTON -->
            <router-link class="nav_item" to="/app/agenda">
                <v-btn class="navBar_text" color="white" plain x-large>
                    AGENDA
                </v-btn>
            </router-link>

            <!-- PATIENTS BUTTON -->
            <router-link class="nav_item" to="/app/patients">
                <v-btn class="navBar_text" color="white" plain x-large>
                    PACIENTES
                </v-btn>
            </router-link>

            <!-- APPOINTMENTS BUTTON -->
            <router-link class="nav_item" to="/app/appointments">
                <v-btn class="navBar_text" color="white" plain x-large>
                    CITAS
                </v-btn>
            </router-link>

            <!-- PAYMENTS BUTTON -->
            <router-link class="nav_item" to="/app/payments">
                <v-btn class="navBar_text" color="white" plain x-large>
                    PAGOS
                </v-btn>
            </router-link>

            <!-- FEED BUTTON -->
            <router-link class="nav_item" to="/app/feed">
                <v-btn class="navBar_text" color="white" plain x-large>
                    FEED
                </v-btn>
            </router-link>

            <!-- PROFILE BUTTON -->
            <router-link class="nav_item" to="/app/profile">
                <v-btn class="navBar_text" color="white" plain x-large>
                    PERFIL
                </v-btn>
            </router-link>

            <v-spacer></v-spacer>

            <!-- NOTIFICATIONS CENTER -->
            <!-- <NotificationsCenter_Section></NotificationsCenter_Section> -->
        </v-row>
    </v-app-bar>
</template>

<script>
import firebase from 'firebase';
// import NotificationsCenter_Section from "./NotificationsCenter_Section"

export default {
    name: 'navbar',
    components: {
        // NotificationsCenter_Section,
    },
    data: () => ({
        email: "",
        notificationNumber: 0,
        timesSoundReproduced: 0,
    }),
    async created() {
        //We get the logged user currentUser
        this.email = firebase.auth().currentUser.email;
    },
    methods: {}
};
</script>

<style scoped>
.navBar_text {
    font-size: 15px !important;
}

.nav_item {
    text-decoration: none;
    color: #ffffff;
}
</style>
