<template>
  <BarGraph
    :graph-data="graphData"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar as BarGraph } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";
import firebase from "firebase";
import {cloud_functions_request} from "@/helpers/cloud_functions_request";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  name: "BarChart",
  components: {
    BarGraph,
  },
  props: {
    graphData: {
      type: Object,
    },
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Citas",
            backgroundColor: [
              "rgba(90, 198, 200, 0.2)",
              "rgba(249, 103, 103, 0.2)",
              "rgba(255, 220, 103, 0.2)",
              "rgba(146, 208, 80, 0.2)",
              "rgba(90, 198, 200, 0.2)",
              "rgba(249, 103, 103, 0.2)",
              "rgba(255, 220, 103, 0.2)",
              "rgba(146, 208, 80, 0.2)",
              "rgba(90, 198, 200, 0.2)",
              "rgba(249, 103, 103, 0.2)",
              "rgba(255, 220, 103, 0.2)",
              "rgba(146, 208, 80, 0.2)",
            ],
            borderColor: [
              "rgba(90, 198, 200, 1)",
              "rgba(249, 103, 103, 1)",
              "rgba(255, 220, 103, 1)",
              "rgba(146, 208, 80, 1)",
              "rgba(90, 198, 200, 1)",
              "rgba(249, 103, 103, 1)",
              "rgba(255, 220, 103, 1)",
              "rgba(146, 208, 80, 1)",
              "rgba(90, 198, 200, 1)",
              "rgba(249, 103, 103, 1)",
              "rgba(255, 220, 103, 1)",
              "rgba(146, 208, 80, 1)",
            ],
            borderWidth: 1,
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  async created() {
    try {
      const doctorId = firebase.auth().currentUser.email;
      let response = await cloud_functions_request('appointmentsInfo', { doctorId });
      this.graphData = response.data.graph;
      this.updateChartData();
    } catch (error) {
      console.error("Error fetching appointment data: ", error);
    }
  },
  methods: {
    updateChartData() {
      const monthNames = [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ];

      this.chartData.labels = this.graphData.map(
        (item) => monthNames[item.month - 1]
      );
      this.chartData.datasets[0].data = this.graphData.map(
        (item) => item.appointments
      );
    },
  },
};
</script>
