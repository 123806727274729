<template>
    <!-- NEW APPOINTMENT DIALOG -->
    <v-dialog
        v-model="dialogTrigger"
        overlay-opacity="0.8"
        persistent
    >
        <!-- DIALOG BODY -->
        <v-card class="rounded-xl elevation-0" color="accent">
            <v-container class="px-10 py-8" fluid>
                <!-- HEADER -->
                <v-row align="center" justify="space-between">
                    <!-- TITLE -->
                    <p class="dialogTitle text-uppercase">EDITAR HORARIO DE TRABAJO</p>

                    <!-- CLOSE DIALOG BUTTON -->
                    <v-btn
                        class="mt-n4 mr-n2"
                        @click="closeDialog"
                        color="white"
                        plain
                        small
                        fab
                    >
                        <v-icon large>mdi-close</v-icon>
                    </v-btn>
                </v-row>

                <!-- DIALOG BODY -->
                <v-row align="center" class="px-8" justify="center">
                    <v-col cols="3"></v-col>

                    <v-col align-self="center" cols="6">
                        <!-- SCHEDULE DISPLAY -->
                        <v-row align="center" class="mt-6 mb-6" justify="space-between">
                            <!-- MONDAY INFO -->
                            <v-col align="center" justify="center">
                                <h2 class="day-title">LUN</h2>
                                <p class="hour">{{ this.parsedSchedule['monday'][0] }}</p>
                                <p class="hour">{{ this.parsedSchedule['monday'][1] }}</p>
                                <v-btn color="primary" dark fab small @click="selectHour('monday')">
                                    <v-icon dark medium>
                                        mdi-clock
                                    </v-icon>
                                </v-btn>
                            </v-col>

                            <!-- TUESDAY INFO -->
                            <v-col align-self="center" align="center">
                                <h2 class="day-title">MAR</h2>
                                <p class="hour">{{ this.parsedSchedule['tuesday'][0] }}</p>
                                <p class="hour">{{ this.parsedSchedule['tuesday'][1] }}</p>
                                <v-btn color="primary" dark fab small @click="selectHour('tuesday')">
                                    <v-icon dark medium>
                                        mdi-clock
                                    </v-icon>
                                </v-btn>
                            </v-col>

                            <!-- WEDNESDAY INFO -->
                            <v-col align-self="center" align="center">
                                <h2 class="day-title">MIE</h2>
                                <p class="hour">{{ this.parsedSchedule['wednesday'][0] }}</p>
                                <p class="hour">{{ this.parsedSchedule['wednesday'][1] }}</p>
                                <v-btn color="primary" dark fab small @click="selectHour('wednesday')">
                                    <v-icon dark medium>
                                        mdi-clock
                                    </v-icon>
                                </v-btn>
                            </v-col>

                            <!-- THURSDAY INFO -->
                            <v-col align-self="center" align="center">
                                <h2 class="day-title">JUE</h2>
                                <p class="hour">{{ this.parsedSchedule['thursday'][0] }}</p>
                                <p class="hour">{{ this.parsedSchedule['thursday'][1] }}</p>
                                <v-btn color="primary" dark fab small @click="selectHour('thursday')">
                                    <v-icon dark medium>
                                        mdi-clock
                                    </v-icon>
                                </v-btn>
                            </v-col>

                            <!-- FRIDAY INFO -->
                            <v-col align-self="center" align="center">
                                <h2 class="day-title">VIE</h2>
                                <p class="hour">{{ this.parsedSchedule['friday'][0] }}</p>
                                <p class="hour">{{ this.parsedSchedule['friday'][1] }}</p>
                                <v-btn color="primary" dark fab small @click="selectHour('friday')">
                                    <v-icon dark medium>
                                        mdi-clock
                                    </v-icon>
                                </v-btn>
                            </v-col>

                            <!-- SATURDAY INFO -->
                            <v-col align-self="center" align="center">
                                <h2 class="day-title">SAB</h2>
                                <p class="hour">{{ this.parsedSchedule['saturday'][0] }}</p>
                                <p class="hour">{{ this.parsedSchedule['saturday'][1] }}</p>
                                <v-btn color="primary" dark fab small @click="selectHour('saturday')">
                                    <v-icon dark medium>
                                        mdi-clock
                                    </v-icon>
                                </v-btn>
                            </v-col>

                            <!-- SUNDAY INFO -->
                            <v-col align-self="center" align="center">
                                <h2 class="day-title">DOM</h2>
                                <p class="hour">{{ this.parsedSchedule['sunday'][0] }}</p>
                                <p class="hour">{{ this.parsedSchedule['sunday'][1] }}</p>
                                <v-btn color="primary" dark fab small @click="selectHour('sunday')">
                                    <v-icon dark medium>
                                        mdi-clock
                                    </v-icon>
                                </v-btn>
                            </v-col>

                        </v-row>

                        <!-- SCHEDULE EDITION RULES -->
                        <v-row></v-row>
                    </v-col>

                    <v-col cols="3"></v-col>
                </v-row>

                <!-- SAVE DATA BUTTON -->
                <v-row align="center" class="mt-8 mb-2" justify="center">
                    <button
                        class="kuida-md-btn coral-btn button-shadow mt-2"
                        @click="updateScheduleRequest"
                    >
                        Guardar Horario
                    </button>
                </v-row>

            </v-container>
        </v-card>

        <!-- ALERT MESSAGES BOTTOM SHEET -->
        <SimpleMessage_BottomSheet
            @close-bottom-sheet="bottomSheetTrigger = false;"
            :bottom-sheet-trigger="bottomSheetTrigger"
            :bottom-sheet-message="bottomSheetMessage"
        ></SimpleMessage_BottomSheet>

        <!-- FILES LOADING INDICATOR -->
        <Loading_Dialog
            :loading-dialog-trigger="loadingDialog"
            dialog-message="Espera un momento"
        ></Loading_Dialog>

        <!-- NEW ENTRY AND LEAVING HOURS SELECTION -->
        <v-dialog
            ref="dialog"
            v-model="timeSelectionDialog"
            height="800"
            overlay-color="black"
            overlay-opacity="0.9"
            persistent
            width="850"
        >
            <v-row
                align="center"
                class="mt-10"
                justify="space-around"
            >
                <h1>{{ this.translatedSelectedDay }}</h1>
            </v-row>
            <v-row
                align="center"
                class="mt-4"
                justify="space-around"
            >
                <v-col align="center" justify="center" style="width: 350px;">
                    <h2>Hora de entrada</h2>
                    <v-time-picker
                        v-model="arriveTime"
                        :max="leaveTime"
                    ></v-time-picker>
                </v-col>
                <v-col align="center" justify="center" style="width: 350px;">
                    <h2>Hora de salida</h2>
                    <v-time-picker
                        v-model="leaveTime"
                        :min="arriveTime"
                    ></v-time-picker>
                </v-col>
            </v-row>
            <v-row
                align="end"
                class="mb-4 mt-4"
                justify="end"
            >
                <v-btn
                    color="white"
                    text
                    x-large
                    @click="timeSelectionDialog = false"
                >
                    Regresar
                </v-btn>
                <v-btn
                    color="white"
                    text
                    x-large
                    @click="writeScheduleChange"
                >
                    Aceptar
                </v-btn>
            </v-row>
        </v-dialog>

    </v-dialog>
</template>

<script>
import SimpleMessage_BottomSheet from "@/components/common_widgets/bottom_sheets/SimpleMessage_BottomSheet"
import Loading_Dialog from "@/components/common_widgets/dialogs/Loading_Dialog";
import firebase from "firebase";
import {Timestamp} from "@/main";

export default {
    name: "EditSchedule_DialogForm",
    props: {
        dialogTrigger: Boolean,
    },
    components: {
        SimpleMessage_BottomSheet,
        Loading_Dialog,
    },
    data() {
        return {
            currentUser: '',
            //User schedule data
            schedule: null,
            parsedSchedule: {},
            scheduleToStore: {},
            timeSelectionDialog: false,
            translatedSelectedDay: '',
            arriveTime: null,
            leaveTime: null,

            // Bottom Sheet Variables
            bottomSheetTrigger: false,
            bottomSheetMessage: '',
            loadingDialog: false,
        }
    },
    async beforeMount() {
        this.currentUser = firebase.auth().currentUser.email;

        // We get doctor schedule info for future usage
        await this.getDoctorSchedule();

    },
    methods: {
        async updateScheduleOnFirestore() {
            await firebase.firestore().collection('doctors').doc(this.currentUser).update({
                'schedule': this.scheduleToStore
            });
        },

        async getDoctorSchedule() {
            await firebase.firestore().collection('doctors').doc(this.currentUser).onSnapshot((snapshot) => {
                this.schedule = snapshot.data()['schedule'];

                this.parseDoctorSchedule();
            });
        },

        async updateScheduleRequest() {
            this.loadingDialog = true;

            this.parseScheduleToStore();

            await this.updateScheduleOnFirestore();

            this.loadingDialog = false;

            this.closeDialog();
        },

        translateSelectedDay(day) {
            if (day === 'monday') this.translatedSelectedDay = 'LUNES'
            if (day === 'tuesday') this.translatedSelectedDay = 'MARTES'
            if (day === 'wednesday') this.translatedSelectedDay = 'MIÉRCOLES'
            if (day === 'thursday') this.translatedSelectedDay = 'JUEVES'
            if (day === 'friday') this.translatedSelectedDay = 'VIERNES'
            if (day === 'saturday') this.translatedSelectedDay = 'SÁBADO'
            if (day === 'sunday') this.translatedSelectedDay = 'DOMINGO'
        },

        timestampArrayToDates(day) {
            let result = [];

            result[0] = day[0].toDate().toTimeString().substring(0, 5);
            result[1] = day[1].toDate().toTimeString().substring(0, 5);

            return result;
        },

        parseScheduleToStore() {
            let weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
            let scheduleAsHours = this.parsedSchedule;

            for (let y = 0; y < weekdays.length; y++) {
                let hoursAsTimestamps = [];
                for (let x = 0; x < 2; x++) {
                    let auxDate = new Date(1999, 12, 31, scheduleAsHours[weekdays[y]][x].substring(0, 2), scheduleAsHours[weekdays[y]][x].substring(3, 5), 0);
                    let auxTimestamp = new Timestamp.fromDate(auxDate);

                    hoursAsTimestamps.push(auxTimestamp);
                }
                this.scheduleToStore[weekdays[y]] = hoursAsTimestamps;
            }
        },

        parseDoctorSchedule() {
            let scheduleMap = this.schedule;

            this.parsedSchedule['monday'] = this.timestampArrayToDates(scheduleMap['monday']);
            this.parsedSchedule['tuesday'] = this.timestampArrayToDates(scheduleMap['tuesday']);
            this.parsedSchedule['wednesday'] = this.timestampArrayToDates(scheduleMap['wednesday']);
            this.parsedSchedule['thursday'] = this.timestampArrayToDates(scheduleMap['thursday']);
            this.parsedSchedule['friday'] = this.timestampArrayToDates(scheduleMap['friday']);
            this.parsedSchedule['saturday'] = this.timestampArrayToDates(scheduleMap['saturday']);
            this.parsedSchedule['sunday'] = this.timestampArrayToDates(scheduleMap['sunday']);
        },

        writeScheduleChange() {
            this.parsedSchedule[this.selectedDay] = [this.arriveTime, this.leaveTime];

            this.timeSelectionDialog = false;
        },

        selectHour(day) {
            this.translateSelectedDay(day);
            this.selectedDay = day;
            this.timeSelectionDialog = true;
        },

        closeDialog() {
            // Emit close request
            this.$emit('dialog-close');
        },
    }
}
</script>

<style scoped>
.dialogTitle {
    color: #FFF;
    font-size: 1.5em !important;
    font-weight: bold;
}

.dialogSubtitle {
    color: #FFF;
    font-size: 0.7em !important;
    font-weight: bold;
    text-align: center;
}
</style>
