<template>
    <!--  REVIEW CARD-->
    <v-card class="my-2 mx-5" color="white" elevation="2" rounded="xl">
        <v-container fill-height>
            <v-row align="center" class="my-2 mx-5" justify="start">
                <h2 class="primary--text">Paciente: {{ name }}</h2>
                <v-spacer></v-spacer>
                <v-rating
                    :value="item.rate"
                    color="accent"
                    readonly
                    small
                >
                    <template v-slot:item="props">
                        <v-icon
                            :color="props.isFilled ? 'accent' : '#CCCCCC'"
                            class="mx-n2"
                            v-text="`mdi-star`"
                        ></v-icon>

                    </template>
                </v-rating>
            </v-row>

            <v-row align="center" class="mx-5 mt-n4" justify="start">
                <p class="review_card_title">Fecha: {{ item.date }}</p>
            </v-row>

            <v-row align="center" class="mx-5 mt-2" justify="start">
                <p class="review_card_title">Reseña:</p>
            </v-row>

            <v-row align="start" class="mx-5 mt-n1 mb-2" justify="start">
                <p class="primary&#45;&#45;text" style="font-weight: normal; font-size: 14px; text-align: start;">
                    {{ item.comment }}</p>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import firebase from "firebase";

export default {
    name: "Review_Card",
    props: [
        'review',
    ],
    data() {
        return {
            name: '',
            item: null,
        }
    },
    async created() {
        this.item = this.$props.review;

        await this.getPatientName();
    },
    methods: {
        async getPatientName() {
            await firebase.firestore().collection('users').doc(this.item.patient).get().then((snapshot) => {
                if(snapshot.exists) {
                    let data = snapshot.data();
                    this.name = data.name;
                } else {
                    this.name = "Patient not found";
                }
            });
        },
    }
}
</script>

<style scoped>
.review_card_title {
    color: #F96767;
    font-size: 15px;
}
</style>