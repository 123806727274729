import firebase from "firebase";

export async function changeServicesDisplayState(displayStatus) {
  let email = firebase.auth().currentUser.email;

  await firebase.firestore().collection('doctors').doc(email).collection('services').get().then((querySnapshot) => {
    querySnapshot.docs.forEach(async (documentSnapshot) => {
      await documentSnapshot.ref.update({
        'display': displayStatus,
      });
    });
  });
}