import {useAppState} from "@/store/useAppState";
import firebase from "firebase";

export async function checkDoctorProfileCompletionAfterUpdate() {
  const appState = useAppState();
  const doctorData = appState.doctorData;
  let statusUpdated;

  // console.log("-- DOCTOR DATA --");
  // console.log(doctorData);

  if(doctorData['status'] === "Incomplete" && doctorData['resume'] !== '' && appState.doctorRoomData !== null && appState.doctorFormationData !== null) {
    console.log("Profile is complete, send to review");
    await firebase.firestore().collection('doctors').doc(appState.doctorId).update({
      'status': 'Review',
      'completeProfile': true,
    });
    statusUpdated = true;
  } else {
    console.log("Profile is INCOMPLETE, send back to Incomplete status");
    await firebase.firestore().collection('doctors').doc(appState.doctorId).update({
      'status': 'Incomplete',
      'completeProfile': false,
    });
    statusUpdated = true;
  }

  return statusUpdated;
}