export function formatDateNumbers(number) {
    if (number.toString().length === 1) return '0' + number;
    else return number;
}

export function formatDate(date) {
    if(date !== null) {
        const dateTime = date.toDate();
        let day = this.formatDateNumbers(dateTime.getDate());
        let month = this.formatDateNumbers((dateTime.getMonth() + 1));
        let year = dateTime.getFullYear();
        return day + '/' + month + '/' + year;
    }
}

export function formatDateFromDateType(date) {
    if(date !== null) {
        const dateTime = date;
        let day = this.formatDateNumbers(dateTime.getDate());
        let month = this.formatDateNumbers((dateTime.getMonth() + 1));
        let year = dateTime.getFullYear();
        return day + '/' + month + '/' + year;
    }
}