<template>
    <v-container id="jumbotron-div" v-scroll fill-height fluid
                 style="background-color: #f96767; padding: 0 100px; border: none;">
        <!-- SIGN UP NAV BAR -->
        <v-app-bar app color="primary" dark fixed flat>
            <router-link class="nav_item" to="/">
                <img alt="Kuida" class="mr-3" height="50" src='../assets/Kuida_logo.png'/>
            </router-link>

            <v-spacer></v-spacer>
            <v-toolbar-items>
                <router-link class="nav_item" to="/">
                    <button class="kuida-sm-btn text-button-shadow" style="color: white; margin-top: 10px;">
                        ¿Ya estás registrado?
                    </button>
                </router-link>
            </v-toolbar-items>
        </v-app-bar>

        <!-- SIGN UP BODY -->
        <v-container>
            <h1 style="margin-top: 40px;">CREA TU CUENTA</h1>
            <h2>PLATAFORMA PARA ESPECIALISTAS</h2>

            <v-form v-model="hasUploadFiles">
                <v-layout mt-5>
                    <v-flex justify-center>
                        <v-row align="center" dense justify="center">
                            <v-col cols="12" md="4" sm="6">
                                <!-- FIRST NAME -->
                                <v-text-field
                                    v-model="firstName"
                                    dark
                                    dense
                                    label="Nombre (s)"
                                    outlined
                                ></v-text-field>

                                <!-- LAST NAME -->
                                <v-text-field
                                    v-model="lastName"
                                    dark
                                    dense
                                    label="Apellido Paterno"
                                    outlined
                                ></v-text-field>

                                <!-- SECOND LAST NAME -->
                                <v-text-field
                                    v-model="secondLastName"
                                    dark
                                    dense
                                    label="Apellido Materno"
                                    outlined
                                ></v-text-field>

                                <!-- GENRE -->
                                <v-select
                                    v-model="genre"
                                    :items="genres"
                                    append-icon="mdi-arrow-down"
                                    background-color="coral"
                                    color="white"
                                    dark
                                    dense
                                    item-color="white"
                                    item-text="white"
                                    outlined
                                    placeholder="Sexo"
                                ></v-select>

                                <!-- BIRTH DATE -->
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                        dark min-width="auto" offset-y rounded transition="scale-transition">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="date"
                                            v-bind="attrs"
                                            v-on="on"
                                            append-icon="mdi-calendar"
                                            dark
                                            dense
                                            label="Fecha de nacimiento"
                                            outlined
                                            readonly
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        ref="picker"
                                        v-model="date"
                                        :max="new Date().toISOString().substr(0, 10)"
                                        light
                                        min="1930-01-01"
                                        @change="saveBirthDate"
                                    ></v-date-picker>
                                </v-menu>

                                <!-- TITLE -->
                                <v-select
                                    v-model="title"
                                    :items="titles"
                                    append-icon="mdi-arrow-down"
                                    color="#FFFFFF"
                                    dark
                                    dense
                                    item-color="#FFFFFF"
                                    menu-props='{class:coral}'
                                    outlined
                                    placeholder="Título"
                                ></v-select>

                                <!-- SPECIALITY -->
                                <v-select
                                    v-model="speciality"
                                    :items="especialidades"
                                    append-icon="mdi-arrow-down"
                                    background-color="coral"
                                    color="white"
                                    dark
                                    dense
                                    item-color="white"
                                    item-text="white"
                                    outlined
                                    placeholder="Especialidad"
                                ></v-select>

                                <!-- PROFESSIONAL ID -->
                                <v-text-field
                                    v-model="professionalId"
                                    dark
                                    dense
                                    label="Cédula Profesional"
                                    outlined
                                ></v-text-field>

                                <!--  Alert in case professional ID is already in use  -->
                                <v-alert v-if="!isProIdAvailable" color="grey" dark dense elevation="5" icon="mdi-alert"
                                         rounded>Está cédula ya está en uso.
                                </v-alert>

                                <!-- TAXES ID -->
                                <v-text-field
                                    v-model="taxesId"
                                    :rules="taxesIdRules"
                                    dark
                                    dense
                                    label="RFC"
                                    outlined
                                ></v-text-field>

                                <!-- EMAIL -->
                                <v-text-field
                                    v-model="email"
                                    :rules="emailRules"
                                    dark
                                    dense
                                    label="Correo Electrónico"
                                    outlined
                                ></v-text-field>

                                <!-- PHONE -->
                                <v-text-field
                                    v-model="phone"
                                    :rules="phoneRules"
                                    dark
                                    dense
                                    label="Teléfono"
                                    outlined
                                ></v-text-field>

                                <!-- USERNAME -->
                                <v-text-field
                                    v-model="username"
                                    :rules="usernameRules"
                                    dark
                                    dense
                                    label="Username"
                                    outlined
                                ></v-text-field>

                                <!--  Alert in case inviteUsername is already taken  -->
                                <v-alert v-if="!isUsernameAvailable" color="grey" dark dense elevation="5"
                                         icon="mdi-alert"
                                         rounded>Este username ya está en uso.
                                </v-alert>

                                <!-- PASSWORD -->
                                <v-text-field
                                    v-model="password"
                                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                    :rules="passwordRules"
                                    :type="show ? 'text' : 'password'"
                                    class="form-input dark"
                                    dark
                                    dense
                                    label="Contraseña"
                                    outlined
                                    @click:append="show = !show"
                                ></v-text-field>

                                <!-- CONFIRM PASSWORD -->
                                <v-text-field
                                    v-model="confirmPassword"
                                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :rules="confirmPasswordRules"
                                    :type="show2 ? 'text' : 'password'"
                                    class="form-input dark"
                                    dark
                                    dense
                                    label="Confirmar Contraseña"
                                    outlined
                                    @click:append="show2 = !show2"
                                ></v-text-field>

                                <!-- ALERT FOR REFERRALS -->
                                <v-alert color="accent" dark dense elevation="5" icon="mdi-account-multiple-plus"
                                         rounded>
                                    Ingresa aquí el nombre de usuario (username) de el/la especialista que te invitó a
                                    registrarte en Kuida.
                                </v-alert>

                                <!-- REFERRAL USER -->
                                <v-text-field
                                    v-model="referenceUsername"
                                    dark
                                    dense
                                    label="Usuario de referencia (opcional)"
                                    outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <!-- EMAIL ALREADY IN USE ALERT -->
                        <v-alert
                            v-if="emailAlreadyInUse === true"
                            style="margin-top: 10px; margin-bottom: 40px; width: 100%; text-align: center"
                            type="error"
                        >
                            Este email ya está registrado. Intente otro
                        </v-alert>

                        <!-- PROFESIONAL ID ALREADY IN USE ALERT -->
                        <v-alert
                            v-if="professionalIdIsUsed === true"
                            style="margin-top: 10px; margin-bottom: 40px; width: 100%; text-align: center"
                            type="error"
                        >
                            Esta cédula profesional ya está registrada. Intente otra
                        </v-alert>

                        <!-- ASKING FOR ID -->
                        <v-row align="center" justify="center" style="margin-bottom: 20px;">
                            <h3>Ayúdanos a verificar tu identidad con una de tu credencial de elector<br>(foto de INE,
                                una por frente y otra por detrás)</h3>
                        </v-row>

                        <!-- FILES PICKER -->
                        <v-row align="center" justify="center">
                            <!-- OPEN FILES PICKER BUTTON -->
                            <button
                                :disabled="disabledImageButton"
                                :loading="isSelecting"
                                class="kuida-md-btn whiteaqua-btn button-shadow"
                                type="button"
                                @click="openFilesPicker"
                            >
                                Añadir archivo
                            </button>

                            <!-- FILES PICKER -->
                            <input
                                ref="uploader"
                                accept="image/*"
                                class="d-none"
                                multiple
                                type="file"
                                @change="onFileChanged"
                            >
                        </v-row>

                        <!-- FILES PREVIEW -->
                        <v-container style="margin-top: 30px;">
                            <v-row align="center" justify="center">
                                <v-col v-for="(image, index) in files" :key="index" sm="2">
                                    <v-flex>
                                        <v-card>
                                            <v-img :src="image">
                                                <v-btn icon large style="float: right" @click="deleteFiles(index)">
                                                    <v-icon color="#F96767">mdi-close</v-icon>
                                                </v-btn>
                                            </v-img>
                                        </v-card>
                                    </v-flex>
                                </v-col>
                            </v-row>
                        </v-container>

                        <!-- RULES ACCEPTANCE ALERTS -->
                        <v-col justify="center" style="margin: 10px 0;">
                            <!-- ACCEPTANCE TERMS & CONDITIONS -->
                            <v-row align="center" justify="center" style="margin: -20px 0;">
                                <v-col md="4" sm="4">
                                    <a class="documentationLink" href="https://healthywo.mx/docs/terms.html"
                                       target="_blank"
                                       @click.stop>
                                        <h4>Acepto Términos y Condiciones</h4>
                                    </a>
                                </v-col>

                                <v-col md="1" sm="1">
                                    <v-switch v-model="termsAccepted" color="accent"></v-switch>
                                </v-col>
                            </v-row>

                            <!-- ACCEPTANCE PRIVACY POLICY -->
                            <v-row align="center" justify="center" style="margin: -20px 0;">
                                <v-col md="4" sm="4">
                                    <a class="documentationLink" href="https://healthywo.mx/docs/terms.html"
                                       target="_blank"
                                       @click.stop>
                                        <h4>Acepto Políticas de Privacidad</h4>
                                    </a>
                                </v-col>

                                <v-col md="1" sm="1">
                                    <v-switch v-model="privacyAccepted" color="accent"></v-switch>
                                </v-col>
                            </v-row>

                            <!-- ACCEPTANCE CONDUCT POLICY -->
                            <v-row align="center" justify="center" style="margin: -20px 0;">
                                <v-col md="4" sm="4">
                                    <a class="documentationLink" href="https://healthywo.mx/docs/terms.html"
                                       target="_blank"
                                       @click.stop>
                                        <h4>Acepto Código de Conducta</h4>
                                    </a>
                                </v-col>

                                <v-col md="1" sm="1">
                                    <v-switch v-model="conductCodeAccepted" color="accent"></v-switch>
                                </v-col>
                            </v-row>
                        </v-col>

                        <!-- ALERT MISSING TERMS & CONDITIONS -->
                        <v-row align="center" justify="center">
                            <v-alert v-if="!termsAccepted" color="grey" dark dense elevation="5" icon="mdi-alert"
                                     rounded>
                                Por favor acepte Términos y Condiciones
                            </v-alert>
                        </v-row>

                        <!-- ALERT MISSING PRIVACY POLICY -->
                        <v-row align="center" justify="center">
                            <v-alert v-if="!privacyAccepted" color="grey" dark dense elevation="5" icon="mdi-alert"
                                     rounded>
                                Por favor acepte Políticas de Privacidad
                            </v-alert>
                        </v-row>

                        <!-- ALERT MISSING CONDUCT POLICY -->
                        <v-row align="center" justify="center">
                            <v-alert v-if="!conductCodeAccepted" color="grey" dark dense elevation="5" icon="mdi-alert"
                                     rounded>
                                Por favor acepte Código de Conducta
                            </v-alert>
                        </v-row>

                        <!-- SIGN UP FORM SUBMIT BUTTON -->
                        <v-row align="end" justify="center" style="margin-bottom: 50px; margin-top: 40px;">
                            <button class="kuida-md-btn aqua-btn button-shadow" type="button" @click="signUpRequest">
                                Registrarme
                            </button>
                        </v-row>

                        <!-- BOTTOM SHEET -->
                        <v-bottom-sheet v-model="bottomSheetTrigger">
                            <v-sheet class="text-center" height="200px">
                                <v-btn class="mt-6" color="primary" text @click="closeBottomSheet">
                                    CERRAR
                                </v-btn>
                                <div class="py-3">
                                    {{ bottomSheetMessage }}
                                </div>
                            </v-sheet>
                        </v-bottom-sheet>

                        <!-- FILES LOADING INDICATOR -->
                        <v-dialog v-model="loadingDialog" hide-overlay persistent width="300">
                            <v-card color="primary" dark>
                                <v-card-text style="padding-top: 10px;">
                                    Espera un momento
                                    <v-progress-linear :value="uploadValue" class="mb-0" color="white" indeterminate>
                                    </v-progress-linear>
                                </v-card-text>
                            </v-card>
                        </v-dialog>

                    </v-flex>
                </v-layout>
            </v-form>
        </v-container>
    </v-container>
</template>

<script>
import firebase from 'firebase'
import router from "@/router";
import * as specialities from "@/constants/specialitiesList"
import * as titles from "@/constants/titlesList"

export default {
    name: "SignUp",
    data() {
        return {
            doctorsCollection: firebase.firestore().collection('doctors'),
            passwordRules: [v => !!v || "Se requiere una contraseña"],
            titles: null, //Lista de opciones para título
            genres: ['Femenino', 'Masculino'],
            confirmPasswordRules: [
                value => !!value || 'Se requiere confirmar la contraseña',
                value => value === this.password || 'Las contraseñas no coinciden',
            ],
            bottomSheetTrigger: false,
            bottomSheetMessage: '',
            isUsernameAvailable: true,
            isProIdAvailable: true,
            isEmailAvailable: true,
            isUsingUsedData: true,
            usernameRules: [
                value => !!value || 'Se requiere que ingrese un nombre de usuario.',
                value => value.length >= 6 || 'El nombre de usuario debe contener mínimo 6 caracteres.',
                value => {
                    const pattern = /^([A-Z]|[a-z]|[0-9]|_)*$/
                    return pattern.test(value) || 'El nombre de usuario solo puede contener letras, números o guiones bajos.'
                },
            ],
            taxesIdRules: [
                value => !!value || 'Se requiere que ingrese un RFC.',
                value => 12 <= value.length <= 13 || 'El nombre de usuario debe contener 12-13 caracteres.',
                value => {
                    const pattern = /^([A-Z]|[a-z]|[0-9]|_)*$/
                    return pattern.test(value) || 'El RFC solo puede contener letras y números.'
                },
            ],
            emailRules: [
                v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Introduce un email válido.'
            ],
            phoneRules: [
                value => {
                    const pattern = /\d{10}/
                    return pattern.test(value) || 'Teléfono debe tener únicamente 10 dígitos'
                },
            ],
            phone: null,
            show: false,
            show2: false,
            password: "",
            confirmPassword: "",
            email: "",
            username: "",
            title: '',
            firstName: '',
            lastName: '',
            secondLastName: '',
            taxesId: '',
            speciality: '',
            genre: '',
            professionalId: '',
            selectedFile: null,
            isSelecting: false,
            emailAlreadyInUse: false,
            professionalIdIsUsed: false,
            files: [],
            disabledImageButton: false,
            alertError: false,
            uploadImages: [],
            citizenId: [],
            stripeVerificationImg: [],
            termsAccepted: false,
            privacyAccepted: false,
            conductCodeAccepted: false,
            hasUploadFiles: false,
            loadingDialog: false,
            fileName: '',
            uploadValue: 0,
            date: null,
            formattedDate: null,
            menu: false,
            especialidades: null,
            referenceUsername: '',
            referralEmail: '',
        }
    },
    beforeMount() {
        this.titles = titles.titles;
        this.especialidades = specialities.specialitiesList;
    },
    watch: {
        // Function listening to any update to validate if professionalId is available
        async professionalId(value) {
            let users = await this.doctorsCollection.where('professionalId', '==', value).get();

            this.isProIdAvailable = !(users.docs.length > 0);
        },

        // Function listening to any update to validate if inviteUsername is available
        async username(value) {
            this.username = value.toString().toLowerCase();

            let users = await this.doctorsCollection.where('username', '==', value).get();

            this.isUsernameAvailable = !(users.docs.length > 0);
        },

        // Function listening to any update to validate if currentUser is available
        async email(value) {
            let user = (await this.doctorsCollection.doc(value).get()).exists;

            this.isEmailAvailable = !user;
        },

        taxesId(value) {
            this.taxesId = value.toString().toUpperCase();
        },

        menu(val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },
    },
    methods: {
        async registerInFirestoreAndCreateUserWithEmailAndPassword() {
            // This block will create the user and authenticate it within firebase auth.
            await firebase.auth().createUserWithEmailAndPassword(this.email, this.password);
        },

        async searchForReferralUsername() {
            if (this.referenceUsername !== '') {
                return await firebase.firestore().collection('doctors').where('username', '==', this.referenceUsername).get().then((snapshot) => {
                    if (snapshot.docs.length > 0) {
                        this.referralEmail = snapshot.docs[0].id;
                        return true;
                    } else {
                        return false;
                    }
                });
            } else {
                return true;
            }
        },

        async storeRemainingFields() {
            const genero = this.genre === 'Femenino'; // Female = true; Male = false;

            let defaultGenreAvatar = '';
            if(genero === true) defaultGenreAvatar = 'https://firebasestorage.googleapis.com/v0/b/kuida-storage/o/FEMALE-DOCTOR.png?alt=media&token=7ced6943-dce3-47b8-9ef5-87e80c2dd995'
            else defaultGenreAvatar = 'https://firebasestorage.googleapis.com/v0/b/kuida-storage/o/MALE-DOCTOR.png?alt=media&token=e2f6c096-dc55-4b1e-a26c-93b4c7aad121';

            let nonDoctors = ["Acupuntura", "Fisioterapeuta", "Logopedia", "Naturista", "Nutricionista", "Optometría", "Psicoanalista", "Psicología", "Psicopedagogía", "Quiropráctica"];
            let isDoctor = true; // true = Doctor; false = Licenciado

            const entryHour = new Date(2000, 0, 1, 9,0);
            const leaveHour = new Date(2000, 0, 1, 17,0);
            const defaultSchedule = {
                monday: [entryHour, leaveHour],
                tuesday: [entryHour, leaveHour],
                wednesday: [entryHour, leaveHour],
                thursday: [entryHour, leaveHour],
                friday: [entryHour, leaveHour],
                saturday: [entryHour, leaveHour],
                sunday: [entryHour, leaveHour],
            };

            if (nonDoctors.includes(this.speciality)) {
                isDoctor = false;
            }

            let document = {
                'availableSubscriptionTrial': true,
                'birthDate': this.formattedDate,
                'citizenId': [],
                'completeProfile': false,
                'firstName': this.firstName,
                'genre': genero,
                'invite': this.referralEmail,
                'lastName': this.lastName,
                'lastSingIn': null,
                'messagingToken': [],
                'onService': false,
                'onServiceWait': false,
                'pendingFees': 0,
                'phone': this.phone.toString(),
                'professionalId': this.professionalId,
                'profilePicture': defaultGenreAvatar,
                'registerDate': new Date(),
                'reviewRequestDate': null,
                'rfc': this.taxesId,
                'role': 'doctor', // doctor, salesman or influencer
                'schedule': defaultSchedule,
                'secondLastName': this.secondLastName,
                'speciality': this.speciality,
                'specialityType': isDoctor,
                'status': 'Registered',
                'stripeAccountId': null,
                'stripeCustomerId': null,
                'subscriptionType': null,
                'title': this.title,
                'username': this.username,
            };

            // This line will create an empty doc in firestore with currentUser as id
            await this.doctorsCollection.doc(this.email).set(document, {merge: true}).catch(err => {
                console.log(err)
                this.error = err.message;
            })
        },

        async uploadCitizenIdFile() {
            for (let i = 0; i < 2; i++) {
                const extension = this.uploadImages[i].name.split('.')[1];
                const metadata = {contentType: `image/${extension}`,};
                const storageRef = firebase.storage().ref(`doctors/${this.email + '/citizenID/' + this.uploadImages[i].name}`).put(this.uploadImages[i], metadata);

                storageRef.on(
                    `state_changed`,
                    snapshot => {
                        /*this.uploadValue can help to check the progress, if you want to use it please declare it in data
                        It does not do anything currently,but it is necessary since snapshot should not be empty. Do not erase snapshot nor this.uploadValue*/
                        this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    },
                    error => {
                        console.log(error)
                    },
                    () => {
                        storageRef.snapshot.ref.getDownloadURL().then(async (url) => {
                            //WE STORE THE URLS TO UPLOAD THEM IN FIRESTORE
                            this.citizenId.push(url);

                            if (this.citizenId.length === 2) {
                                await this.doctorsCollection.doc(this.email).update({
                                    'citizenId': this.citizenId,

                                }).catch(err => {
                                    console.log(err)
                                    this.error = err.message;
                                })
                            }
                        });
                    }
                );
            }
        },

        async onFileChanged(e) {
            for (let x = 0; x < e.target.files.length; x++) {
                this.selectedFile = e.target.files[x];

                //WE STORE SELECTED FILES IN ARRAY SO WE CAN USE THEM LATER ON THE UPLOAD
                this.uploadImages.push(this.selectedFile)

                //THIS READS THE IMAGE UPLOADED BY THE USER
                let fileReader = new FileReader()
                fileReader.readAsDataURL(this.selectedFile)

                fileReader.addEventListener('load', () => {
                        this.files.push(fileReader.result)
                    }
                )
            }
        },

        async signUpRequest() {

            if (this.validateEmptyFields()) {

                if (this.isUsernameAvailable === true) {
                    if (this.termsAccepted || this.conductCodeAccepted || this.privacyAccepted) {
                        // console.log("Policies accepted...")

                        if (this.files.length === 2) {
                            // console.log("File picked...")

                            if (this.isEmailAvailable === true && this.isUsingUsedData === true && this.isProIdAvailable) {
                                // console.log("All data is available... :)");

                                if (this.password === this.confirmPassword) {
                                    // console.log("Everything is ok for user creation :)");
                                    this.loadingDialog = true;

                                    if (this.password.length >= 6) {

                                        if (await this.searchForReferralUsername() === true) {
                                            // console.log("Referral inviteUsername found :)");

                                            await this.registerInFirestoreAndCreateUserWithEmailAndPassword().then(async () => {
                                                //THIS WILL UPLOAD THE URLS OF THE IMAGES STORED IN FIREBASE

                                                await this.uploadCitizenIdFile().then(async () => {
                                                    /* This function will store or not the remaining fields from the form and it all depends on
                                                    whether professionalId is currently held by another user or not*/
                                                    await this.storeRemainingFields().then(() => {
                                                        this.loadingDialog = false;
                                                        // console.log("Sign Up successful!");
                                                        router.push('/doctorsForm');
                                                    });
                                                });
                                            });

                                        } else {
                                            // Display too referral inviteUsername not found
                                            this.loadingDialog = false;
                                            this.bottomSheetMessage = 'El nombre de usuario de referencia que introdujiste no existe o está mal escrito, inténtalo de nuevo. Recuerda que solo otro especialista activo en Kuida puede invitarte.';
                                            this.bottomSheetTrigger = true;

                                        }
                                    } else {
                                        // Display too short password alert
                                        this.loadingDialog = false;
                                        this.bottomSheetMessage = 'La contraseña debe tener al menos 6 caracteres.';
                                        this.bottomSheetTrigger = true;

                                    }
                                } else {
                                    // Display mismatching passwords alert
                                    this.loadingDialog = false;
                                    this.bottomSheetMessage = 'Las contraseñas deben coincidir.';
                                    this.bottomSheetTrigger = true;

                                }
                            } else {
                                // Display incomplete form alert
                                this.loadingDialog = false;
                                this.bottomSheetMessage = 'El username, correo y cédula profesional no se pueden repetir dentro de la plataforma';
                                this.bottomSheetTrigger = true;

                            }
                        } else {
                            // Display missing id alert
                            this.loadingDialog = false;
                            this.bottomSheetMessage = 'Favor de añadir tu identificación oficial de manera correcta. Se deben seleccionar 2 imágenes (frente y vuelta).';
                            this.bottomSheetTrigger = true;

                        }
                    } else {
                        // Display missing rules acceptance alert
                        this.loadingDialog = false;
                        this.bottomSheetMessage = 'Favor de aceptar todas nuestras políticas de uso.';
                        this.bottomSheetTrigger = true;
                    }
                } else {
                    // Display unavailable inviteUsername
                    this.loadingDialog = false;
                    this.bottomSheetMessage = 'Favor de introducir un username que no esté en uso';
                    this.bottomSheetTrigger = true;

                }

            } else {
                // Display incomplete form alert
                this.loadingDialog = false;
                this.bottomSheetMessage = 'Favor de concluir el formulario, hay algún campo vacío.';
                this.bottomSheetTrigger = true;

            }
        },

        validateEmptyFields() {
            let allFieldsAreFilled;

            allFieldsAreFilled = this.title !== '' && this.firstName !== '' && this.lastName !== '' && this.secondLastName !== '' && this.username !== '' && this.speciality !== '' &&
                this.professionalId !== '' && this.password !== '' && this.confirmPassword !== '' && this.email !== ''
                && this.genre !== '' && this.taxesId !== '' && this.formattedDate !== null && this.phone !== null;

            return allFieldsAreFilled;
        },

        saveBirthDate(date) {
            this.$refs.menu.save(date)

            this.formattedDate = this.addDays(1);
            // console.log(this.formattedDate.toString());
        },

        deleteFiles(index) {
            this.files.splice(index, 1);
            this.fileName = ''
        },

        closeBottomSheet() {
            this.bottomSheetTrigger = false;
            this.bottomSheetMessage = '';
        },

        openFilesPicker() {
            this.isSelecting = true
            window.addEventListener('focus', () => {
                this.isSelecting = false
            }, {once: true})

            this.$refs.uploader.click()
        },

        addDays(days) {
            let result = new Date(this.date);
            result.setDate(result.getDate() + days);
            return result;
        },
    }
}

</script>

<style scoped>
.documentationLink:hover {
    text-decoration: underline;
}

h4 {
    color: white;
}
</style>
