<template>
    <v-container class="rounded-xl primary white--text font-weight-bold px-6 py-8">
        <v-row
            v-for="(item, index) in title"
            :key="index"
            class="text-center text-lg-start"
        >
            <v-col cols="12" class="text-center text-lg-start">
                <p class="title text-sm-h5 text-md-h5 text-lg-h6 font-weight-bold">{{ item }}</p>
                <v-row align="center" class="justify-center justify-lg-start">
                    <p class="mr-6">
                        <v-icon class="white--text ml-2 mr-2" size="170%">{{ icon[index] }}</v-icon>
                    </p>
                    <p class="text-h3 text-sm-h2 font-weight-bold">{{ dataByIndex(index) }}</p>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "Appointments_Cards",
    props: {
        color: {
            type: String,
            default: "#f96767",
        },
        title: {
            type: Array,
        },
        icon: {
            type: Array,
        },
        data: {
            type: Object,
        },
    },
    methods: {
    dataByIndex(index) {
      const dataKeys = [
        "allTime",
        "thisYear",
        "thisMonth",
        "thisWeek",
        "today",
      ];
      return this.data[dataKeys[index]];
    },
  },
};
</script>

<style>
p {
    font-family: "Montserrat", sans-serif;
}

.title{
    font-size: 20px;
}
</style>