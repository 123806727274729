<template>
  <div class="doctors_home" style="background-color: white;">
    <NavBar></NavBar>
    <router-view></router-view>

    <!-- Missing Data Dialog -->
    <AlertPerfilIncomplete_Dialog
      :dialog-trigger="missingDataAlertTrigger"
      :dialogTitle="missingDataAlertTitle"
      :dialog-message="missingDataAlertText"
      :dialogFields="dialogFieldsMissing"
      @dialog-close="closeIncompleteProfileDialog"
    />

    <!-- Profile Sent to review -->
        <Alert_Dialog
            :dialog-trigger="mainAlertDialogTrigger"
            :dialog-title="mainAlertTitle"
            :dialog-message="mainAlertText"
            @dialog-close="closeIncompleteProfileDialog"
        ></Alert_Dialog>

        <!-- Subscription Dialog Status -->
        <ActionConfirm_Dialog
            :stripe-customer-id="customerId"
            :dialog-trigger="actionConfirmDialogTrigger"
            :dialog-title="actionConfirmTitle"
            :dialog-message="actionConfirmText"
            accept-label="Ver más tarde"
            cancel-label="Revisar suscripción"
            @action-confirm="redirectSubscriptionStatus" 
            @action-cancel="closeActionConfirmDialog"
        ></ActionConfirm_Dialog>
        
        <!-- Pending Fees Dialog -->
        <Alert_Dialog
          :dialog-trigger="pendingFeesAlertTrigger"
          :dialog-title="pendingFeesAlertTitle"
          :dialog-message="pendingFeesAlertText"
          @dialog-close="closePendingFeesDialog"
        ></Alert_Dialog>

    </div>
</template>

<script>
import NavBar from "@/components/navbar/NavBar";
import { useAppState } from "@/store/useAppState";
import firebase from "firebase";
import Alert_Dialog from "@/components/common_widgets/dialogs/Alert_Dialog.vue";
import AlertPerfilIncomplete_Dialog from "@/components/common_widgets/dialogs/AlertPerfilIncomplete_Dialog.vue";
import ActionConfirm_Dialog from "@/components/common_widgets/dialogs/ActionConfirm_Dialog.vue";
import {cloud_functions_request} from "@/helpers/cloud_functions_request";

export default {
  name: "DoctorsHome",
  components: {
    Alert_Dialog,
    NavBar,
    AlertPerfilIncomplete_Dialog,
    ActionConfirm_Dialog,
  },
  props: {
    subscriptionsList: Array,
  },
  data() {
    return {
      missingDataAlertTrigger: false,
      missingDataAlertTitle: "",
      missingDataAlertText: "",
      dialogFieldsMissing: [],
      pendingFeesAlertTrigger: false,
      pendingFeesAlertTitle: "",
      pendingFeesAlertText: "",
      actionConfirmDialogTrigger: false,
      actionConfirmTitle: '',
      actionConfirmText: '',
      userId: '',
      customerId: '',
      status: '',
      latestSubscription: null,
      subscriptionStatus: null,
      trialDaysAvailability: null,
    };
  },
  computed: {
    mainAlertDialogTrigger() {
      return useAppState().mainDialogTrigger;
    },
    mainAlertTitle() {
      return useAppState().mainDialogTitle;
    },
    mainAlertText() {
      return useAppState().mainDialogText;
    },
  },
  async beforeMount() {
    const appState = useAppState();
    // Get whole doctor data
    await this.getDoctorDataAndStoreIt(appState);
    await this.getDoctorRoomDataAndStoreIt(appState);
    await this.getDoctorFormationDataAndStoreIt(appState);
    await this.getDoctorServicesDataAndStoreIt(appState);
    await this.getDoctorStripeData();
    await this.validateProfileSubscription();
    // Validate if doctor profile is completed
    await this.validateIfProfileIsComplete(appState);
    const email = firebase.auth().currentUser.email;
    const doctorDocument = await firebase.firestore().collection('doctors').doc(email).get()
    const pendingFees = doctorDocument.data().pendingFees
    if (pendingFees >= 250) {
      this.pendingFeesAlertTitle = "Acción necesaria";
      this.pendingFeesAlertText =
        "Haz rebasado los $250 MXN de comisiones pendientes por cobrar, tu cuenta está en pausa hasta que podamos realizar este cobro. Puedes actualizar tu método de pago para que intentemos cobrar esta comisión pendiente. \n";
      this.pendingFeesAlertTrigger = true;
    }
  },
  methods: {
    async updateDoctorsProfileCompletion(appState) {
      const doctorData = appState.doctorData;
      // Update doctor data to store that profile has the minimum requirements.
      if (doctorData["completeProfile"] !== true) {
        // Update data on DB
        await firebase
          .firestore()
          .collection("doctors")
          .doc(appState.doctorId)
          .update({
            completeProfile: true,
          });
        // Update data locally
        appState.changeProfileCompletionState(true);
      }
    },

    async getDoctorFormationDataAndStoreIt(appState) {
      const email = firebase.auth().currentUser.email;
      const doctorFormation = await firebase
        .firestore()
        .collection("doctors")
        .doc(email)
        .collection("education")
        .orderBy("graduationDate", "desc")
        .get();

      if (doctorFormation.size >= 1) {
        appState.storeDoctorFormationData(doctorFormation.docs);
      }
    },

    async getDoctorServicesDataAndStoreIt(appState) {
      const email = firebase.auth().currentUser.email;
      const doctorServices = await firebase
        .firestore()
        .collection("doctors")
        .doc(email)
        .collection("services")
        .orderBy("addedSince", "desc")
        .get();

      if (doctorServices.size >= 1) {
        appState.storeDoctorServicesData(doctorServices);
      }
    },

    async getDoctorRoomDataAndStoreIt(appState) {
      const email = firebase.auth().currentUser.email;
      const doctorRoom = await firebase
        .firestore()
        .collection("doctors")
        .doc(email)
        .collection("room")
        .orderBy("creationDate", "desc")
        .limit(1)
        .get();

      if (doctorRoom.size === 1) {
        appState.storeDoctorRoomData(doctorRoom);
      }
    },

    async getDoctorDataAndStoreIt(appState) {
      const email = firebase.auth().currentUser.email;
      const doctorDocument = await firebase
        .firestore()
        .collection("doctors")
        .doc(email)
        .get();

      appState.storeDoctorId(email);
      appState.storeDoctorData(doctorDocument.data());
    },

    async validateIfProfileIsComplete(appState) {
        // console.log("Checking for complete profile");
      const doctorData = appState.doctorData;
      const completeProfile = doctorData["completeProfile"];

      // 0. Check whether profile is incomplete or not
      if (!completeProfile) {
        const profilePicture = doctorData["profilePicture"];
        const resume = doctorData["resume"];

        this.missingDataAlertTitle = "Acción necesaria";
        this.missingDataAlertText =
          "Para que tu perfil pueda ser revisado es necesario que nos compartas la siguiente información general: \n";

        // 1. Check if there is missing avatar and resume
        // Display missing profile picture
        if (profilePicture === "") {
          this.dialogFieldsMissing.push({
            section:
              "\n\n Actualiza esta información desde tu Perfil en la pestaña de Información. \n",
            field: "\n - Foto de perfil.",
          });
        }

        // Display missing resume
        if (resume === "" || resume === undefined) {
          this.dialogFieldsMissing.push({
            section:
              '\n\n Actualiza esta información desde tu Perfil en la pestaña de "Información" haciendo click en el botón de editar junto a "Información General". \n',
            field: "\n - Resumen de tu trayectoria.",
          });
        }

        // 2. Check if there is room info
        if (appState.doctorRoomData === null) {
          this.dialogFieldsMissing.push({
            section: '\n\n Actualiza esta información desde tu Perfil en la pestaña de "Información" haciendo click en el botón de editar junto a "Información de Consultorio". \n',
            field: "\n - Información de tu consultorio.",
          });
        }

        // 3. Check if there is formation info
        if (appState.doctorFormationData === null) {
          this.dialogFieldsMissing.push({
            section:
              '\n\n Actualiza esta información desde tu Perfil en la pestaña de "Información" haciendo click en el botón de añadir junto a "Formación Académica". \n',
            field: "\n - Información académica.",
          });
        }

        // 4. Check if there is registered services
        if (appState.doctorServicesData === null) {
          this.dialogFieldsMissing.push({
            section: '\n Esta información es opcional pero te recomendamos completarla desde tu Perfil en la pestaña de "Servicios". \n',
            field: "\n - Añadir servicios.",
          });
        }
        // Open missing data alert
        this.missingDataAlertTrigger = true;
      }
    },

    // Select
    selectLatestSubscription() {
      // Select latest subscription from list
      this.latestSubscription = this.subscriptionsList[0];
    },

    // Auth
    async getDoctorStripeData() {
      this.userId = firebase.auth().currentUser.email;
      let doctorData = await firebase.firestore().collection('doctors').doc(this.userId).get().then((snapshot) => {
        return snapshot.data();
      });
      this.trialDaysAvailability = doctorData['availableSubscriptionTrial'];
      this.customerId = doctorData['stripeCustomerId'];
    },

    // Validate Subscription Status Profile
    async validateProfileSubscription() {
      
      this.clearSubscriptionData();
      
      let requestObject = {
        'customerId': this.customerId,
      };
      
      let responses = await cloud_functions_request('getDoctorsSubscriptions', requestObject);
      
      this.subscriptionsList = responses.data.sort((a, b) => {
        return b['start_date'] - a['start_date'];
      });

      // false in case there is no subscription data
      this.subscriptionStatus = this.subscriptionsList.length > 0;

      if (this.subscriptionStatus !== false) {
        let status = responses.data.data[0].status;

        // Depending on the state within the 'status', display the corresponding text based on the found status.
        switch (status) {
          // status === 'active'
          case 'active':
            this.actionConfirmDialogTrigger = false;
            return;

          case 'trialing':
            this.actionConfirmDialogTrigger = false;
            return;

            // TODO Display something on different status for subscription
          // status === 'past_due'
          /*case 'past_due':
            this.actionConfirmTitle = "Subscripción status PAST_DUE";
            this.actionConfirmText = "Texto para estatus PAST_DUE";
            this.actionConfirmDialogTrigger = true;
            return;

          // status === 'unpaid'
          case 'unpaid':
            this.actionConfirmTitle = "Subscripción status UNPAID";
            this.actionConfirmText = "Texto para estatus UNPAID";
            this.actionConfirmDialogTrigger = true;
            return;

          // status === 'canceled'
          case 'canceled':
            this.actionConfirmTitle = "Subscripción status CANCELED";
            this.actionConfirmText = "Texto para estatus CANCELED";
            this.actionConfirmDialogTrigger = true;
            return;

          // status === 'incomplete'
          case 'incomplete':
            this.actionConfirmTitle = "Subscripción status INCOMPLETE";
            this.actionConfirmText = "Texto para estatus INCOMPLETE";
            this.actionConfirmDialogTrigger = true;
            return;

          // status === 'incomplete_expired'
          case 'incomplete_expired':
            this.actionConfirmTitle = "Subscripción status INCOMPLETE_EXPIRED";
            this.actionConfirmText = "Texto para estatus INCOMPLETE_EXPIRED";
            this.actionConfirmDialogTrigger = true;
            return;

          // status === 'ended'
          case 'ended':
            this.actionConfirmTitle = "Subscripción status ENDED";
            this.actionConfirmText = "Texto para estatus ENDED";
            this.actionConfirmDialogTrigger = true;
            return;*/

          // status === null (Have a subscription plan without any status)
          default:
            this.actionConfirmTitle = "Error al obtener información";
            this.actionConfirmText = "A ocurrido un error al obtener información respecto a tu suscripción, te recomendamos hacer refresh de esta pestaña.";
            this.actionConfirmDialogTrigger = false;
            return;
        }
      }else{  
        // NO subscription, NO Status found
        this.actionConfirmTitle = "Subscríbete";
        this.actionConfirmText = "Aún no tienes ninguna subscripción activa en Kuida, recuerda que para disfrutar todos nuestros beneficios debes estar suscrito. Puedes administrar tu suscripción desde tu perfil en cualquier momento."
        this.actionConfirmDialogTrigger = true;
        return;
      }
    },

    closeActionConfirmDialog() {
      this.actionConfirmDialogTrigger = false;
    },

    redirectSubscriptionStatus() {
      this.$router.push('/app/profile');
      this.actionConfirmDialogTrigger = false;
      
    },

    clearSubscriptionData() {
      this.subscriptionsList = [];
      this.subscriptionStatus = null;
    },

    closeIncompleteProfileDialog() {
      this.missingDataAlertTrigger = !this.missingDataAlertText;
    },

    closePendingFeesDialog(){
      this.pendingFeesAlertTrigger = !this.pendingFeesAlertText;
    }
  },
};
</script>
