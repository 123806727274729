<template>
    <v-app>
        <router-view></router-view>
    </v-app>
</template>

<script>
import firebase from "firebase";
import router from "@/router";
import {cloud_functions_request} from "@/helpers/cloud_functions_request";
import {changeServicesDisplayState} from "@/helpers/change_services_state";

export default {
    name: 'App',
    data() {
        return {
            userId: null,
            userStatus: null,
            customerId: null,
            subscriptionsList: [],
            subscriptionStatus: null,
        }
    },
    async beforeMount() {
        this.userId = firebase.auth().currentUser === null ? null : firebase.auth().currentUser.email;

        if (this.userId !== null) {
            await this.getStatus();

            // TODO Create Subscription Status Provider
            await this.getSubscriptionStatusFromStripe();

            if (this.userStatus === 'Registered') {
                await router.push('/doctorsForm');
            }
        } else {
            console.log("No current session");
        }
    },
    methods: {
        async getStatus() {
            return await firebase.firestore().collection('doctors').doc(this.userId).get().then((snapshot) => {
                let info = snapshot.data()

                this.userStatus = info['status'];
                this.customerId = info['stripeCustomerId'];
            });
        },

        async getSubscriptionStatusFromStripe() {
            let requestObject = {
                'customerId': this.customerId,
            }

            let response = await cloud_functions_request('getDoctorsSubscriptions', requestObject);

            this.subscriptionsList = response.data;

            this.subscriptionStatus = this.subscriptionsList.length > 0;

            if(this.subscriptionStatus === false) {
              await changeServicesDisplayState(false);
            }
        },
    }
};
</script>
