<template>
    <v-col cols="12" class="pa-0">
        <v-card
            class="rounded-xl mx-1"
            color="#5ac6c8"
            elevation="5"
            height="30vh"
        >
            <v-container class="fill-height">
                <v-col align="center" align-self="center" class="px-8">

                    <!-- CIRCULAR PROGRESS INDICATOR WHILE RETRIEVE BANK ACCOUNT DATA -->
                    <v-container
                        v-if="bankAccountData === null"
                        style="height: 10vh;"
                        fluid
                    >
                        <v-row align="center" justify="center">
                            <v-progress-circular class="mt-5" color="white" indeterminate></v-progress-circular>
                        </v-row>
                    </v-container>

                    <!-- NO BANK ACCOUNT DATA LABEL -->
                    <v-row v-if="bankAccountData !== null && bankAccountData.length === 0" align="center" justify="start">
                        <p
                            class="my-3 bank-info"
                            style="text-align: center;"
                        >
                            Para recibir tus ganancias generadas con Kuida registra una cuenta bancaria activa para que
                            te depositemos en esta.
                        </p>
                    </v-row>

                    <!-- BANK ACCOUNT OWNER -->
                    <v-row v-if="bankAccountData !== null && bankAccountData.length > 0" align="center" justify="start">
                        <p class="mb-0 bank-info  text-md-body-1 text-sm-body-2">Nombre de cuenta habiente: {{ accountOwnerName }}</p>
                    </v-row>

                    <!-- BANK ACCOUNT NUMBER -->
                    <v-row v-if="bankAccountData !== null && bankAccountData.length > 0" align="center" justify="start">
                        <p class="mb-0 bank-info  text-md-body-1 text-sm-body-2">CLABE: {{ accountNumber }}</p>
                    </v-row>

                    <!-- BANK ACCOUNT ENTITY -->
                    <v-row v-if="bankAccountData !== null && bankAccountData.length > 0" align="center" justify="start">
                        <p class="mb-0 bank-info  text-md-body-1 text-sm-body-2">Banco: {{ accountBank }}</p>
                    </v-row>

                    <v-row class="mt-10 mx-n6" justify="end">
                        <!-- UPDATE BANK ACCOUNT BUTTON -->
                        <button style="margin-top: 50px;"
                            class="kuida-sm-btn whiteaqua-btn button-shadows text-md-body-1 text-sm-body-2"
                            @click="openBankAccountUpdateDialog"
                        >
                            Actualizar Cuenta Bancaria
                        </button>

                        <!-- REQUEST PAYOUT BUTTON -->
                        <!-- <button
                            class="kuida-sm-btn coral-btn button-shadow"
                            @click="openPayoutRequestDialog"
                        >
                            Reclamar Depósito
                        </button> -->
                    </v-row>
                </v-col>
            </v-container>
        </v-card>

        <!-- BANK ACCOUNT UPDATE DIALOG -->
        <BankAccountUpdate_DialogForm
            :stripe-account-id="stripeAccountId"
            :dialog-trigger="updateBankAccountDialogTrigger"
            @updated-account-successfully="getBankAccountInfo"
            @dialog-close="updateBankAccountDialogTrigger = false"
        ></BankAccountUpdate_DialogForm>

        <!-- MONEY REQUEST CONFIRM DIALOG -->
        <!-- <ActionConfirm_Dialog
            :dialog-message="'¿Estás seguro que quieres transferir tu saldo Kuida disponible a tu cuenta bancaria? Tu depósito será de ' + this.availableMoney + ' MXN.'"
            :dialog-trigger="moneyRequestConfirmDialogTrigger"
            accept-label="Transferir"
            cancel-label="Cancelar"
            dialog-title="ATENCIÓN"
            @action-cancel="moneyRequestConfirmDialogTrigger = false"
            @action-confirm="stripeMoneyPayoutRequest"
        ></ActionConfirm_Dialog> -->

        <!-- LOADING DIALOG -->
        <Loading_Dialog
            :loading-dialog-trigger="loadingDialogTrigger"
            dialog-message="Espera un momento"
        ></Loading_Dialog>

        <!-- SIMPLE MESSAGE BOTTOM SHEET -->
        <SimpleMessage_BottomSheet
            :bottom-sheet-message="bottomSheetMessage"
            :bottom-sheet-trigger="bottomSheetTrigger"
            @close-bottom-sheet="bottomSheetTrigger = false;"
        ></SimpleMessage_BottomSheet>
    </v-col>
</template>

<script>
import SimpleMessage_BottomSheet from '@/components/common_widgets/bottom_sheets/SimpleMessage_BottomSheet';
import BankAccountUpdate_DialogForm from '@/components/appointments/dialog_forms/BankAccountUpdate_DialogForm';
// import ActionConfirm_Dialog from "@/components/common_widgets/dialogs/ActionConfirm_Dialog";
import Loading_Dialog from '@/components/common_widgets/dialogs/Loading_Dialog';
import firebase from "firebase";
import {cloud_functions_request} from "@/helpers/cloud_functions_request";

export default {
    name: "BankInfo_Section",
    components: {
        BankAccountUpdate_DialogForm,
        SimpleMessage_BottomSheet,
        // ActionConfirm_Dialog,
        Loading_Dialog
    },
    props: [
        'availableAmount',
        'availableMoney',
        'cols'
    ],
    data() {
        return {
            moneyRequestConfirmDialogTrigger: false,
            updateBankAccountDialogTrigger: false,
            loadingDialogTrigger: false,
            bottomSheetTrigger: false,
            bottomSheetMessage: '',
            bankAccountData: null,
            stripeAccountId: '',
            accountOwnerName: '',
            accountNumber: '',
            accountBank: '',
            currentUser: '',
        }
    },
    async mounted() {
        this.currentUser = firebase.auth().currentUser.email;

        await this.getStripeAccountId();

        await this.getBankAccountInfo();
    },
    methods: {
        /* async stripeMoneyPayoutRequest() {
            this.moneyRequestConfirmDialogTrigger = false

            // 1. CHECK IF THERES MINIMUM $100.00 AVAILABLE KUIDA BALANCE
            if (this.availableAmount > 100) {
                try {
                    this.loadingDialogTrigger = true;

                    // 2. SEND DOCTOR ID SO SERVER GETS SECURE DATA DIRECTLY FROM FIREBASE
                    let body = {
                        doctorId: this.currentUser
                    };

                    // 3. TRANSFER BALANCE FROM KUIDA STRIPE ACCOUNT TO DOCTOR STRIPE CONNECTED ACCOUNT
                    let transfer = await cloud_functions_request('transferDoctorBalance', body);

                    if (transfer['transferId'] !== null) {
                        // 4. PAYOUT AVAILABLE BALANCE AT DOCTOR'S STRIPE CONNECTED ACCOUNT
                        let payout = await cloud_functions_request('doctorPayoutRequest', body);

                        if (payout['payoutId'] !== null) {
                            this.loadingDialogTrigger = false;

                            // 5. DISPLAY SUCCESSFUL PAYOUT
                            this.bottomSheetTrigger = true;
                            this.bottomSheetMessage = 'Se ha realizado exitosamente la transferencia a tu cuenta bancaria. Esta debe reflejarse en tu estado de cuenta en un lapso máximo de 1 día hábil.';
                        }
                    }

                } catch (e) {
                    this.loadingDialogTrigger = false;

                    this.bottomSheetTrigger = true;
                    this.bottomSheetMessage = 'Ha ocurrido un error al intentar realizar la transferencia, intentalo más tarde.'
                }

            } else {
                this.loadingDialogTrigger = false;

                this.bottomSheetTrigger = true;
                this.bottomSheetMessage = 'Necesitas contar con un monto disponible para retiro mínimo de $100.00 MXN para poder realizar una transferencia a tu cuenta bancaria.'
            }
        }, */

        async getStripeAccountId() {
            this.stripeAccountId = await firebase.firestore().collection('doctors').doc(this.currentUser).get().then((snapshot) => {
                return snapshot.data().stripeAccountId;
            });
        },

        async getBankAccountInfo() {
            // 0. Clear any previous bank account data
            this.bankAccountData = null;

            // 1. Set Up request body
            let body = {
                stripeId: this.stripeAccountId,
            }

            // 2. Request Doctor's Bank Account
            let response = await cloud_functions_request('getDoctorBankInfo', body);

            // 3. Assign retrieved data into local variables.
            this.bankAccountData = response.data['bankData'];

            if(this.bankAccountData.length > 0) {
                this.accountOwnerName = this.bankAccountData[0]['account_holder_name'];
                this.accountNumber = '**************' + this.bankAccountData[0]['last4'];
                this.accountBank = this.bankAccountData[0]['bank_name'];
            }

        },

        openBankAccountUpdateDialog() {
            this.updateBankAccountDialogTrigger = true;
        },

        /* openPayoutRequestDialog() {
            this.moneyRequestConfirmDialogTrigger = true;
        }, */
    }
}
</script>

<style scoped>
.bank-info {
    color: white;
    font-size: 15px;
    font-weight: 500;
}
</style>