<template>
    <v-row>
        <v-container>
            <!-- add button -->
            <v-btn
                fab
                color="primary"
                dark
                medium
                fixed
                bottom
                right
                class="add"
                @click="createNote"
            >
                <v-icon size="40px">mdi-plus</v-icon>
            </v-btn>

            <CreateNote_Dialog :dialog="dialog" :patientId="patientId" :patientName="patientName" @childToParent="createNote" @modal="createNote"> </CreateNote_Dialog>


        </v-container>


            <!-- EMPTY TITLE BOTTOM SHEET -->
        <v-bottom-sheet v-model="noPatient">
            <v-sheet class="text-center" height="200px">
                <v-btn class="mt-6" color="primary" text @click="noPatient = false">
                    CERRAR
                </v-btn>
                <div class="py-3">
                    Favor selecciona un paciente para poder generar una nota en su historial médico.
                </div>
            </v-sheet>
        </v-bottom-sheet>
    </v-row>
</template>

<script>
import firebase from "firebase";
import CreateNote_Dialog from "@/components/common_widgets/dialogs/CreateNote_Dialog";
export default {
    name: "CreateNote_Button.vue",
    components: {
        CreateNote_Dialog
    },
    async created() {
        //We get the logged user currentUser
        this.email = this.getLoggedInUserEmail();
    },
    props:{
        patientName: String,
        patientId: String,
        lastAppointment: String,
    },
    data: () =>({
        dialog: false,
        isValid: true,
        noPatient: false
    }),
    methods: {
        createNote() {
            if(this.patientName !== '') {
                this.dialog = !this.dialog
            } else {
                this.noPatient = true;
            }
        },

        getLoggedInUserEmail(){
            return firebase.auth().currentUser.email;
        },

    },
}
</script>

<style scoped>
.add{
    border-radius: 50%;
}
.note .error--text{
    color: #f96767 !important;
    caret-color: #f96767 !important;
}
</style>

