<template>
    <!-- EDIT ROOM INFO TRIGGER -->
    <v-dialog
            v-model="openAddFormationDialog"
            overlay-opacity="0.8"
            persistent
    >
        <!-- ADD BUTTON -->
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    icon
            >
                <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
        </template>

        <!-- ADD DIALOG -->
        <v-card class="rounded-xl elevation-0" color="accent">
            <v-container class="px-10 py-8" fluid>
                <!-- HEADER -->
                <v-row align="center" justify="space-between">
                    <!-- TITLE -->
                    <p class="dialogTitle text-uppercase">Agregar Información Académica</p>

                    <!-- CLOSE DIALOG BUTTON -->
                    <v-btn
                            class="mt-n4 mr-n2"
                            color="white"
                            fab
                            plain
                            small
                            @click="openAddFormationDialog = false"
                    >
                        <v-icon large>mdi-close</v-icon>
                    </v-btn>
                </v-row>

                <!-- DIALOG BODY -->
                <v-row align="center" class="px-8" justify="center">
                    <v-col cols="3"></v-col>
                    <v-col align-self="center" cols="6">
                        <!-- TITLE TEXT FIELD -->
                        <v-text-field
                                ref="title"
                                v-model="title"
                                :rules="[rules.required]"
                                color="white"
                                dark
                                dense
                                label="Título"
                                outlined
                        ></v-text-field>

                        <!-- PROFESSIONAL ID TEXT FIELD -->
                        <v-text-field
                                ref="professionalId"
                                v-model="professionalId"
                                :rules="[rules.required]"
                                color="white"
                                dark
                                dense
                                label="Cédula Profesional"
                                outlined
                        ></v-text-field>

                        <!-- SPECIALITY SELECTOR -->
                        <v-select
                                ref="speciality"
                                v-model="speciality"
                                :items="specialities"
                                :rules="[rules.required]"
                                append-icon="mdi-arrow-down"
                                background-color="coral"
                                color="white"
                                dark
                                dense
                                item-color="white"
                                item-text="white"
                                outlined
                                placeholder="Especialidad"
                        ></v-select>

                        <!-- SCHOOL TEXT FIELD -->
                        <v-text-field
                                ref="school"
                                v-model="school"
                                :rules="[rules.required]"
                                color="white"
                                dark
                                dense
                                label="Escuela"
                                outlined
                        ></v-text-field>

                        <!-- GRADUATION DATE -->
                        <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :rules="[rules.required]"
                                dark min-width="auto"
                                offset-y
                                rounded
                                transition="scale-transition"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        ref="graduationDate"
                                        v-model="graduationDate"
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[rules.required]"
                                        append-icon="mdi-calendar"
                                        dark
                                        dense
                                        label="Fecha aproximada de graduación"
                                        outlined
                                        readonly
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                    ref="picker"
                                    v-model="graduationDate"
                                    :max="new Date().toISOString().substr(0, 10)"
                                    light
                                    min="1930-01-01"
                                    @change="saveGraduationDate"
                            ></v-date-picker>
                        </v-menu>

                    </v-col>
                    <v-col cols="3"></v-col>
                </v-row>

                <!-- SAVE DATA BUTTON -->
                <v-row align="center" class="mt-0 mb-2" justify="center">
                    <button
                            class="kuida-md-btn coral-btn button-shadow mt-8"
                            type="button"
                            @click="saveFormationInfoRequest"
                    >
                        Guardar
                    </button>
                </v-row>

            </v-container>
        </v-card>

        <!-- INVALID FORM DIALOG -->
        <v-dialog
                v-model="invalidForm"
                max-width="500px"
                overlay-opacity="0.8"
                persistent
        >
            <v-card class="pa-16" color="white">
                <v-row align="center" justify="center">
                    <v-col align-self="center">
                        <h1 class="primary--text mt-n8">ERROR</h1>

                        <p style="text-align: center;">Debes llenar todos los campos.</p>

                        <v-row align="center" class="mt-8" justify="center">
                            <button
                                    class="kuida-md-btn coral-btn"
                                    @click="invalidForm = false"
                            >Cerrar
                            </button>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>

    </v-dialog>
</template>

<script>
import firebase from "firebase";
import * as specialities from "@/constants/specialitiesList"
import {useAppState} from "@/store/useAppState";
import {checkDoctorProfileCompletionAfterUpdate} from "@/helpers/validate_completion_and_status";

export default {
  name: "UpdateRoomInfo_Form",
  data() {
    return {
      user: '',
      menu: null,
      title: null,
      professionalId: null,
      school: null,
      speciality: null,
      invalidForm: false,
      specialities: null,
      selectedDate: null,
      formattedDate: null,
      graduationDate: null,
      formHasErrors: false,
      openAddFormationDialog: false,
      rules: {
        required: value => !!value || 'Este campo es obligatorio.',
      },
    }
  },
  computed: {
    form() {
      return {
        title: this.title,
        school: this.school,
        speciality: this.speciality,
        graduationDate: this.graduationDate,
        professionalId: this.professionalId,
      }
    },
  },
  beforeMount() {
    this.user = firebase.auth().currentUser.email;

    this.specialities = specialities.specialitiesList;
  },
  methods: {
    async saveFormationInfoRequest() {
      this.formValidation();

      if (this.invalidForm === false) {
        let formationObject = {
          title: this.title,
          professionalId: this.professionalId,
          school: this.school,
          speciality: this.speciality,
          graduationDate: this.selectedDate,
        }

        const stored = await this.saveFormationInfoToFirestore(formationObject);

        // Always store new Room info to analyze if profile is complete
        useAppState().addNewFormationInfo(stored);

        // Validate if doctor can be sent to Review after updating resume
        await checkDoctorProfileCompletionAfterUpdate();

        this.clearInput();

        this.openAddFormationDialog = false;
      }
    },

    async saveFormationInfoToFirestore(data) {
      return await firebase.firestore().collection('doctors').doc(this.user).collection('education').add(data);
    },

    formValidation() {
      this.invalidForm = false;

      // console.log("Validating form...");

      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) this.invalidForm = true

        this.$refs[f].validate(false)
      })
    },

    addDays(days) {
      let result = new Date(this.graduationDate);
      result.setDate(result.getDate() + days);
      return result;
    },

    saveGraduationDate(date) {
      this.$refs.menu.save(date)

      this.selectedDate = this.addDays(1);
      // console.log(this.selectedDate.toString());
    },

    clearInput() {
      this.title = null;
      this.school = null;
      this.speciality = null;
      this.graduationDate = null;
      this.professionalId = null;
    },
  }
}
</script>

<style scoped>
.dialogTitle {
    color: #FFF;
    font-size: 1.5em !important;
    font-weight: bold;
}

.dialogSubtitle {
    color: #FFF;
    font-size: 0.7em !important;
    font-weight: bold;
    text-align: center;
}

</style>