<template>
    <v-container fluid class="patients-container">
        <v-row>
            <!--PATIENTS LIST -->
            <PatientsCard v-on:childToParent="onPatientClick"></PatientsCard>
            <!--PATIENTS NOTES -->
            <PatientsNotes :patientName="patientName" :patient-id="patientId" :last-appointment="lastAppointment"></PatientsNotes>
            <!--FLOATING BUTTON-->
            <CreateNote_Dialog :patient-name="patientName"  :patient-id="patientId" :last-appointment="lastAppointment"></CreateNote_Dialog>
        </v-row>
    </v-container>
</template>

<script>

import CreateNote_Dialog from "@/components/common_widgets/floatingButton/CreateNote_Button";
import PatientsNotes from "@/components/patients/PatientsNotes_Component";
import PatientsCard from "@/components/common_widgets/cards/Patients_Card";

export default {
    name: 'PatientsFeed',
    components: {
        PatientsCard,
        PatientsNotes,
        CreateNote_Dialog
    },
    data: () => ({
        patientName: '',
        lastAppointment: '',
        patientId: '',
    }),
    methods:{
        onPatientClick(name, date, patientId){
            this.patientName = name;
            this.lastAppointment = date;
            this.patientId = patientId;
        }
    }
};
</script>

<style>
.patients-container {
    margin-top: 40px;
}
</style>