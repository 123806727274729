<template>
    <!--FLOATING BUTTON FOR HOME-->
    <!--When the button is clicked it will send the word 'modal' so it will open a Modal-->
    <v-btn bottom color="#F96767" fab fixed medium right rounded @click="$emit('modal')">
        <v-icon color="white" size="35">mdi-plus</v-icon>
    </v-btn>
</template>

<style scoped>
.v-btn {
    border-radius: 50% !important;
}
</style>
<script>
export default {
    data() {
        return {
            dialog: false,
        }
    },
};
</script>
