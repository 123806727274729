<template>
    <!--PATIENTS FEED-->
    <PatientsFeed_Component></PatientsFeed_Component>
</template>

<script>
import PatientsFeed_Component from "@/components/patients/PatientsFeed_Component";

export default {
    name: 'App',
    components: {
        PatientsFeed_Component
    },
    data: () => ({
        //
    }),
};
</script>
