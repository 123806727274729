<template>
    <v-dialog
        v-model="dialog"
        overlay-opacity="0.8"
        persistent
    >
        <!-- ADD DIALOG -->
        <v-card class="rounded-xl elevation-0" color="accent">
            <v-container class="px-10 py-8" fluid>
                <!-- HEADER -->
                <v-row align="center" justify="space-between">
                    <!-- TITLE -->
                    <p class="dialogTitle text-uppercase">Crear Publicación</p>

                    <!-- CLOSE DIALOG BUTTON -->
                    <v-btn
                        class="mt-n4 mr-n2"
                        color="white"
                        fab
                        plain
                        small
                        @click="dialog = false"
                    >
                        <v-icon large>mdi-close</v-icon>
                    </v-btn>
                </v-row>

                <!-- DIALOG BODY -->
                <v-row align="center" class="px-8" justify="center">
                    <v-col cols="2"></v-col>
                    <v-col align-self="center" cols="8">
                        <!-- POST COPY OUT -->
                        <v-textarea
                            v-model="postCopyOut"
                            background-color="white"
                            color="primary"
                            counter
                            no-resize
                            placeholder="Escribe aquí el contenido de tu publicación..."
                            solo
                        ></v-textarea>

                        <!-- AUDIENCE SELECTION -->
                        <v-select
                            v-model="postAudience"
                            :items="audiencesList"
                            append-icon="mdi-arrow-down"
                            class="mb-n2"
                            color="#FFFFFF"
                            dark
                            dense
                            item-color="#FFFFFF"
                            menu-props='{class:coral}'
                            outlined
                            placeholder="Audiencia"
                        ></v-select>

                        <!--ADD IMG BUTTON-->
                        <v-row align="center" justify="center">
                            <button
                                :disabled="disabledImageButton"
                                :loading="filesPickerTrigger"
                                class="kuida-md-btn whiteaqua-btn button-shadow"
                                @click="openImagesPicker"
                            >
                                Añadir Imágenes
                            </button>
                            <input
                                ref="uploader"
                                accept="image/*"
                                class="d-none"
                                multiple
                                type="file"
                                @change="onFileChanged"
                            >
                        </v-row>

                        <!--PREVIEW IMAGES-->
                        <v-container class="mt-6">
                            <v-row align="center" justify="center">
                                <v-card v-for="(image, index) in images" :key="index" class="mx-1">
                                    <v-img :src="image" aspect-ratio="1" height="100" width="100">
                                        <v-btn icon small style="float: right" @click="deleteImages(index)">
                                            <v-icon color="white">mdi-close-circle</v-icon>
                                        </v-btn>
                                    </v-img>
                                </v-card>
                            </v-row>
                        </v-container>

                        <!-- POST LOADING PROGRESS LINEAR BAR -->
                        <v-container class="mb-3">
                            <v-progress-linear
                                v-if="loading"
                                color="primary"
                                height="6"
                                indeterminate
                                rounded
                            ></v-progress-linear>
                        </v-container>

                        <!-- PUBLISH BUTTON -->
                        <v-row align="end" justify="center">
                            <button
                                class="kuida-md-btn coral-btn button-shadow"
                                @click="savePostRequest()"
                            >
                                Publicar
                            </button>
                        </v-row>
                    </v-col>
                    <v-col cols="2"></v-col>
                </v-row>
            </v-container>
        </v-card>

        <!-- INVALID FORM DIALOG -->
        <Alert_Dialog
            :dialog-message="dialogMessage"
            :dialog-title="dialogTitle"
            :dialog-trigger="alertDialog"
            @dialog-close="dialogCustomEvent"
        ></Alert_Dialog>
    </v-dialog>
</template>

<script>
import firebase from 'firebase'
import * as list from "@/constants/audiencesList"
import Alert_Dialog from "@/components/common_widgets/dialogs/Alert_Dialog"

export default {
    name: "PostCard",
    props: {
        dialog: Boolean
    },
    components: {
        Alert_Dialog,
    },
    data() {
        return {
            user: '',
            images: [],
            postImagesUrls: [],
            postCopyOut: '',
            postAudience: null,
            selectedFile: null,
            dialogMessage: null,
            dialogTitle: null,
            alertDialog: false,
            filesPickerTrigger: false,
            disabledImageButton: false,
            uploadImages: [],
            audiencesList: null,
            loading: false,
            newPostId: ''
        }
    },
    mounted() {
        // Get current user currentUser
        this.user = firebase.auth().currentUser.email;
        this.audiencesList = list.audiencesList;
        this.postAudience = this.audiencesList[0];
    },
    methods: {
        async savePostRequest() {
            if (this.postCopyOut === '') {
                //IF THERE IS NO COPY OUT APP SHOULD ALERT
                this.dialogTitle = "ERROR";
                this.dialogMessage = "Tu publicación debe tener una descripción que acompañe la multimedia que quieras compartir.";
                this.alertDialog = true;
            } else {
                //FIRST IT will check if the user has uploaded at least one image and written some postCopyOut
                if (this.images.length === 0 || this.images.length > 8) {
                    //IF ONE IS MISSING IT WILL DEPLOY AN ALERT
                    this.dialogTitle = "ERROR";
                    this.dialogMessage = "Tu publicación debe tener entre 1-8 imágenes.";
                    this.alertDialog = true;

                } else {
                    //THIS WILL DEPLOY AN UPLOADING BAR
                    this.loading = true;

                    // This function will store the dialogs in Firebase
                    await this.storePosInDatabase();

                    this.clearFields();
                    this.dialog = false;
                    window.location.reload();
                }
            }
        },

        async storePosInDatabase() {
            let selectedAudienceIndex = this.audiencesList.lastIndexOf(this.postAudience);

            const postObject = {
                audience: selectedAudienceIndex,
                content: this.postCopyOut,
                author: this.user,
                date: new Date(),
                likes: 0,
            }

            // This line will create an empty doc in firestore with author, postCopyOut, date, img, likes
            const feedCollection = firebase.firestore().collection('feed');
            await feedCollection.add(postObject).then(async (snapshot) => {
                this.newPostId = snapshot.id;

                //THIS WILL UPLOAD THE URLS OF THE IMAGES STORED IN FIREBASE STORAGE
                await this.storePostMedia();

            }).catch(err => {
                console.log(err)
                this.error = err.message;

            });
        },

        async storePostMedia() {
            for (let i = 0; i < this.uploadImages.length; i++) {
                let extension = this.uploadImages[i].name.split('.')[1];
                const metadata = {contentType: `image/${extension}`,};

                let storageRef = firebase.storage().ref();
                let imageRef = storageRef.child(`posts/${this.newPostId}/${this.uploadImages[i].name}`);

                await imageRef.put(this.uploadImages[i], metadata);
                await imageRef.put(this.uploadImages[i], metadata).then(async () => {
                    let url = await imageRef.getDownloadURL();
                    this.postImagesUrls.push(url);
                });
            }

            await firebase.firestore().collection('feed').doc(this.newPostId).update({
                img: this.postImagesUrls,
            })
        },

        deleteImages(index) {
            this.images.splice(index, 1);
            this.disableButton()
        },

        dialogCustomEvent() {
            this.alertDialog = false;
        },

        openImagesPicker() {
            this.filesPickerTrigger = true
            window.addEventListener('focus', () => {
                this.filesPickerTrigger = false
            }, {once: true})

            this.$refs.uploader.click()
        },

        onFileChanged(e) {
            for (let x = 0; x < e.target.files.length; x++) {
                this.selectedFile = e.target.files[x];
                // console.log("FOTO: " + e.target.files[x].name)

                //WE STORE SELECTED FILES IN ARRAY SO WE CAN USE THEM LATER ON THE UPLOAD
                this.uploadImages.push(this.selectedFile)

                //THIS READS THE IMAGE UPLOADED BY THE USER
                const fileReader = new FileReader()
                fileReader.addEventListener('load', () => {
                        this.images.push(fileReader.result)
                        this.disableButton()
                    }
                )

                fileReader.readAsDataURL(this.selectedFile)
            }
        },

        disableButton() {
            //WHEN 8 IMAGES ARE UPLOADED THE BUTTON WILL BE DISABLED
            this.disabledImageButton = this.images.length === 8;
        },

        clearFields() {
            //THIS WILL CLEAR ALL VARIABLES USED SO WE CAN USE THEM AGAIN
            this.postCopyOut = '';
            this.selectedFile = null;
            this.filesPickerTrigger = false;
            this.images = [];
            this.disabledImageButton = false;
            this.alertError = false;
            this.uploadImages = [];
            this.postImagesUrls = [];
            this.docId = null;
            this.loading = false;
        }
    }
}
</script>
<style scoped>
@import './../../../styles/styles.css';

.title {
    color: white;
}

.dialogTitle {
    color: #FFF;
    font-size: 1.5em !important;
    font-weight: bold;
}

.dialogSubtitle {
    color: #FFF;
    font-size: 0.7em !important;
    font-weight: bold;
    text-align: center;
}
</style>