<template>
    <v-container style="height: 100vh;">
        <v-row align="center" class="mt-16" justify="center">
            <!-- AGENDA SCREEN CALENDAR COMPONENT -->
            <v-col cols="8">
                <Calendar_Component></Calendar_Component>
            </v-col>

            <!-- AGENDA DETAILS / RESUME COMPONENT -->
            <v-col cols="4">
                <!-- PENDING APPOINTMENTS TITLE -->
                <h3 class="coral-text" style="font-size: 20px; text-align: center;">Citas pendientes</h3>

                <v-card id="coming-appointments-card" color="accent" rounded="xl">
                    <v-card-text>
                        <v-row v-if="appointmentsList === null" class="appointments-list" align="center" justify="center">
                            <v-col align="center" justify="center">
                                <v-progress-circular
                                    class="mt-8"
                                    indeterminate
                                    color="primary"
                                ></v-progress-circular>
                            </v-col>
                        </v-row>

                        <v-simple-table v-else-if="appointmentsList.length >= 1" class="white--text accent appointments-list" dark style="width: 100%;">
                            <!-- TODAY'S PENDING APPOINTMENTS TABLE HEADER -->
                            <thead style="background-color: #3ec6c6;">
                                <tr>
                                <th style="color: white; font-size: large; text-align: left; width: 44%">Paciente
                                </th>
                                <th style="color: white; font-size: large; text-align: left; width: 16%">Hora</th>
                                <th style="color: white; font-size: large; text-align: left; width: 30%">Tipo</th>
                                </tr>
                            </thead>

                            <!--THIS WILL BLOCK WILL SHOW ALL APPOINTMENTS-->
                            <tbody>
                                <!-- TODAY'S PENDING APPOINTMENTS TABLE ROWS -->
                                <tr
                                v-for="appointment in appointmentsList"
                                v-bind:key="appointment[0]"
                                @click="finishAppointment(appointment)"
                            >
                                <td style="font-size: medium">{{ appointment.customerName }}</td>
                                <td style="font-size: medium">{{ appointment.formattedHour }}</td>
                                <td style="font-size: medium">{{ appointment.service }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>

                        <v-row  v-else-if="appointmentsList.length === 0" class="appointments-list" align="center" justify="center">
                            <h4 class="white--text">No hay citas pendientes por hoy</h4>
                        </v-row>

                        <!-- START NEXT APPOINTMENT / FINISH CURRENT BUTTON -->
                        <v-row align="end" dense>
                            <CurrentAppointment_Component
                                :appointments-list-prop="appointmentsList"
                                @changeAppointment="onAppointmentOnCalendarClick"
                                @childToParent="onAppointmentOnCalendarClick"
                                @startAppointment="appointmentStartedBool = true"
                            ></CurrentAppointment_Component>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-col>

            <!-- APPOINTMENTS MANAGEMENT FLOATING ACTION BUTTON -->
            <v-speed-dial
                transition="scroll-y-reverse-transition"
                :open-on-hover="true"
                :bottom="bottom"
                direction="top"
                :right="right"
                :left="left"
                :top="top"
                fixed
            >
                <!-- AGENDA EDITING BUTTONS ACTIVATOR -->
                <template v-slot:activator>
                    <v-btn
                        bottom color="#F96767"
                        id="plus-button"
                        rounded
                        medium
                        fixed
                        right
                        fab
                    >
                        <v-icon
                            color="white"
                            size="35"
                        >mdi-plus</v-icon>
                    </v-btn>
                </template>

                <!-- AGENDA EDITING BUTTONS -->
                <div class="add-event-item-container">
                    <!-- SCHEDULE BUTTON -->
                    <button
                        class="button-shadow kuida-md-btn grey-btn white--text event-button"
                        @click="editScheduleDialogTrigger = true;"
                    >
                        Editar horario
                    </button>

                    <!-- EVENT BUTTON -->
                    <button
                        class="button-shadow kuida-md-btn grey-btn white--text event-button"
                        @click="newEventDialogTrigger = true"
                    >
                        Agregar evento
                    </button>

                    <!-- APPOINTMENT BUTTON -->
                    <button
                        class="button-shadow kuida-md-btn grey-btn white--text event-button"
                        @click="createAppointmentsRequest"
                    >
                        Agendar cita
                    </button>
                </div>
            </v-speed-dial>
        </v-row>

        <!-- EDIT SCHEDULE DIALOG -->
        <EditSchedule_DialogForm
            :dialog-trigger="editScheduleDialogTrigger"
            @dialog-close="editScheduleDialogTrigger = false;"
        ></EditSchedule_DialogForm>

        <!-- NEW EVENT DIALOG -->
        <NewEvent_DialogForm
            :dialog-trigger="newEventDialogTrigger"
            @dialog-close="newEventDialogTrigger = false;"
        ></NewEvent_DialogForm>

        <!-- NEW APPOINTMENT DIALOG -->
        <NewAppointment_DialogForm
            :dialog-trigger="newAppointmentDialogTrigger"
            @dialog-close="newAppointmentDialogTrigger = false;"
        ></NewAppointment_DialogForm>

        <!-- ALERT MESSAGES BOTTOM SHEET -->
        <SimpleMessage_BottomSheet
            :bottom-sheet-trigger="bottomSheetTrigger"
            :bottom-sheet-message="bottomSheetMessage"
            @close-bottom-sheet="bottomSheetTrigger = false"
        ></SimpleMessage_BottomSheet>

    </v-container>
</template>

<script>
import SimpleMessage_BottomSheet from "@/components/common_widgets/bottom_sheets/SimpleMessage_BottomSheet";
import CurrentAppointment_Component from "@/components/agenda/sections/CurrentAppointment_Component";
import NewAppointment_DialogForm from "@/components/agenda/dialog_forms/NewAppointment_DialogForm";
import EditSchedule_DialogForm from "@/components/agenda/dialog_forms/EditSchedule_DialogForm";
import NewEvent_DialogForm from "@/components/agenda/dialog_forms/NewEvent_DialogForm";
import Calendar_Component from "@/components/agenda/sections/Calendar_Component";
import firebase from "firebase";
import {cloud_functions_request} from "@/helpers/cloud_functions_request";

export default {
    components: {
        CurrentAppointment_Component,
        SimpleMessage_BottomSheet,
        NewAppointment_DialogForm,
        EditSchedule_DialogForm,
        NewEvent_DialogForm,
        Calendar_Component,
    },
    data: () => ({
        newAppointmentDialogTrigger: false,
        editScheduleDialogTrigger: false,
        newEventDialogTrigger: false,
        todayAppointments: [],
        email: "",
        tabs: null,
        top: false,
        right: true,
        bottom: true,
        left: false,
        appointmentsList: [],
        date: null,
        time: null,
        onClickAppointment: [],
        selectedAppointment: false,
        boolPending: false,
        appointmentStartedBool: false,
        doctorStatus: false,
        subscriptionStatus: false,

        // Bottom Sheet Variables
        bottomSheetTrigger: false,
        bottomSheetMessage: '',
    }),
    async created() {
        //We get the logged user currentUser
        this.email = firebase.auth().currentUser.email;

        await this.getDoctorAcceptanceStatus();

        await this.getSubscriptionStatusFromStripe();

        //Stream listener to get the users appointment
        let nowMinus20 = new Date();
        nowMinus20.setMinutes(nowMinus20.getMinutes() - 21);

        let doctorAppointments = firebase.firestore().collection('appointments').where('doctor', "==", this.email).where('date', '>', nowMinus20);
        doctorAppointments.onSnapshot(
            (snap) => {
                // Check if the date of the doc is today
                let today = new Date();

                // Resets array
                this.todayAppointments = []

                // For every doc
                for (let i = 0; i < snap.docs.length; i++) {
                    if (snap.docs[i].data()['status'] !== 'Cancelled' && snap.docs[i].data()['status'] !== 'Finished' && snap.docs[i].data()['status'] !== 'Request') {
                        // Doc's date
                        let formattedDate = new Date(snap.docs[i].data()['date'].seconds * 1000).toLocaleDateString();
                        // formattedDate = formattedDate.toLocaleDateString(); //+ ' ' + formattedDate.toLocaleTimeString();

                        // FILTRO DE HOY
                        //If today's date is the same in the doc we will store it in an array todayAppointments
                        if (formattedDate === today.toLocaleDateString()) {
                            //Saves that doc
                            this.todayAppointments.push(snap.docs[i]);
                        }
                    }
                }

                //When it gets all the appointments
                this.getAppointmentDetails();
            }
        );
    },
    watch: {
        top(val) {
            this.bottom = !val
        },
        right(val) {
            this.left = !val
        },
        bottom(val) {
            this.top = !val
        },
        left(val) {
            this.right = !val
        },
    },
    methods: {
        createAppointmentsRequest() {
            if (this.doctorStatus === 'Approved') {

                if(this.subscriptionStatus === true) {
                    this.newAppointmentDialogTrigger = true;

                } else {
                    this.bottomSheetMessage = "Al parecer no estás suscrito a Kuida aún o tu suscripción ha vencido. Te invitamos a revisar el estado de tu suscripción en tu perfil."
                    this.bottomSheetTrigger = true;

                }

            } else {
                this.bottomSheetMessage = 'No puedes agendar citas dentro de Kuida hasta que hayamos verificado tu identidad y hayas sido aceptado por nuestro equipo. Espera tu confirmación por currentUser.'
                this.bottomSheetTrigger = true;

            }

        },

        async getDoctorAcceptanceStatus() {
            // Get current user account status
            const doctorData = await firebase.firestore().collection('doctors').doc(this.email).get().then((snap) => {
                return snap.data();
            });

            this.customerId = doctorData['stripeCustomerId'];
            this.doctorStatus = doctorData['status'];
        },

        async getSubscriptionStatusFromStripe() {
            let requestObject = {
                'customerId': this.customerId,
            }

            let response = await cloud_functions_request('getDoctorsSubscriptions', requestObject);

            this.subscriptionsList = response.data;

            if (this.subscriptionsList.length > 0) {
                this.subscriptionsList.forEach((subscription) => {
                    this.subscriptionStatus = subscription['status'] === 'active' || subscription['status'] === 'trialing';
                });

            } else {
                this.subscriptionStatus = false;
            }
        },

        clearAppointmentFields() {
            this.selectService = '';
            this.newAppointmentTime = null;
            this.newAppointmentDate = null;
            this.username = '';
        },

        async getAppointmentDetails() {
            //Resets array in case there are any changes
            this.appointmentsList = [];

            //If there are no appointments for today
            if (this.todayAppointments.length === 0) {
                this.appointmentsList = [];
            } else {

                //For every doc of today
                for (let i = 0; i < this.todayAppointments.length; i++) {
                    //We get the appointment hour
                    let hour = new Date(this.todayAppointments[i].data()['date'].seconds * 1000);
                    //The [], {hour: '2-digit', minute:'2-digit'} helps us get the date without seconds
                    let formattedHour = hour.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});

                    //The type of appointment
                    let service = this.todayAppointments[i].data()['service'];

                    //And the name of the client
                    let client = "";
                    if (this.todayAppointments[i].data()['patient'] !== "") {
                        client = await this.getPatientName(this.todayAppointments[i].data()['patient']);
                    }

                    //We add it to the list of appointments
                    let appointmentResumeObject = {
                        'appointmentId': this.todayAppointments[i].id,
                        'customerId': this.todayAppointments[i].data()['patient'],
                        'customerName': client,
                        'formattedHour': formattedHour,
                        'hour': hour,
                        'service': service,
                    };
                    this.appointmentsList.push(appointmentResumeObject);
                }

            }
        },

        //Gets client name with id
        async getPatientName(patientId) {
            let name = await firebase.firestore().collection('users').doc(patientId).get();
            return name.data()['name'];
        },

        // Finish Appointment
        finishAppointment(appointment) {
            if (appointment[4] === "Pending" && this.appointmentStartedBool === false) {
                //IT WILL ONLY LET US START A DATE IF THE STATUS IS PENDING
                this.boolPending = true;
                this.onClickAppointment = appointment;
                this.selectedAppointment = true;
            }
        },

        onAppointmentOnCalendarClick() {
            this.selectedAppointment = false;
            this.appointmentStartedBool = false;
        },
    }
}
</script>

<style>
.coral-text {
    color: #F96767;
    font-family: 'Montserrat', sans-serif;
}

.add-event-item-container {
    margin-left: -200px;
    margin-bottom: 35px;
}

/*POPUP STYLES*/
.theme--light.v-input.custom-label-color input::placeholder {
    color: whitesmoke;
}

.v-input__slot .v-label {
    color: whitesmoke !important
}

.v-text-field--outlined fieldset {
    color: white !important;
}

.white-input input {
    color: white !important;
}

.aqua-popup-window {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;

    width: 100%;
    max-width: 600px;
    background-color: #3EC6C6;
    border-radius: 16px;
    padding: 25px 50px 25px 50px;
}

.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 98;
    background-color: rgba(0, 0, 0, 0.3);
}

.v-data-table td {
    border-bottom: none !important;
}

.v-data-table th {
    border-bottom: none !important;
}

#plus-button {
    border-radius: 50% !important;
}

.event-button {
    margin: 10px 0;
    margin-right: 40px;
}

#coming-appointments-card {
    height: 75vh;
}

/* Filter to white out default clocks in time pickers */
input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(130deg) brightness(100%) contrast(100%);
}

.day-title {
    color: white;
    margin-bottom: 10px;
}

.hour {
    color: white;
    font-size: 16px;
    text-align: center;
}

.appointments-list {
    height: 65vh;
}

.fa-chevron-right, .v-icon {
    color: #FA8C8C;
}

</style>
