<template>
    <!-- BANK ACCOUNT UPDATE DIALOG -->
    <v-dialog
        v-model="dialogTrigger"
        overlay-opacity="0.8"
        persistent
    >
        <!-- DIALOG BODY -->
        <v-card class="rounded-xl elevation-0" color="accent">
            <v-container class="pa-10 pa-sm-10" fluid>
                <!-- HEADER -->
                <v-row align="center" justify="space-between">
                    <!-- TITLE -->
                    <p class="dialogTitle text-uppercase text-sm-h4">ACTUALIZAR CUENTA BANCARIA</p>

                    <!-- CLOSE DIALOG BUTTON -->
                    <v-btn
                        class="mt-n4 mr-n2"
                        @click="closeDialog"
                        color="white"
                        plain
                        small
                        fab
                    >
                        <v-icon large>mdi-close</v-icon>
                    </v-btn>
                </v-row>

                <!-- DIALOG BODY -->
                <v-row align="center" justify="center">
                    <v-col cols="3"></v-col>
                    <v-col align-self="center" class="pa-0" cols="12" md="6">
                        <!-- DIALOG SUBTITLE -->
                        <p class="white--text dialogSubtitle mb-8">
                            Introduce tu cuenta bancaria en la que quieres recibir tus ganancias generadas dentro de Kuida.
                        </p>

                        <!-- BANK ACCOUNT OWNER NAME -->
                        <v-text-field
                            label="Nombre de cuenta habiente"
                            :rules="ownerNameRules"
                            v-model="ownerName"
                            color="white"
                            outlined
                            dense
                            dark
                        ></v-text-field>

                        <!-- BANK ACCOUNT NUMBER -->
                        <v-text-field
                            label="CLABE (18 dígitos)"
                            v-model="clabeNumber"
                            :rules="clabeRules"
                            color="white"
                            outlined
                            dense
                            dark
                        ></v-text-field>

                        <!-- STRIPE ERRORS DISPLAY -->
                        <div class="card-details-label" id="card-error"></div>
                    </v-col>

                    <v-col cols="3"></v-col>
                </v-row>

                <!-- SAVE DATA BUTTON -->
                <v-row align="center" class="mt-8 mb-2" justify="center">
                    <button
                        class="kuida-md-btn coral-btn button-shadow mt-2"
                        @click="updateBankAccountRequest"
                    >
                        Actualizar Cuenta Bancaria
                    </button>
                </v-row>
            </v-container>
        </v-card>

        <!-- ALERT MESSAGES BOTTOM SHEET -->
        <SimpleMessage_BottomSheet
            @close-bottom-sheet="bottomSheetTrigger = false;"
            :bottom-sheet-trigger="bottomSheetTrigger"
            :bottom-sheet-message="bottomSheetMessage"
        ></SimpleMessage_BottomSheet>

        <!-- FILES LOADING INDICATOR -->
        <Loading_Dialog
            :loading-dialog-trigger="loadingDialog"
            dialog-message="Espera un momento"
        ></Loading_Dialog>

    </v-dialog>
</template>

<script>
import SimpleMessage_BottomSheet from "@/components/common_widgets/bottom_sheets/SimpleMessage_BottomSheet"
import Loading_Dialog from "@/components/common_widgets/dialogs/Loading_Dialog";
import {cloud_functions_request} from "@/helpers/cloud_functions_request";

export default {
    name: "BankAccountUpdate_DialogForm",
    props: {
        dialogTrigger: Boolean,
        stripeAccountId: String,
    },
    components: {
        SimpleMessage_BottomSheet,
        Loading_Dialog,
    },
    data() {
        return {
            // Bank Account Variables
            ownerName: '',
            clabeNumber: '',
            accountToken: '',

            // Bank Account text fields rules
            ownerNameRules: [
                value => !!value || 'Se requiere nombre de cuenta habiente.',
                value => {
                    const pattern = /^([A-Z]|[ ])*$/
                    return pattern.test(value) || 'El nombre solo puede contener letras mayúsculas y espacios.'
                },
            ],
            clabeRules: [
                value => !!value || 'Se requiere introducir un número de tarjeta',
                value => (value && value.length >= 18) || 'Introduce los 18 dígitos de tu CLABE interbancaria',
                value => {
                    const pattern = /^([0-9])*$/
                    return pattern.test(value) || 'Introducir únicamente números.'
                }
            ],

            // Bottom Sheet Variables
            bottomSheetTrigger: false,
            bottomSheetMessage: '',
            loadingDialog: false,
        }
    },
    watch: {
        ownerName(value) {
            this.ownerName = value.toString().toUpperCase();
        }
    },
    methods: {

        async assignTokens(accountToken) {
            try {
                // 1. SET UP REQUEST BODY
                let tokens = {
                    accountToken: accountToken['id'],
                    accountId: this.stripeAccountId,
                }

                // 2. API REQUEST TO ASSIGN STRIPE BANK ACCOUNT TOKEN TO DOCTOR'S STRIPE CONNECTED ACCOUNT
                let response = await cloud_functions_request('assignBankAccountTokenToConnectedAccount', tokens);

                // 3.A RETURN IN CASE OF A SUCCESSFUL REQUEST
                if (response.status === 200) {
                    return true;

                }
            } catch (e) {
                // 3.B DISPLAY ANY ERROR OCCURRED AT REQUEST
                return false;
            }
        },

        async updateBankAccountRequest() {
            const letters = /^([A-Z]|[ ]|[Ñ'`-])*$/; // REGEX FOR ONLY LETTERS AND SPACE VALIDATION
            const numbers = /^([0-9])*$/; // REGEX FOR ONLY NUMBERS VALIDATION

            // 1. CHECK EVERY FIELD HAS AN INPUT
            if(this.checkEmptyFields() === true) {
                // 2. CHECK THAT OWNER NAME IS COMPOSED ONLY BY LETTERS AND SPACES
                if (this.ownerName.match(letters)) {
                    // 3. CHECK THAT CLABE NUMBER IS COMPOSED ONLY BY NUMBERS
                    if (this.clabeNumber.match(numbers) && this.clabeNumber.length === 18) {
                        this.loadingDialog = true;

                        // 4. CREATE A TOKEN FOR BANK ACCOUNT WITH STRIPE API
                        let accountToken = await this.createAccountToken()
                        if (accountToken === null) {
                            // 4.A DISPLAY IF THERE WAS ANY ERROR UPDATING BANK ACCOUNT1
                            this.displayBankAccountUpdateError();
                            return;
                        }

                        // 5. ASSIGN NEW STRIPE BANK ACCOUNT TOKEN TO STRIPE CONNECTED ACCOUNT
                        let assignResult = await this.assignTokens(accountToken);
                        if (assignResult === false) {
                            this.displayBankAccountUpdateError();
                            return;
                        }

                        // 6. ASK APP TO RETRIEVE NEW BANK ACCOUNT DATA
                        this.$emit('updated-account-successfully');

                        // 7. CLOSE BANK ACCOUNT UPDATE DIALOG
                        this.closeDialog();

                    } else {
                        // Display bad CLABE format alert
                        this.bottomSheetMessage = "La CLABE interbancaria tiene un formato inválido. Esta CLABE debe estar compuesta únicamente por 18 dígitos.";
                        this.bottomSheetTrigger = true;
                    }

                } else {
                    // Display bad name format alert
                    this.bottomSheetMessage = "El nombre del cuenta habiente debe estar compuesto únicamente por letras y espacios.";
                    this.bottomSheetTrigger = true;
                }

            } else {
                this.bottomSheetMessage = 'Favor de llenar todos los campos';
                this.bottomSheetTrigger = true;
            }
        },

        displayBankAccountUpdateError() {
            this.loadingDialog = false;
            this.bottomSheetMessage = "Ha ocurrido un error al dar de alta tu cuenta bancaria, prueba con otra cuenta o intenta más tarde.";
            this.bottomSheetTrigger = true;
        },

        async createAccountToken() {
            // 1. SET UP REQUEST BODY
            let body = {
                country: 'MX',
                currency: 'mxn',
                account_holder_name: this.ownerName,
                account_number: this.clabeNumber,
                account_holder_type: 'individual',
            };

            // 2. API REQUEST TO CREATE STRIPE BANK ACCOUNT TOKEN WITH USERS INPUT
            const {token, error} = await this.$stripe.createToken('bank_account', body);

            if (error) {
                // 3.A DISPLAY ERROR CREATING BANK ACCOUNT TOKEN
                document.getElementById('card-error').innerHTML = error.message;
                return null;
            } else {
                // 3.B RETURN BANK ACCOUNT TOKEN WHEN CREATED SUCCESSFULLY
                return token;
            }
        },

        checkEmptyFields() {
            return this.ownerName !== '' && this.clabeNumber !== '';
        },

        closeDialog() {
            // Clear createPostDialogTrigger text fields.
            this.ownerName = '';
            this.clabeNumber = '';

            // Emit close request
            this.$emit('dialog-close');
        },
    }
}
</script>

<style scoped>
.dialogTitle {
    color: #FFF;
    font-size: 20px;
    font-weight: bold;
}

.dialogSubtitle {
    text-align: center;
    font-size: 0.75em;
    font-weight: 500;
    color: #FFF;
}

.card-details-text-field {
    border: #FFF 2px solid;
    padding: 10px 10px;
    margin-bottom: 5px;
    border-radius: 13px;
}

.card-details-label {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: white;
}
</style>
