<template>
    <v-container>
        <!-- CALENDAR DATE TITLE -->
        <v-row align="center" justify="center">
            <v-toolbar-title v-if="$refs.calendar" id="date-title">
                {{ this.$refs.calendar.title.toUpperCase() }}
            </v-toolbar-title>
        </v-row>


        <v-row align="center" justify="center" style="margin-top: 10px; margin-bottom: 10px;">
            <v-btn
                class="ma-1"
                icon
                @click="$refs.calendar.prev()"
            >
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>

            <button
                class="kuida-sm-btn aqua-btn button-shadow"
                @click="assignType('month')"
            >
                Vista Mensual
            </button>
            <button
                class="kuida-sm-btn aqua-btn button-shadow"
                @click="assignType('week')"
            >
                Vista Semanal
            </button>
            <button
                class="kuida-sm-btn aqua-btn button-shadow"
                @click="assignType('day')"
            >
                Vista Por Día
            </button>
            <v-btn
                class="ma-1"
                icon
                @click="$refs.calendar.next()"
            >
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </v-row>

        <v-sheet>
            <v-calendar
                id="doctor-calendar"
                ref="calendar"
                v-model="focus"
                :event-color="getEventColor"
                :events="events"
                :type="type"
                color="#F96767"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
            ></v-calendar>
            <v-menu
                v-model="selectedOpen"
                :activator="selectedElement"
                :close-on-content-click="false"
                offset-x
            >
                <v-card
                    color="grey lighten-4"
                    flat
                    min-width="350px"
                >
                    <v-toolbar
                        :color="selectedEvent.color"
                        dark
                    >
                        <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon>
                            <v-icon v-if="selectedEvent.type === 2 || selectedEvent.status === 'Request'"
                                    @click="deleteEventRequest(selectedEvent)">mdi-trash-can
                            </v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <p v-if="selectedEvent.type === 1">Servicio: {{ selectedEvent.service }}</p>
                        <p>Fecha: {{ selectedEvent.formattedDate }} </p>
                        <p>Hora: {{ selectedEvent.formattedHour }} </p>
                    </v-card-text>

                </v-card>
            </v-menu>
        </v-sheet>

        <!-- DELETE EVENT CONFIRMATION DIALOG -->
        <v-dialog
            v-model="deleteEventDialog"
            max-width="600"
            persistent
        >
            <v-card>
                <v-card-title class="headline pt-10" style="color: #F96767;">
                    ¿Estás seguro que deseas eliminar este evento?
                </v-card-title>
                <v-card-text>Al momento que elimines este evento desaparecerá de tu calendario, se mostrará este espacio
                    disponible en tu agenda y no podrás revertir la operación.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="grey darken-1"
                        text
                        @click="closeEventDeletionDialog()"
                    >
                        Regresar
                    </v-btn>
                    <v-btn
                        color="accent darken-1"
                        text
                        @click="deleteEvent"
                    >
                        Eliminar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
import firebase from "firebase";

export default {
    name: "Calendar_Component",
    data: () => ({
        type: 'month',
        types: ['month', 'week', 'day', '4day'],
        focus: '',
        typeToLabel: {
            month: 'Month',
            week: 'Week',
            day: 'Day',
            '4day': '4 Days',
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
        colors: ['#F96767', '#3EC6C6', '#C7C7C7'],
        appointments: [],
        deleteEventDialog: false,
        eventToDelete: '',
    }),
    mounted() {
        this.$refs.calendar.checkChange()
    },
    async created() {
        //We get the logged user currentUser
        this.email = firebase.auth().currentUser.email;

        //Stream listener to get the users appointment
        firebase.firestore().collection('appointments').where('doctor', "==", this.email).onSnapshot(
            async (snap) => {
                //New array
                let appointments = []

                //For every doc
                for (let i = 0; i < snap.docs.length; i++) {
                    if (snap.docs[i].data()['status'] !== 'Cancelled') {
                        //Doc's necessary data
                        let startDate = new Date(snap.docs[i].data()['date'].seconds * 1000);
                        let endDate = this.addMinutes(startDate, snap.docs[i].data()['duration']);

                        let formattedDate = startDate.toLocaleDateString();
                        let formattedHour = startDate.getHours() + ':' + this.parseMinutes(startDate) + ' - ' + endDate.getHours() + ':' + this.parseMinutes(endDate);

                        let service = snap.docs[i].data()['service'];
                        let type = snap.docs[i].data()['type'];
                        let status = snap.docs[i].data()['status'];

                        if (type === 1) {
                            let name = await firebase.firestore().collection('users').doc(snap.docs[i].data()['patient']).get().then((snapshot) => {
                                return snapshot.data()['name']
                            });

                            //Saves the appointment with its date
                            //Firebase name=service
                            appointments.push({
                                id: snap.docs[i].id,
                                type: type,
                                name: name,
                                start: startDate,
                                end: endDate,
                                color: this.colors[0],
                                timed: true,
                                status: status,
                                service: service,
                                formattedDate: formattedDate,
                                formattedHour: formattedHour
                            });
                        } else {
                            //Saves the appointment with its date
                            //Firebase name=service
                            appointments.push({
                                id: snap.docs[i].id,
                                type: type,
                                name: service,
                                start: startDate,
                                end: endDate,
                                color: this.colors[1],
                                timed: true,
                                service: service,
                                formattedDate: formattedDate,
                                formattedHour: formattedHour
                            });
                        }
                    }
                }

                //Assign the appointments to the variable used by the calendar
                this.events = appointments;
            }
        );
    },
    methods: {
        deleteEventRequest(event) {
            this.eventToDelete = event.id;
            this.deleteEventDialog = true;
        },

        async deleteEvent() {
            const appointmentDocument = await firebase.firestore().collection('appointments').doc(this.eventToDelete).get();

            if(appointmentDocument.data()['status'] === 'Request') {
                await firebase.firestore().collection('appointments').doc(this.eventToDelete).delete();

                this.closeEventDeletionDialog();
            } else {
                // TODO Display can't delete alert

                this.closeEventDeletionDialog();
            }


        },

        closeEventDeletionDialog() {
            this.eventToDelete = '';
            this.deleteEventDialog = false;
        },

        parseMinutes(date) {
            let min = date.getMinutes()
            if (min === 0) {
                return '00';
            } else {
                return min;
            }
        },

        assignType(newType) {
            this.type = newType;
        },

        addMinutes(date, minutes) {
            return new Date(date.getTime() + minutes * 60000);
        },

        viewDay({date}) {
            this.focus = date
            this.type = 'day'
        },

        getEventColor(event) {
            return event.color
        },

        setToday() {
            this.focus = ''
        },

        prev() {
            this.$refs.calendar.prev()
        },

        next() {
            this.$refs.calendar.next()
        },

        showEvent({nativeEvent, event}) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                setTimeout(() => {
                    this.selectedOpen = true
                }, 10)
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                setTimeout(open, 10)
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },
    },
}
</script>

<style>
#date-title {
    font-family: 'Montserrat', sans-serif;
    color: #F96767;
    font-size: 16px;
    font-weight: bold;
}

#doctor-calendar {
    height: 70vh;
}
</style>