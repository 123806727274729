<template>
    <v-container fluid style=" background-color: #3ec6c6; padding: 0 100px;" fill-height id="jumbotron-div">
        <!-- LOG IN NAVBAR -->
        <v-app-bar app color="accent" dark fixed flat>
            <router-link to="/" class="nav_item">
                <img class="mr-3" src='../assets/Kuida_logo.png' height="50"/>
            </router-link>


            <v-spacer></v-spacer>
            <v-toolbar-items>
                <router-link to="/signup" class="nav_item">
                    <button class="kuida-sm-btn text-button-shadow" style="color: white; margin-top: 10px;">
                        ¿No estás registrado?
                    </button>
                </router-link>
            </v-toolbar-items>
        </v-app-bar>

        <!-- LOG IN BODY -->
        <v-container>
            <h1>INICIAR SESIÓN</h1>
            <h2>PLATAFORMA PARA ESPECIALISTAS</h2>

            <v-container style="margin-top: 30px;">
                <v-row align="center" justify="center" dense>
                    <v-col cols="12" sm="6" md="4">
                        <!-- EMAIL TEXT FIELD -->
                        <v-text-field
                            style="margin-bottom: 15px;"
                            dense
                            dark
                            label="Email"
                            outlined
                            v-model="email"
                            :rules="[rules.required, rules.email]"
                            @keyup.exact.enter="onLogInRequest"
                        ></v-text-field>

                        <!-- PASSWORD TEXT FIELD -->
                        <v-text-field
                            dense
                            dark
                            class="login-form-input"
                            label="Contraseña"
                            outlined
                            hide-details
                            v-model="password"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="show1 ? 'text' : 'password'"
                            name="input-10-1"
                            hint="Contraseña"
                            counter @click:append="show1 = !show1"
                            @keyup.exact.enter="onLogInRequest"
                        ></v-text-field>

                        <v-row align="center" justify="center" dense style="margin-top: 30px;">
                            <button
                                class="kuida-md-btn coral-btn button-shadow"
                                @click="onLogInRequest()"
                                type="submit"
                            >
                                Iniciar Sesión
                            </button>

<!--                            <button class="kuida-sm-btn text-button-shadow" style="margin-top: 20px; color: white;">-->
<!--                                ¿Olvidaste tu contraseña?-->
<!--                            </button>-->
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>

        <!-- INCORRECT PASSWORD BOTTOM SHEET -->
        <v-bottom-sheet v-model="incorrectPassword">
            <v-sheet class="text-center" height="200px">
                <v-btn class="mt-6" text color="primary" @click="incorrectPassword = false">
                    CERRAR
                </v-btn>
                <div class="py-3">
                    Contraseña incorrecta, intenta de nuevo.
                </div>
            </v-sheet>
        </v-bottom-sheet>

        <!-- INCORRECT PLATFORM BOTTOM SHEET -->
        <v-bottom-sheet v-model="incorrectPlatform">
            <v-sheet class="text-center" height="200px">
                <v-btn class="mt-6" text color="primary" @click="incorrectPlatform = false">
                    CERRAR
                </v-btn>
                <div class="py-3">
                    Tu cuenta no es válida para usarse en la plataforma de especialistas.
                </div>
            </v-sheet>
        </v-bottom-sheet>

        <!-- USER DOESN'T EXISTS -->
        <v-bottom-sheet v-model="userNotFound">
            <v-sheet class="text-center" height="200px">
                <v-btn class="mt-6" text color="primary" @click="userNotFound = false">
                    CERRAR
                </v-btn>
                <div class="py-3">
                    El email que introdujiste no está registrado en Kuida, favor de crear una cuenta.
                </div>
            </v-sheet>
        </v-bottom-sheet>

        <!-- EMPTY FIELD BOTTOM SHEET -->
        <v-bottom-sheet v-model="emptyField">
            <v-sheet class="text-center" height="200px">
                <v-btn class="mt-6" text color="primary" @click="emptyField = false">
                    CERRAR
                </v-btn>
                <div class="py-3">
                    Algún campo está vacío, favor de introducir tus datos.
                </div>
            </v-sheet>
        </v-bottom-sheet>
    </v-container>
</template>

<script>
import firebase from "firebase";
import router from "../router";

export default {
    name: "LogInScreen",
    data() {
        return {
            doctorsCollection: firebase.firestore().collection('doctors'),
            show1: false,
            password: '',
            title: 'Preliminary report',
            email: '',
            rules: {
                required: value => !!value || 'Required.',
                counter: value => value.length <= 20 || 'Max 20 characters',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
                min: v => v.length >= 8 || 'Min 8 characters',
            },
            incorrectPassword: false,
            incorrectPlatform: false,
            userNotFound: false,
            emptyField: false,
        }
    },
    methods: {
        async onLogInRequest() {
            if (this.email !== '' || this.password !== '') {
                firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(async () => {

                    let userDoc = (await this.doctorsCollection.doc(this.email).get()).exists;

                    if (userDoc) {
                        await this.doctorsCollection.doc(this.email).update({
                            'lastSignIn': new Date()
                        }).then(async () => {

                            await this.doctorsCollection.doc(this.email).get().then((snapshot) => {
                                let doc = snapshot.data();

                                if(doc['status'] === 'Registered') {
                                    router.push('/doctorsForm');
                                } else {
                                    router.push('/app/'); // Navigate to platform Home
                                }
                            });
                        });

                    } else {
                        // When regular users try to enter to doctors platform
                        this.incorrectPlatform = true;
                    }

                }).catch((error) => {
                    let errorCode = error.code;
                    let errorMessage = error.message;
                    console.log("ERROR " + errorCode + ": " + errorMessage);

                    if (errorCode === 'auth/user-not-found') {
                        this.userNotFound = true;
                    } else {

                        // Incorrect Password alert
                        this.incorrectPassword = true;
                    }
                });
            } else {
                // Empty Field Alert
                this.emptyField = true;
            }

        }
    }
}
</script>

<style scoped>
html {
    background-color: #3ec6c6;
}

a {
    color: white;
}

.nav_item {
    text-decoration: none;
    color: #ffffff;
}
</style>