var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-2",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-container',{staticClass:"mt-6"},[(_vm.reviewsList.length === 0)?_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('h1',{staticStyle:{"color":"#F96767"}},[_vm._v("Aún no tienes ninguna reseña")])]):_vm._e(),_c('v-row',{staticClass:"px-16",attrs:{"justify":"center","align":"center"}},[_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("Invita a tus pacientes a agendar una cita contigo a través de Kuida para que puedan realizar una reseña sobre ti.")])]),_c('v-row',{staticClass:"px-16 mb-0 mt-4",attrs:{"justify":"center","align":"center"}},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"aqua-btn kuida-big-btn button-shadow",on:{"click":function($event){return _vm.getUsersAppLink()}}},'button',attrs,false),on),[_vm._v(" Copiar link de app para usuarios ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"px-12 py-4",attrs:{"color":"primary"}},[_c('v-card-title',{staticClass:"text-h5 white--text",staticStyle:{"text-align":"center"}},[_vm._v(" Link copiado en portapapeles ")])],1)],1)],1)],1),(_vm.reviewsList.length > 0)?_c('v-col',{attrs:{"align":"center","cols":"8"}},[_c('v-container',{staticStyle:{"overflow":"auto"}},[_c('DynamicScroller',{attrs:{"items":_vm.reviewsList,"min-item-size":200,"key-field":_vm.id,"bench":"3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var index = ref.index;
var active = ref.active;
return [_c('DynamicScrollerItem',{attrs:{"data-index":index,"item":item,"active":active,"size-dependencies":[ item.comment ]}},[_c('Review_Card',{attrs:{"review":item}})],1)]}}],null,false,4274099716)})],1)],1):_vm._e(),_c('v-btn',{staticStyle:{"border-radius":"45px"},attrs:{"fab":"","dark":"","fixed":"","bottom":"","right":"","color":"primary"},on:{"click":_vm.toTop}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-chevron-up")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }