<template>
    <!-- EDIT ROOM INFO TRIGGER -->
    <v-dialog
            v-model="openGeneralEditDialog"
            overlay-opacity="0.8"
            persistent
            width="auto"
    >
        <!-- EDIT BUTTON -->
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    icon
            >
                <!-- TODO Add functionality -->
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </template>

        <!-- EDIT DIALOG -->
        <v-card class="rounded-xl elevation-0" color="accent">
            <v-container class="px-10 py-8">
                <!-- HEADER -->
                <v-row align="center" justify="space-between">
                    <!-- TITLE -->
                    <p class="dialogTitle text-uppercase">Editar Información General</p>

                    <!-- CLOSE DIALOG BUTTON -->
                    <v-btn
                            class="mt-n4 mr-n2"
                            color="white"
                            fab
                            plain
                            small
                            @click="openGeneralEditDialog = false"
                    >
                        <v-icon large>mdi-close</v-icon>
                    </v-btn>
                </v-row>

                <!-- DIALOG BODY -->
                <v-row align="center" class="px-8" justify="center">
                    <v-col>
                        <!-- TITLE SELECTOR -->
                        <v-select
                                ref="title"
                                v-model="title"
                                :items="titlesList"
                                :rules="[rules.required]"
                                append-icon="mdi-arrow-down"
                                background-color="coral"
                                color="white"
                                dark
                                dense
                                item-color="white"
                                item-text="white"
                                outlined
                                placeholder="Título"
                        ></v-select>

                        <!-- USERNAME TEXT FIELD -->
                        <v-text-field
                                ref="username"
                                v-model="username"
                                :rules="[rules.required]"
                                color="white"
                                dark
                                dense
                                label="Username"
                                outlined
                                @keyup="transformToLowerCase"
                        ></v-text-field>

                        <!-- PHONE TEXT FIELD -->
                        <v-text-field
                                ref="phone"
                                v-model="phone"
                                :rules="[rules.required, rules.phoneNumber]"
                                color="white"
                                dark
                                dense
                                label="Teléfono"
                                outlined
                        ></v-text-field>

                        <!-- RESUME TEXT AREA -->
                        <v-textarea
                                v-model="resume"
                                color="white"
                                counter
                                dark
                                dense
                                height="150"
                                label="Resumen"
                                no-resize
                                outlined
                        ></v-textarea>
                    </v-col>
                </v-row>

                <!-- SAVE DATA BUTTON -->
                <v-row align="center" justify="center">
                    <button
                            class="kuida-md-btn coral-btn button-shadow mt-4"
                            type="button"
                            @click="saveGeneralInfoRequest"
                    >
                        Guardar
                    </button>
                </v-row>

            </v-container>
        </v-card>

        <!-- INVALID FORM DIALOG -->
        <v-dialog
                v-model="invalidForm"
                max-width="500"
                overlay-opacity="0.8"
                persistent
        >
            <v-card class="pa-16" color="white">
                <v-row align="center" justify="center">
                    <v-col align-self="center">
                        <h1 class="primary--text mt-n8">ERROR</h1>

                        <p class="mt-2" style="text-align: center; font-size: 0.85em;">{{ dialogText }}</p>

                        <v-row align="center" class="mt-8" justify="center">
                            <button
                                    class="kuida-md-btn coral-btn"
                                    @click="invalidForm = false"
                            >Cerrar
                            </button>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>

    </v-dialog>
</template>

<script>
import firebase from "firebase";
import * as titles from "@/constants/titlesList"
import {checkDoctorProfileCompletionAfterUpdate} from "@/helpers/validate_completion_and_status";
import {useAppState} from "@/store/useAppState";

export default {
  name: "UpdateGeneralInfo_Form",
  data() {
    return {
      user: '',
      title: '',
      phone: '',
      resume: '',
      username: '',
      dialogText: '',
      doctorData: null,
      titlesList: null,
      invalidForm: false,
      openGeneralEditDialog: false,
      rules: {
        required: value => !!value || 'Este campo es obligatorio.',
        phoneNumber: value => {
          const pattern = /\d{10}/
          return pattern.test(value) || 'Teléfono debe tener únicamente 10 dígitos'
        },
      },
    }
  },
  async beforeMount() {
    this.user = firebase.auth().currentUser.email;
    this.titlesList = titles.titles;

    await this.getDoctorInfoDocument();
  },
  methods: {
    async searchForAvailableUsername() {
      let result = null;
      await firebase.firestore().collection('doctors').where('username', '==', this.username).get().then((snapshot) => {
        if (!snapshot.empty) {
          result = snapshot.docs[0].id === this.user;
        } else {
          result = true;
        }
      });
      return result;
    },

    async saveGeneralInfoRequest() {
      let validUsername = this.validateUsername();
      if (validUsername === true) {

        let completeForm = this.validateFormInput();
        if (completeForm === true) {

          const validResume = this.checkResumeLength();
          if (validResume === true) {

            let availableUsername = await this.searchForAvailableUsername();
            if (availableUsername === true) {
              await firebase.firestore().collection('doctors').doc(this.user).update({
                'username': this.username,
                'resume': this.resume,
                'title': this.title,
                'phone': this.phone

              }).then(async () => {
                this.openGeneralEditDialog = false;

                // Always store new resume to analyze if the profile is complete
                useAppState().storeNewResume(this.resume);

                // Validate if doctor can be sent to Review after updating resume
                await checkDoctorProfileCompletionAfterUpdate();

              });

            } else {
              this.dialogText = "El username introducido ya está en uso, selecciona otro.";
              this.invalidForm = true;

              this.username = this.doctorData['username'];
            }
          } else {
            this.dialogText = "El resumen debe ser de entre 1 y 150 caracteres.";
            this.invalidForm = true;

            this.resume = this.doctorData['resume'];
          }
        } else {
          this.dialogText = "Debes llenar todos los campos.";
          this.invalidForm = true;
        }
      } else {
        this.dialogText = "Tu username debe estar compuesto por al menos 5 caracteres de letras, números, puntos o guiones.";
        this.invalidForm = true;
      }

    },

    getDoctorInfoDocument() {
      let doctorId = this.user;

      firebase.firestore().collection('doctors').doc(doctorId).onSnapshot((snapshot) => {
        this.doctorData = snapshot.data();

        this.fillCurrentDataIntoFields();
      });
    },

    fillCurrentDataIntoFields() {
      this.title = this.doctorData['title'];
      this.phone = this.doctorData['phone'];
      this.username = this.doctorData['username'];
      this.resume = this.doctorData['resume'];
    },

    transformToLowerCase() {
      this.username = this.username.toLowerCase();
    },

    validationExpression() {
      return this.phone !== '' && this.title !== '' && this.resume !== '' && this.username !== '';
    },

    checkResumeLength() {
      return Boolean(this.resume.length > 0 && this.resume.length <= 500);
    },

    validateFormInput() {
      return this.validationExpression();
    },

    validateUsername() {
      // Username RegEx
      const pattern = /^[a-z0-9_.-]{5,20}$/

      // Evaluate if user input matches the regex pattern
      let result = this.username.match(pattern);

      // Return result
      return result !== null;
    },
  }
}
</script>

<style scoped>
.dialogTitle {
    color: #FFF;
    font-size: 1.5em !important;
    font-weight: bold;
}

.dialogSubtitle {
    color: #FFF;
    font-size: 0.7em !important;
    font-weight: bold;
    text-align: center;
}

</style>