<template>
    <v-container class="grey-rounded-card mb-6">
        <v-col align="start" class="ml-4">
            <v-row align="start" class="pa-0 ma-0 mt-5" justify="start">
                <!-- TITLE INFO -->
                <v-col align="start" class="pa-0 ma-0">
                    <p class="grey-card-info-title">Nombre</p>
                    <p class="grey-card-info-content">{{ item.name }}</p>
                </v-col>

                <!-- DELETE BUTTON -->
                <v-btn
                    class="mt-n5"
                    color="primary"
                    icon
                    @click="deletionConfirm = true"
                >
                    <v-icon medium>mdi-delete</v-icon>
                </v-btn>
            </v-row>

            <!-- PRICE INFO -->
            <p class="grey-card-info-title">Precio</p>
            <p class="grey-card-info-content">{{ item.price }}</p>

            <!-- DURATION INFO -->
            <p class="grey-card-info-title">Duración</p>
            <p class="grey-card-info-content">{{ item.duration }} minutos</p>

            <!-- LOCATION INFO -->
            <p class="grey-card-info-title">Ubicación</p>
            <p class="grey-card-info-content">{{ getLocationName(item.type) }}</p>

            <!-- ADDED SINCE INFO -->
            <p class="grey-card-info-title">Añadido desde</p>
            <p class="grey-card-info-content">{{ item.addedSince }}</p>

            <!-- DESCRIPTION INFO -->
            <p class="grey-card-info-title">Descripción</p>
            <p class="grey-card-info-content mr-4">{{ item.description }}</p>
        </v-col>

        <!-- DELETION CONFIRM DIALOG -->
        <v-dialog
            v-model="deletionConfirm"
            max-width="750px"
            overlay-opacity="0.8"
            persistent
        >
            <v-card class="pa-16" color="white">
                <v-row align="center" justify="center">
                    <v-col align-self="center">
                        <h1 class="primary--text mt-n8">ATENCIÓN</h1>

                        <p style="text-align: center;">
                            ¿Estás seguro que quieres borrar este servicio? Este servicio
                            dejará de aparecer de inmediato para agendar citas pero las CITAS PREVIAMENTE agendadas con
                            este servicio NO SE ELIMINARÁN.
                        </p>

                        <v-row align="center" class="mt-8" justify="space-around">
                            <button
                                class="kuida-md-btn aqua-btn"
                                @click="deleteService"
                            >Eliminar
                            </button>

                            <button
                                class="kuida-md-btn coral-btn"
                                @click="deletionConfirm = false"
                            >Cancelar
                            </button>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import firebase from "firebase";

export default {
    props: [
        'item'
    ],
    name: "Service_Card",
    data() {
        return {
            user: '',
            deletionConfirm: false,
        }
    },
    beforeMount() {
        this.user = firebase.auth().currentUser.email;
    },
    methods: {
        getLocationName(locationNumber) {
            if (locationNumber === 0) return "Consultorio"
            else if (locationNumber === 1) return "Online"
            else if (locationNumber === 2) return "Visita a casa"
            else return "Error"
        },

        async deleteService() {
            await firebase.firestore()
                .collection('doctors')
                .doc(this.user)
                .collection('services')
                .doc(this.item.id).delete()
                .then(() => this.deletionConfirm = false);
        },
    }
}
</script>

<style scoped>
.grey-rounded-card {
    background-color: #E2E2E2;
    border-radius: 20px;
    elevation: higher;
    padding-right: 20px;
}

.grey-card-info-content {
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: normal !important;
    margin-top: -15px;
    color: #F96767;
}

.grey-card-info-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #F96767;
}
</style>