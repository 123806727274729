import Vue from 'vue'
import VueRouter from 'vue-router'
import DoctorsPlatform from '@/views/DoctorsPlatform'
import LogInScreen from "@/views/LogInScreen";
import SignUpScreen from "@/views/SignUpScreen";
import DoctorsForm from "@/views/DoctorsForm";
import DoctorsFeed from "@/components/feed/FeedScreen";
import Agenda_Screen from "@/components/agenda/Agenda_Screen";
import Patients_Screen from "@/components/patients/Patients_Screen";
import Appointments_Screen from "@/components/appointments/Appointments_Screen";
import Payments_Screen from "@/components/appointments/Payments_Screen";
import Error_Screen from "@/components/navbar/NotFound";
import firebase from "firebase";
import Doctor_Profile from "@/components/profile/Doctor_Profile";

Vue.use(VueRouter)

// For access this routes you should type something like => 'localhost:8080/app/feed
const routes = [
  { path: '/', name: 'LogIn', component: LogInScreen, meta: { isGuest: true } },
  { path: '/signup', name: 'SignUp', component: SignUpScreen, meta: { isGuest: true } },
  { path: '/doctorsForm', name: 'DoctorsForm', component: DoctorsForm, meta: { isAuthenticated: true } },
  { path: '/not', name: 'NotFoundError', component: Error_Screen },
  { path: '/app', component: DoctorsPlatform, meta: { isAuthenticated: true }, children: [
      { path: '', component: Agenda_Screen },
      { path: 'feed', name: 'DoctorsFeed', component: DoctorsFeed },
      { path: 'agenda', name: 'DoctorsAgenda', component: Agenda_Screen },
      { path: 'patients', name: 'DoctorsPatients', component: Patients_Screen },
      { path: 'profile', name: 'DoctorsProfile', component: Doctor_Profile,},
      { path: 'appointments', name: 'DoctorsAppointments', component: Appointments_Screen },
      { path: 'payments', name: 'DoctorsPayments', component: Payments_Screen },
      { path: 'apply', name: 'ApplyForm', component: SignUpScreen },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach(async (to, from, next) => {
    if(to.matched.some(record => record.meta.isGuest)) { // Case when you want to access a route with isGuest guard
        // Check if logged in
        if(firebase.auth().currentUser) {
            // Go to log in page
            next({
                path: '/app',
                query: {
                    redirect: to.fullPath
                }
            });

        } else {
            // Continue to route
            next();
        }

    } else if(to.matched.some(record => record.meta.isAuthenticated)) { // Case when you want to access a route with isAuthenticated guard

        // Check if NOT logged in
        if(!firebase.auth().currentUser) {
            // Go to log in page
            next({
                path: '/',
                query: {
                    redirect: to.fullPath
                }
            });
        } else {
            // Continue to route
            next();
        }
    } else {
        // This case is used when there is no guard required for the route
        next(); // Continue to route
    }
});

export default router;
