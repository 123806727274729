<template>
    <!-- NEW APPOINTMENT DIALOG -->
    <v-dialog
        v-model="dialogTrigger"
        overlay-opacity="0.8"
        persistent
    >
        <!-- DIALOG BODY -->
        <v-card class="rounded-xl elevation-0" color="accent">
            <v-container class="px-10 py-8" fluid>
                <!-- HEADER -->
                <v-row align="center" justify="space-between">
                    <!-- TITLE -->
                    <p class="dialogTitle text-uppercase">CREAR NUEVA CITA</p>

                    <!-- CLOSE DIALOG BUTTON -->
                    <v-btn
                        class="mt-n4 mr-n2"
                        @click="closeDialog"
                        color="white"
                        plain
                        small
                        fab
                    >
                        <v-icon large>mdi-close</v-icon>
                    </v-btn>
                </v-row>

                <!-- DIALOG BODY -->
                <v-row align="center" class="px-8" justify="center">
                    <v-col cols="3"></v-col>

                    <v-col align-self="center" cols="6">
                        <!-- SELECT SCHEDULE LABEL -->
                        <v-row align="center" justify="start">
                            <font-awesome-icon
                                icon="clock"
                                style="color: white;">
                            </font-awesome-icon>
                            <p style="color: white; font-size: 17px; margin-left: 20px; margin-top: 15px;">Selecciona un
                                horario</p>
                        </v-row>

                        <v-row align="center" justify="center">
                            <!-- APPOINTMENT TYPE PICKER -->
                            <v-select
                                v-model="selectedService"
                                :items="servicesTitles"
                                :rules="[v => !!v || 'Item is required']"
                                append-icon="mdi-arrow-down"
                                color="#FFFFFF"
                                dark
                                dense
                                item-color="#FFFFFF"
                                label="Tipo de cita"
                                outlined
                                required
                                rounded
                            ></v-select>

                            <v-row align="center" class="" justify="center">
                                <v-col class="col-7">
                                    <!-- DATE PICKER -->
                                    <v-menu
                                        ref="menu"
                                        v-model="datePickerTrigger"
                                        :close-on-content-click="false"
                                        dark
                                        offset-y
                                        rounded
                                        transition="scale-transition"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="newAppointmentDate"
                                                v-bind="attrs"
                                                v-on="on"
                                                append-icon="mdi-calendar"
                                                dark
                                                dense
                                                label="Fecha de cita"
                                                outlined
                                                readonly
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            ref="picker"
                                            v-model="newAppointmentDate"
                                            :min="new Date().toISOString().substr(0, 10)"
                                            color="primary"
                                            light
                                            width="400"
                                            @change="saveDateSelection"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col class="col-5">
                                    <!-- HOUR PICKER -->
                                    <v-menu
                                        ref="menu2"
                                        v-model="hourPickerTrigger"
                                        :close-on-content-click="false"
                                        dark
                                        offset-y
                                        rounded
                                        transition="scale-transition"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="newAppointmentTime"
                                                v-bind="attrs"
                                                v-on="on"
                                                append-icon="mdi-clock-time-four-outline"
                                                class="white-input custom-label-color"
                                                dark
                                                dense
                                                label="Hora de inicio"
                                                outlined
                                                readonly
                                            ></v-text-field>
                                        </template>
                                        <v-time-picker
                                            v-model="newAppointmentTime"
                                            light
                                            width="300"
                                            @click:minute="$refs.menu.save(newAppointmentTime)"
                                        ></v-time-picker>
                                    </v-menu>
                                </v-col>

                            </v-row>
                        </v-row>

                        <!-- INVITE KUIDA USER TO APPOINTMENT -->
                        <v-row align="center">
                            <v-col cols="1">
                                <font-awesome-icon
                                    icon="user-friends"
                                    style="color: white;">
                                </font-awesome-icon>
                            </v-col>
                            <v-col cols="11">
                                <p style="color: white; font-size: 15px; margin-left: 5px; margin-top: 15px;">Introduce
                                    el
                                    username (nombre de usuario) de Kuida de la persona que desees invitar a la
                                    cita.</p>
                            </v-col>
                        </v-row>

                        <!-- PATIENT'S USERNAME FOR INVITATION -->
                        <v-row align="center" justify="center">
                            <v-text-field
                                v-model="inviteUsername"
                                class="white-input custom-label-color"
                                dense
                                outlined
                                placeholder="Nombre de usuario"
                                rounded
                            ></v-text-field>
                        </v-row>
                    </v-col>

                    <v-col cols="3"></v-col>
                </v-row>

                <!-- SAVE DATA BUTTON -->
                <v-row align="center" class="mt-8 mb-2" justify="center">
                    <button
                        class="kuida-md-btn coral-btn button-shadow mt-2"
                        @click="createNewAppointmentRequest"
                    >
                        Agendar Cita
                    </button>
                </v-row>

            </v-container>
        </v-card>

        <!-- ALERT MESSAGES BOTTOM SHEET -->
        <SimpleMessage_BottomSheet
            @close-bottom-sheet="bottomSheetTrigger = false;"
            :bottom-sheet-trigger="bottomSheetTrigger"
            :bottom-sheet-message="bottomSheetMessage"
        ></SimpleMessage_BottomSheet>

        <!-- FILES LOADING INDICATOR -->
        <Loading_Dialog
            :loading-dialog-trigger="loadingDialog"
            dialog-message="Espera un momento"
        ></Loading_Dialog>

    </v-dialog>
</template>

<script>
import SimpleMessage_BottomSheet from "@/components/common_widgets/bottom_sheets/SimpleMessage_BottomSheet"
import Loading_Dialog from "@/components/common_widgets/dialogs/Loading_Dialog";
import firebase from "firebase";
import {Timestamp} from "@/main";
import {cloud_functions_request} from "@/helpers/cloud_functions_request";

export default {
    name: "NewAppointment_DialogForm",
    props: {
        dialogTrigger: Boolean,
    },
    components: {
        SimpleMessage_BottomSheet,
        Loading_Dialog,
    },
    data() {
        return {
            currentUser: '',
            inviteUsername: '',
            //User services data
            servicesIds: [],
            servicesTitles: [],
            servicesPrices: [],
            servicesCurrencies: [],
            servicesDurations: [],
            servicesSpecialities: [],
            servicesDescriptions: [],
            selectedService: '',
            datePickerTrigger: null,
            hourPickerTrigger: null,
            newAppointmentTime: null,
            newAppointmentDate: null,
            appointmentPatientId: null,
            // Bottom Sheet Variables
            bottomSheetTrigger: false,
            bottomSheetMessage: '',
            loadingDialog: false,
        }
    },
    async beforeMount() {
        this.currentUser = firebase.auth().currentUser.email;

        //We get the doctor's services data and add then in the following arrays
        let services = (await firebase.firestore().collection('doctors').doc(this.currentUser).collection('services').get()).docs;

        for (let i = 0; i < services.length; i++) {
            this.servicesIds.push(services[i].id);
            this.servicesTitles.push(services[i].data()['title']);
            this.servicesPrices.push(services[i].data()['price']);
            this.servicesCurrencies.push(services[i].data()['currency']);
            this.servicesDurations.push(services[i].data()['duration']);
            this.servicesSpecialities.push(services[i].data()['speciality']);
            this.servicesDescriptions.push(services[i].data()['description']);
        }
    },
    methods: {
        //Function to add an appointment with a patient on firebase 'appointments'
        async createNewAppointmentRequest() {
            //We get the index of the service selected
            let serviceIndex = this.servicesTitles.indexOf(this.selectedService);

            if (serviceIndex !== -1) {
                if (this.newAppointmentDate !== null) {
                    if (this.newAppointmentTime !== null) {
                        if (this.inviteUsername !== '') {
                            if (await this.lookForUsername() !== false) {
                                this.loadingDialog = true;

                                //This will upload the appointment to firebase with the given info of the patient
                                await firebase.firestore().collection('appointments').add({
                                    "currency": this.servicesCurrencies[serviceIndex], // Only MXN $ currently
                                    "date": Timestamp.fromDate(new Date(this.newAppointmentDate + " " + this.newAppointmentTime)), // Appointment date
                                    "description": this.servicesDescriptions[serviceIndex],
                                    "doctor": this.currentUser, // doctor's document id
                                    "duration": parseInt(this.servicesDurations[serviceIndex]), // In minutes
                                    "finishDate": null,
                                    "invite": true, // false = created by patient, true = created by doctor as an invitation
                                    "inviteDate": new Date(),
                                    "kuidaFee": 0,
                                    "patient": this.appointmentPatientId, // customer's document id
                                    "paymentDate": null,
                                    "paymentIntentId": "",
                                    "price": parseInt(this.servicesPrices[serviceIndex]),
                                    "review": false, // false = Not reviewed yet, true = already reviewed
                                    "scheduleDate": null,
                                    "service": this.selectedService,
                                    "serviceId": this.servicesIds[serviceIndex],
                                    "speciality": this.servicesSpecialities[serviceIndex],
                                    "startDate": null,
                                    "status": "Request", // Remains as 'Request' until user accepts invite
                                    "type": 1, // 1 = Appointment, 2 = Event, 3 = break

                                }).then(async (snap) => {

                                    // Create notification for user
                                    let body = {appointmentId: snap.id}
                                    await cloud_functions_request('createNewAppointmentRequestFromDoctorNotification', body);
                                });

                                this.loadingDialog = false;

                                this.closeDialog();
                            } else {
                                this.bottomSheetMessage = 'El nombre de usuario de el/la paciente que deseas invitar no está registrado, favor de intentar con otro.';
                                this.bottomSheetTrigger = true;
                            }
                        } else {
                            // Display pending invite data
                            this.bottomSheetMessage = 'Favor de introducir un usuario a invitar para la nueva cita.';
                            this.bottomSheetTrigger = true;
                        }
                    } else {
                        // Display pending hour selection
                        this.bottomSheetMessage = 'Favor de seleccionar una hora para la nueva cita.';
                        this.bottomSheetTrigger = true;
                    }
                } else {
                    // Display pending date selection
                    this.bottomSheetMessage = 'Favor de seleccionar una fecha para la nueva cita.';
                    this.bottomSheetTrigger = true;
                }
            } else {
                // Display pending service selection
                this.bottomSheetMessage = 'Favor de seleccionar un tipo de servicio para la nueva cita.';
                this.bottomSheetTrigger = true;
            }
        },

        // Function that searches if introduced invite inviteUsername exists.
        async lookForUsername() {
            return await firebase.firestore().collection('users').where('username', '==', this.inviteUsername).get().then((snap) => {
                let docs = snap.docs;

                if (docs.length > 0) {
                    this.appointmentPatientId = docs[0].id;
                } else {
                    console.log("Username not found...");
                }

                return docs.length > 0;
            });
        },

        saveDateSelection(date) {
            this.$refs.menu.save(date)

            this.formattedDate = new Date(this.date)
        },

        closeDialog() {
            // Clear any form field
            this.clearInput();

            // Emit close request
            this.$emit('dialog-close');
        },

        clearInput() {
            this.selectedService = '';
            this.newAppointmentTime = null;
            this.newAppointmentDate = null;
            this.appointmentPatientId = null;
        },
    }
}
</script>

<style scoped>
.dialogTitle {
    color: #FFF;
    font-size: 1.5em !important;
    font-weight: bold;
}

.dialogSubtitle {
    color: #FFF;
    font-size: 0.7em !important;
    font-weight: bold;
    text-align: center;
}
</style>
