<template>
    <v-row>
        <v-col cols="6" align-self="start">
            <p class="data-label">{{ this.$props.label }}</p>
        </v-col>
        <v-col cols="6" align-self="start">
            <p class="data-text">{{ this.$props.data }}</p>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "SubscriptionInfo_Text",
    props: [
        'label',
        'data'
    ],
}
</script>

<style scoped>
.data-label, .data-text {
    font-size: 13px;
    font-weight: 500;
}

.data-label {
    color: #F96767;
}

.data-text {
    color: #9F9E9E;
}
</style>