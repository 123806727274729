<template>
    <v-container class="py-6">
        <v-row align="center" justify="center">
            <v-col v-if="this.noPosts === false" cols="10" >
                <DynamicScroller
                    :items="postsList"
                    :min-item-size="400"
                    key-field="id"
                >
                    <template v-slot="{ item, index, active }">
                        <DynamicScrollerItem
                            :active="active"
                            :data-index="index"
                            :item="item"
                            :size-dependencies="[ item.img[0], ]"
                        >
                            <Post_Card :post="item"></Post_Card>
                        </DynamicScrollerItem>
                    </template>
                </DynamicScroller>
            </v-col>

            <v-container fluid>
                <v-row v-if="this.noPosts === true"  justify="center" align="center">
                    <h1 style="color: #F96767; margin-top: 10px;">No has creado ninguna publicación</h1>
                </v-row>

                <v-row v-if="this.noPosts === true"  justify="center" align="center">
                    <p>Ve a la pestaña 'Feed' para crear tu primer post.</p>
                </v-row>
            </v-container>

            <v-btn
                bottom
                color="primary"
                dark
                elevation="2"
                fab
                fixed
                right
                style="border-radius: 45px;"
                @click="toTop"
            >
                <v-icon large>mdi-chevron-up</v-icon>
            </v-btn>
        </v-row>
    </v-container>
</template>

<script>
import Post_Card from "@/components/common_widgets/cards/Post_Card";
import firebase from "firebase";

export default {
    name: "DoctorPosts_Component",
    components: {
        Post_Card,
    },
    data() {
        return {
            postsList: [],
            feedCollection: firebase.firestore().collection('feed'),
            noPosts: false,
            lastPost: null,
            user: '',
        }
    },
    async created() {
        this.feedCollection = this.query == null ? this.feedCollection : this.query
        this.user = firebase.auth().currentUser.email;

        await this.fetchPosts()
    },
    methods: {
        async fetchPosts() {
            await this.feedCollection.where('author', '==', this.user).limit(3).orderBy('date', "desc").get().then(query => {
                if (query.empty) {
                    // console.log("No posts found");
                    this.noPosts = true
                } else {
                    this.lastPost = query.docs[query.docs.length - 1]

                    query.forEach(item => {
                        const map = {
                            'id': item.id,
                            'title': item.data().title,
                            'date': item.data().date,
                            'content': item.data().content,
                            'img': item.data().img,
                            'likes': item.data().likes,
                            'author': item.data().author
                        };

                        this.postsList.push(map);
                    })

                    // console.log(this.cards);
                }
            }).catch((err) => {
                console.log(err)
            })
        },

        nextPosts() {
            this.feedCollection.where('author', '==', this.user).limit(3).orderBy('date', "desc").startAfter(this.lastPost).get().then(query => {
                this.lastPost = query.docs[query.docs.length - 1]
                query.forEach(item => {
                    this.postsList.push({
                        'title': item.data().title,
                        'date': item.data().date,
                        'content': item.data().content,
                        'img': item.data().img,
                        'likes': item.data().likes,
                        'author': item.data().author
                    })
                })
            })
        },

        onScroll(e) {
            if (typeof window === 'undefined') return
            const top = window.pageYOffset || e.target.scrollTop || 0
            this.fab = top > 20;
        },

        toTop() {
            this.$vuetify.goTo(0)
        }
    }
}
</script>

<style scoped>
</style>