<template>
    <v-bottom-sheet v-model="bottomSheetTrigger">
        <v-sheet class="text-center" height="200px">
            <v-btn class="mt-6" color="primary" text @click="$emit('close-bottom-sheet')">
                CERRAR
            </v-btn>
            <div class="py-3">
                <p class="px-16" style="font-size: 0.8em;">{{ bottomSheetMessage }}</p>
            </div>
        </v-sheet>
    </v-bottom-sheet>
</template>

<script>
export default {
    name: "SimpleMessage_BottomSheet",
    props: {
        bottomSheetTrigger: Boolean,
        bottomSheetMessage: String,
    }
}
</script>

<style scoped>

</style>